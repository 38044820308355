import React, { useState } from "react";
import CustomInput from "./CustomInput";
import times_icon from "../../assets/images/times_icon.png";
import logo from "../../assets/images/circle_logo.png";
import agent from "../../agent";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../components/Loader";
import axios from "axios";

const GiftPopup = ({ crossHandle, setGiftPopupToggle }) => {
  const [postCodeSuggestion, setPostCodeSuggestion] = useState([]);
  const [postCodeLoading, setPostCodeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    postCode: "",
    town_or_city: "",
    county: "",
    street: "",
    country: "",
    houseName: "",
  });

  const [postCodeSummary , setPostCodeSummary] = useState([])

 
  const getPostCodeValue = async (postCode) => {
    try {
      setPostCodeLoading(true);
      let res = await agent.postCodeRequest.getPostCodeSuggestion(postCode);
      if (res?.status) {
        setPostCodeSuggestion(res?.suggestions);
        setPostCodeLoading(false);
      }
    } catch (error) {
      setPostCodeLoading(false);
      
    }
  };

  const handleFormData = async (e) => {
    const { name, value } = e.target;

    if (name == "telephone") {
      if (value?.length > 11) return;
    }
    if (name == "postCode") {
      getPostCodeValue(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handlePostCodeAddress = async (value) => {
  //   setPostCodeSummary([]);
  //       setFormData({
  //         ...formData,
  //         country: "UK",
  //         town_or_city: value?.posttown,
  //         county: value?.county,
  //         houseName: value?.premise,
  //         street: value?.street,
  //         postCode: value?.postcode,
  //       });
     
  // };
  const handlePostCodeAddress = async (id) => {
    setPostCodeSuggestion([]);

    try {
      let res = await agent.postCodeRequest.getAddress(id);
      if (res.status) {
        const data = await res?.data;
        const houseName = data?.line_1?.length == 1 ? data?.line_1 +" "+ data?.line_2 : data?.line_1

        setFormData({
          ...formData,
          country: data?.country,
          town_or_city: data?.town_or_city,
          county: data?.county,
          houseName: houseName,
          street: data?.thoroughfare,
          postCode: data?.postcode,
        });
      }
    } catch (error) {
      
    }
  };

  const submitHandler = async () => {
    let empty = false;
    for (let keys of Object.keys(formData)) {
      if (!formData[keys]) {
        empty = true;
      }
    }
    if (empty) {
      return toast.error("All fields required!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    try {
      setLoading(true);
      const res = await agent.common.addGift({
        ...formData,
        offer: "FREE GIFT",
      });
      if (res?.status) {
        setLoading(false);
        setGiftPopupToggle(false);
        localStorage.setItem("visiterGift", true);
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    
    }
  };

  return (
    <div className="overlay-visited">
      {loading && <Loader />}
      <div className="street-postmain">
        <button className="close-btn" style={{ cursor: "pointer" }}>
          <img src={times_icon} alt="" onClick={crossHandle} />{" "}
        </button>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="input-group">
          <div className="input-field">
            <CustomInput
              type={"text"}
              name="firstName"
              value={formData.firstName}
              label={"First Name"}
              handleChange={handleFormData}
            />
          </div>
          <div className="input-field">
            <CustomInput
              type={"text"}
              name="lastName"
              label={"Last Name"}
              value={formData.lastName}
              handleChange={handleFormData}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-field">
            <CustomInput
              type={"email"}
              label={"Email Address"}
              name="email"
              value={formData.email}
              handleChange={handleFormData}
            />
          </div>
          <div className="input-field">
            <CustomInput
              type={"number"}
              name="telephone"
              label={"Telephone Number"}
              value={formData.telephone}
              handleChange={handleFormData}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-field full-width">
            <CustomInput
              type={"text"}
              label={"Post Code"}
              value={formData.postCode}
              name="postCode"
              handleChange={handleFormData}
            />
            {postCodeLoading ? (
              <Loader />
            ) : (
              postCodeSuggestion?.length > 0 && (
                <ul className="postcode">
                  {postCodeSuggestion?.map((value) => {
                    return (
                      <li key={value?.id} style={{cursor:"pointer"}} onClick={() => handlePostCodeAddress(value?.id)}>
                        {/* below expression for formate the address */}
                        {value?.address.replace(/^(\d+),\s*/, "$1 ")}
                      </li>
                    );
                  })}
                </ul>
              )
            )}
          </div>
        </div>
        <div className="input-group">
          <div className="input-field">
          <CustomInput
              type={"text"}
              label={"House Name"}
              name="houseName"
              handleChange={handleFormData}
              value={formData.houseName}
            />
            
          </div>
          <div className="input-field">
          <CustomInput
              type={"text"}
              label={"Street"}
              name="street"
              value={formData.street}
              handleChange={handleFormData}
            />
           
          </div>
        </div>
        <div className="input-group">
          <div className="input-field">
          <CustomInput
              type={"text"}
              value={formData.town_or_city}
              label={"Town/City"}
              name="town_or_city"
              handleChange={handleFormData}
            />
           
          </div>
          <div className="input-field">
          <CustomInput
              type={"text"}
              label={"County"}
              value={formData.county}
              name="county"
              handleChange={handleFormData}
            />
           
          </div>
        </div>
        <div className="input-group">
          <div className="input-field full-width">
          <CustomInput
              type={"text"}
              label={"Country"}
              name="country"
              value={formData.country}
              handleChange={handleFormData}
            />
          </div>
        </div>
        <button className="submit-btn" onClick={submitHandler}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default GiftPopup;
