import React, {useState, useEffect, Fragment} from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import backIcon from "../../assets/images/back_btn.png";
import { validate as FormValidate, ListErrorMessages } from '../../constants/Validations';
import { RESET_PASSWORD, RESET_PASSWORD_EMPTY } from "../../constants/actionTypes";
import agent from "../../agent";
import closeBtn from "../../assets/images/times_icon.png"
import Loader from "../components/Loader";
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
    root: {
      // input label when focused
      "& label.Mui-focused": {
        color: "#219653"
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
        borderBottomColor: "#219653"
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#219653"
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#219653"
        }
      }
    }
  });



const mapStateToProps = (state) => ({
    rPassSuccess: state.common.rPassSuccess,
    rPassMessage: state.common.rPassMessage,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitForm: async (values) => {
        try {
            const response = await agent.Auth.resetPassword(values);
            dispatch({
                type: RESET_PASSWORD,
                payload: response,
            });
            toast.success("Password Reset Successful!", {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (error) {
            if (error.response && error.response.statusCode === 400) {
                dispatch({
                    type: RESET_PASSWORD,
                    payload: JSON.parse(error.response.text),
                });
                toast.error(error.response.text, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    },
    onEmpty: () => { dispatch({ type: RESET_PASSWORD_EMPTY }) }
});

const ResetPassword = (props) => {
    const { handleCloseResetPass, onSubmitForm, onEmpty, handleShowLogin, rPassMessage, rPassSuccess, forgotEmail,showResetPass, handleShowResetPassComplete } =props
    const [password, setPassword] = useState("");
    const [showPassowrd, setShowPassword] = useState(false);
	const [showConfirmPassowrd, setShowConfirmPassword] = useState(false);
	const [confirmpassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const [inProgress, setInProgress] = useState(false);


    const classes = useStyles();


    const submitBtn =  (e) => {
	    e.preventDefault();
	    let checkValidate = FormValidate({
            password: { value: password, required: true, hasSpecialCharacter:true, hasUpperCase: true, hasLowerCase:true, minlength: 8 },
            confirmPassword: { value: confirmpassword, required: true, hasSpecialCharacter:true, hasUpperCase: true, hasLowerCase:true, minlength: 8,equalPassword: password }
        });
	    if(!checkValidate.status) {
	        setErrors(null);
            setInProgress(true)
            submitHandle()
	    } else {
	        let values = ListErrorMessages(checkValidate.errors);
            const errorMessages = {
                password: values.password,
                confirmPassword: values.confirmPassword
              };
            const formattedErrors = Object.values(errorMessages).join('\n');
            toast.error(formattedErrors,{
                position: toast.POSITION.TOP_RIGHT
            });
	    }
	} 

    const submitHandle =  () => {
        let sendData ={
           "email": forgotEmail,
           "confirmPassword": confirmpassword,
           "password": password
         }
        onSubmitForm(sendData);
        setPassword("");
        setConfirmPassword("");
        setMessage(null);
        setErrors({});

    };

    useEffect(() => {
        if(rPassMessage) {
            if (typeof rPassMessage === "string") {
                setMessage(rPassMessage)
                setInProgress(false)
                onEmpty()
                if(rPassSuccess){
                    setMessage(null)
                    handleCloseResetPass()
                    handleShowResetPassComplete()
                    
                } else {
                    setTimeout(function() {
                        setMessage(null)
                    }, 4000);
                }
            }
            
        }

    }, [rPassMessage])
    return (
        <Fragment>
            <div
            style={{ position: 'fixed' }}
            className={`overlay ${showResetPass ? 'password-active' : ''}`}
            />
        <div className={`login-main new-password-main ${showResetPass ? 'password-active': ''}`}>
            <form>
                <div className="logo">
                    <div className="logo-link"><img src={logo} alt="Logo"/></div>
                    <span onClick={handleCloseResetPass}><img src={closeBtn} alt="closeBtn" />
                    </span>
                </div>
                {inProgress && <Loader/>}
                <div className="login-field">
                <h2>Set new password</h2>
                <p>Must be at least 8 characters</p>
                    {/* {message ? <span className={(rPassSuccess) ? 'text-success' : 'text-danger' }>{message}</span> : <Fragment /> } */}
                    <div className="input-field">
                    <TextField
                    className={classes.root}
                            id="outlined-password-input"
                            fullWidth
                            label="Password"
                            value={password}
                            type={showPassowrd ? "text" : "password"}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                       
                        <i className={`fa fa-eye${showPassowrd?'':'-slash'}`} aria-hidden="true" onClick={(e) => setShowPassword(!showPassowrd)}></i>
                        {/* <span className="text-danger">{errors && errors.password ? errors.password : ''}</span> */}
                    </div>
                    <div className="input-field">
                    <TextField
                    className={classes.root}
                            id="outlined-password-input"
                            fullWidth
                            label="Confirm password"
                            value={confirmpassword}
                            type={showConfirmPassowrd ? "text" : "password"}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                        {/* <input
                            placeholder="Confirm password"
                            type={showConfirmPassowrd ? "text" : "password"}
                            value={confirmpassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        /> */}
                        <i className={`fa fa-eye${showConfirmPassowrd?'':'-slash'}`} aria-hidden="true" onClick={(e) => setShowConfirmPassword(!showConfirmPassowrd)}></i>
                        {/* <span className="text-danger">{errors && errors.confirmPassword ? errors.confirmPassword : ''}</span> */}
                    </div>
                    <div className="login-btn">
                        <button
                            className="login-button reset-btn btn btn-md btn-primary reset-pass-btn w-100 mb-3 orange-btn-border"
                            type="button"
                            disabled={inProgress}
                            onClick={submitBtn}
                        >
                            Reset password
                        </button>
                    </div>
                    <div className="back-login">
                        <div className="back-login-btn" onClick={handleShowLogin}><img src={backIcon} alt="Back icon"/>Back to login</div>
                    </div>
                </div>
            </form>
        </div>
        </Fragment>
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);