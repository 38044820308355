import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import Loader from '../components/Loader';
import agent from '../../agent';
import closeBtn from "../../assets/images/close_icon.png"

const CheckoutModal = ({
  isModalOpen,
  closeModal,
  openSuccessModal,
  orderNumber,
  setHasPlotInOrder
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';

    return () => (document.body.style.overflow = '');
  }, [isModalOpen]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    try {
      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: 'http://localhost:3000/cart',
        },
      });

      if (error) {
        throw new Error(error.message);
      }
      switch (paymentIntent.status) {
        case 'succeeded':
          await updateOrderPaymentStatus(orderNumber, "success");
          closeModal();
          openSuccessModal();
          break;
        case 'processing':
          break;
        case 'requires_payment_method':
          throw new Error('Your payment was not successful, please try again.');
        default:
          throw new Error('Something went wrong.');
      }
    } catch (err) {
      await updateOrderPaymentStatus(orderNumber, "failed");
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        toast.error(err);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const updateOrderPaymentStatus = async (orderNumber, orderStatus) => {
    try {
      const res = await agent.common.updatePaymentStatus(
        orderNumber,
        orderStatus
      );
      setHasPlotInOrder(res?.hasPlotInOrder)
      const { status, message } = res;
      if (!status) throw new Error(message);
    } catch (err) {
      if (err instanceof Error) {
      } else {
        toast.error('Something went wrong!');
      }
    }
  };
  const handleClose = () => {
    closeModal();
  }

  return (
    <Fragment>
      <div
        onClick={closeModal}
        style={{ position: 'fixed' }}
        className={`overlay ${isModalOpen ? 'cart-show' : ''}`}
      />

      <div className={`add-Card checkout-card ${isModalOpen ? 'card-active' : ''}`}>
        {isLoading && <Loader />}
        <h2>Checkout</h2>
        <span><img src={closeBtn} alt="" onClick={handleClose} /></span>

        <form onSubmit={handleSubmit}>
          <PaymentElement options={{ layout: 'tabs' }} />
          <button type='submit'>Checkout</button>
        </form>
      </div>
    </Fragment>
  );
};

export default CheckoutModal;
