

import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import goBackImg from '../../assets/images/arrow_left.png';
import SEO from '../SEO/SEO';

const PrivacyPolicy = (props) => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1); // This will navigate back to the previous page.
    };
    return (
        <Fragment>
             <SEO
				title="Inspired Meadows | CookiePolicy"
			
       />
            <section id="terms-main">
                <div class="container">
                    <div class="row">
                        <div class="terms-col">
                            <div class="terms-details">
                                <div class="back-btn" onClick={handleGoBack}>
                                    <Link to="#"><img src={goBackImg} alt="arrow left icon" />Go back</Link>
                                </div>
                                <div className='policy-head'>
                                    <div className='heading'>
                                        <h1>Cookies Policy</h1>
                                        <span className='last-update'>LAST UPDATED JANUARY 2024</span>
                                    </div>
                                </div>
                                <div className='policy-list'>
                                    <div className='policy'>
                                        <h3>1. Introduction</h3>
                                        <p>
                                            Welcome to our website www.inspiredmeadows.co.uk. This Cookie Policy explains how we use cookies and similar technologies on our website. By using our website, you agree to the use of cookies as described in this policy.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>2. What are Cookies?</h3>
                                        <p>
                                            Cookies are small text files that are placed on your device when you visit a website. They are widely used to make websites work more efficiently and enhance your user experience.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>3. Types of Cookies We Use</h3>
                                        <div className='policy-category'>
                                            <b>a. Essential Cookies</b>
                                            <p>These cookies are necessary for the proper functioning of our website. They enable you to navigate our site and use its features.</p>
                                        </div>
                                        <div className='policy-category'>
                                            <b>b. Analytical/Performance Cookies</b>
                                            <p>These cookies allow us to collect information about how visitors use our website. We use this information to analyse and improve the performance of our site.</p>
                                        </div>
                                        <div className='policy-category'>
                                            <b>c. Functional Cookies</b>
                                            <p>Functional cookies enable the website to provide enhanced functionality and personalization. They may be set by us or third-party providers whose services we have added to our pages.</p>
                                        </div>
                                        <div className='policy-category'>
                                            <b>d. Targeting/Advertising Cookies</b>
                                            <p>These cookies may be set through our site by advertising partners. They may be used to build a profile of your interests and show you relevant advertisements on other sites.</p>
                                        </div>
                                    </div>
                                    <div className='policy'>
                                        <h3>4. How to Manage Cookies</h3>
                                        <p>
                                            You can control and/or delete cookies as you wish. For details, see <span>www.allaboutcookies.org</span>.
                                        </p>
                                        <br></br>
                                        <p>
                                            You can delete all cookies that are already on your device and set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit our site, and some services and functionalities may not work.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>5. Changes to This Policy</h3>
                                        <p>
                                            We may update our Cookie Policy from time to time. Please check this page regularly for updates. If you continue to use our website after any changes, it will indicate your acceptance of the changes.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>6. Contact Us</h3>
                                        <p>
                                            If you have any questions about our Cookie Policy, please contact us at <span>info@inspiredmeadows.co.uk</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default PrivacyPolicy; 