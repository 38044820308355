/* eslint-disable no-undef */
import React, { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import agent from "../agent";

import "../assets/css/style.css";
import "../assets/css/responsive.css";

import { APP_LOAD } from "../constants/actionTypes";
import RouteList from "./RouteList";
import { HelmetProvider } from 'react-helmet-async';


const mapStateToProps = (state) => {
  return {
    ...state,
    currentUser: state.common.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAppLoad: (payload) =>
    dispatch({ type: APP_LOAD, payload, skipTracking: true }),
});

const AppComponent = (props) => {
  const { onAppLoad, currentUser } = props;
  useEffect(() => {
    let userId = null,
      _payload = null;

    if (localStorage.getItem("userId")) {
      userId = localStorage.getItem("userId");
      async function fetchData() {
        // You can await here
        try {
          _payload = await agent.Auth.current(userId);
          onAppLoad(_payload);
        } catch (error) {
          if (error.response && error.response.statusCode === 400) {
            onAppLoad(_payload);
          }
        }
      }
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
    }
  }, [currentUser]);

  return (
    <>
       <HelmetProvider>

      <div className="main-body">
        <Suspense fallback={null}>
          <RouteList />
        </Suspense>
      </div>
      </HelmetProvider>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
