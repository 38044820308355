import { Fragment } from 'react';

const MemorialItem = ({ item, handleProductClick }) => {
  return (
    <Fragment>
      <div className='product-col' onClick={() => handleProductClick(item)}>
        <div className='product-plant'>
          <img
            src={item?.images[0] || 'https://placehold.co/200x200'}
            alt='product'
          />
          <span>{item?.itemType || '--'}</span>
          <h3>{item?.title || '--'}</h3>
          <span className='price'>£{item?.price.toFixed(2) || '--'}</span>
        </div>
      </div>
    </Fragment>
  );
};

export default MemorialItem;
