import React, { Suspense } from 'react';
import bannerimg from "../../assets/images/banner-img.3.png";
import ImageLoading from '../Common/skeleton/ImageLoading';

const LazyImage = React.lazy(() => import("../Common/Optemize/LazyImage")); 


const AboutHeader = () => {
  return (
    <>
      <section id="banner-img-main">
        <div className="custom-banner-img">
        <Suspense fallback={<ImageLoading/>}>
        <LazyImage src={bannerimg} alt="Placeholder Image" />
        </Suspense>
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>About Inspired Meadows</h1>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default AboutHeader;
