import SEO from "../SEO/SEO";
import MainView from "./MainView";

const Cart = () => {
  return (
    <div className="cart-page">
      <SEO
				title="Inspired Meadows | Cart"
       />
      <MainView />
    </div>
  )
}

export default Cart;
