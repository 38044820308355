import { useDispatch } from 'react-redux';

import MemorialItem from './MemorialItem';

import {
  setIsMemorialDetailModalOpen,
  setMemorialDetail,
} from '../../reducers/slices/products';
import Loader from '../components/Loader';

const MemorialList = ({ data, isLoading, error }) => {
  const dispatch = useDispatch();

  if (isLoading) return <div><Loader/></div>;

  if (!isLoading && error !== '') return <div>{error}</div>;

  const handleShowMemorialDetails = detail => {
    dispatch(setMemorialDetail(detail));
    dispatch(setIsMemorialDetailModalOpen({ isOpen: true }));
  };

  if (!isLoading && data?.length) {
    return data.map(item => (
      <MemorialItem
        key={item.id}
        item={item}
        handleProductClick={handleShowMemorialDetails}
      />
    ));
  } else {
    return <div>No products found</div>;
  }
};

export default MemorialList;
