import { combineReducers } from "redux";
import common from "./common";
import { routerReducer } from "react-router-redux";

import productsReducer from './slices/products'
import headerReducer from "./slices/headerSlice";

export default combineReducers({
    common,
    router: routerReducer,
    products: productsReducer,
    header: headerReducer,
});
