

import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import goBackImg from '../../assets/images/arrow_left.png';
import SEO from '../SEO/SEO';

const PrivacyPolicy = (props) => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1); // This will navigate back to the previous page.
    };
    return (
        <Fragment>
            <SEO
				title="Inspired Meadows | Privacy Policy "
       />
            <section id="terms-main">
                <div class="container">
                    <div class="row">
                        <div class="terms-col">
                            <div class="terms-details">
                                <div class="back-btn" onClick={handleGoBack}>
                                    <Link to="#"><img src={goBackImg} alt="arrow left icon" />Go back</Link>
                                </div>
                                <div className='policy-head'>
                                    <div className='heading'>
                                        <h1>Data Policy - Inspired Meadows Ltd.</h1>
                                        <span className='last-update'>LAST UPDATED JANUARY 2024</span>
                                    </div>
                                    <p>
                                        Thank you for choosing Inspired Meadows. This Data Policy outlines how we collect, use, share, and protect your personal information when you use our website and services. By accessing or using our services, you consent to the practices described in this policy.
                                    </p>
                                </div>
                                <div className='policy-list'>
                                    <div className='policy'>
                                        <h3>1. Information We Collect</h3>
                                        <div className='policy-category'>
                                            <b>(a) Personal Information</b>
                                            <p>We collect the following personal information necessary to carry out our obligations and services:</p>
                                            <p>Contact Information: Name, email address, phone number, and home address.</p>
                                            <p>Billing Information: Credit card details, billing address, and payment-related information.</p>
                                            <p>Purchase History: Details of products or services you have purchased from us.	</p>
                                            <p>
                                                Account Information: Username, password, and account preferences.
                                            </p>
                                            <p>
                                                Deceased Information: Details of the deceased, including name, date of birth, and date of passing.
                                            </p>
                                            <p>
                                                Next of Kin Information: Details of the next of kin or responsible party, including name and contact information.
                                            </p>
                                        </div>

                                        <div className='policy-category'>
                                            <b>(b) Automatically Collected Information</b>
                                            <p>
                                                We may also collect information automatically when you visit our website, including:
                                            </p>
                                            <p>
                                                Device Information: IP address, browser type, device type, and operating system.
                                            </p>
                                            <p>
                                                Usage Information: Pages you visit, links you click, and time spent on our site.
                                            </p>
                                            <p>
                                                Cookies: We use cookies to enhance your experience and track your preferences.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='policy'>
                                        <h3>2. How We Use Your Information</h3>
                                        <p>
                                            We use your information for the following purposes:
                                        </p>
                                        <p>
                                            To process and complete purchase documentation.
                                        </p>
                                        <p>
                                            To communicate with you regarding burial arrangements.
                                        </p>
                                        <p>
                                            To comply with legal and regulatory requirements.
                                        </p>
                                        <p>
                                            To provide customer support and address enquiries.
                                        </p>
                                        <p>
                                            To improve our website, services, and user experience.
                                        </p>
                                        <p>
                                            To send promotional emails and updates (you can opt-out at any time).
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>3. Information Sharing</h3>
                                        <p>
                                            We may share your information with third parties for the following purposes:
                                        </p>
                                        <p>
                                            Payment Processors: We use secure payment platforms to process payments for burial plot purchases.
                                        </p>
                                        <p>
                                            Legal Compliance: We may disclose information to comply with legal obligations or respond to legal requests.
                                        </p>
                                        <p>
                                            Service Providers: We may share data with third-party service providers who assist us in various business operations.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>4. Data Retention</h3>
                                        <p>
                                            We retain your personal information only for as long as necessary to fulfil the purposes outlined in this policy and as required by law.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>5. Your Choices</h3>
                                        <p>
                                            Opt-Out: You have the option to opt-out of promotional communications by contacting us at <span>info@inspiredmeadows.co.uk</span>.
                                        </p>
                                        <p>
                                            Communication Preferences: You can opt-out of marketing emails by using the unsubscribe link in the email.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>6. Children's Privacy</h3>
                                        <p>
                                            Our website is not intended for individuals under the age of 16. We do not knowingly collect personal information from children unless provided to us by the Parent(s).
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>7. Security</h3>
                                        <p>
                                            We take the security of your information seriously. We employ industry-standard measures to protect your data from unauthorized access, disclosure, alteration, and destruction.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>8. Consent for Data Processing</h3>
                                        <p>
                                            By using our services, you provide explicit consent for the processing of your personal data as outlined in this policy.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>9. Changes to this Policy</h3>
                                        <p>
                                            We may update this Data Policy to reflect changes in our practices or for legal reasons. We will notify you of any significant changes through our website or other communication methods.
                                        </p>
                                    </div>
                                    <div className='policy'>
                                        <h3>10. Contact Us</h3>
                                        <p>
                                            If you have any questions or concerns about our Data Policy, please contact us at <span>info@inspiredmeadows.co.uk</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default PrivacyPolicy; 