import { Fragment, useEffect } from 'react';

const ReadMorePopup = ({ activeReadMore, onClose, activeValue }) => {
    return (
        <Fragment>
            <div
                onClick={onClose}
                style={{ position: 'fixed' }}
                className={`overlay ${activeReadMore ? 'cart-show' : ''}`}
            />

            <div className={`cart-carousel cart-success ${activeReadMore ? 'cart-show' : ''}`}>
                {activeValue === "burial" ?
                <p>The <strong>Arca Urn</strong>, crafted from recycled paper and designed to
                resemble the Acorn from the mighty Oak tree, and is available
                in a variety of shades.  The Acorn, being a cherished food
                source for woodland creatures, this Urn option fits well with
                our commitment towards preserving the natural world.<br/>
                <strong>Casket</strong> materials that align with our sustainability ethos
                are, Willow or Bamboo being the most favourable choices,
                with Banana Leaves, or Seagrass as alternative options.
                Another sustainable material is cardboard which can be customised
                accordingly.  For a handcrafted option, Somerset Willow Coffins
                provide a range of willow caskets. As an alternative, you may
                consider a biodegradable <strong>Shroud</strong>. Made from materials such as hemp, wool,
                or cotton, these shrouds offer a natural and eco-friendly option..</p> 
                :
                <p>
                Parkin & Jackson in Kendal, with over 150 years of experience, use a locally 
                quarried material called Lakeland Slate for <strong>Memorial Plaques</strong>. The Lakeland slate 
                comes in a shade of blue or green, the green having unique markings and patterns on 
                each stone. If required, they can help you select the right memorial plaque design 
                and inscription for your loved one.  We can accept plaques with measurements up to 
                50cm x 50cm and plaques will be laid flat to earth within the plot.
                </p>
                }

                <button onClick={onClose} class='ok-btn'>
                    Ok
                </button>
            </div>
        </Fragment>
    );
};

export default ReadMorePopup;
