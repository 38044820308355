import { Fragment, useState } from 'react';

import './loader.css';

const AdditionalItem = ({ item, updateProductQuantity, removeCartProduct }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Fragment>
      <div className='cart-item'>
        <div className='cart-img'>
          <img
            src={
              item.additionalItemData.images
                ? item?.additionalItemData?.images[0]
                : 'https://placehold.co/200x200'
            }
            alt=''
          />
        </div>
        <div className='cart-details'>
          <h3>{item?.additionalItemData?.title || '--'}</h3>
          <span>{item.productType || '--'}</span>
          <p>{item?.additionalItemData?.description || '--'}</p>
          <div className='cart-quantity'>
            <div className='quantity-btn'>
              <button
                className='quantity-low'
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  item.quantity > 1 && updateProductQuantity(item.id, 'decrement', setIsLoading)
                }
              >
                <i className='fa fa-minus' aria-hidden='true'></i>
              </button>
              {isLoading ? <Loader /> : <button>{item.quantity}</button>}
              <button
                className='quantity-high'
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  updateProductQuantity(item.id, 'increment', setIsLoading)
                }
              >
                <i className='fa fa-plus' aria-hidden='true'></i>
              </button>

              <span>
                {item.quantity} x £{item?.additionalItemData?.price?.toFixed(2)}{' '}
                = £
                {(item?.additionalItemData?.price * item?.quantity).toFixed(2)}
              </span>
            </div>

            <div className='cart-item-remove'>
              <button
                className='remove-item'
                onClick={() => removeCartProduct(item.id)}
              >
                <i className='fa fa-trash-o' aria-hidden='true'></i>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const Loader = () => {
  return (
    <button
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className='loadingio-spinner-rolling-4cqbtlamiq8'>
        <div className='ldio-9ohg9q9skfh'>
          <div></div>
        </div>
      </div>
    </button>
  );
};

export default AdditionalItem;
