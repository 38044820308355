import React, { useEffect, useState } from "react";
import "./pdfView.css";
// import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Arrow_left from "../../assets/images/arrow_left.png";
import { Viewer, Worker } from "@react-pdf-viewer/core";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import SEO from "../SEO/SEO";

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfView = () => {
  const selectedPdf = useSelector((state) => state?.header?.selectedPdf);
  const [numPages, setNumPages] = useState(null);
  const navigate = useNavigate();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClick = () => {
    navigate(-1);
  };






  return (
    <>
           <SEO
              title="Pdf Page"
              description="Inspired meadows Pdf Page"
              keywords="Brochure"
             />
      <div className="pdf-container">
        <div className="pdf-back-btn">
          <div
            onClick={handleClick}
            className="back_icon"
            style={{ marginBottom: "19px" }}
          >
            <img src={Arrow_left} alt="Go Back" /> Go Back{" "}
          </div>
        </div>
        {selectedPdf ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={selectedPdf} />
          </Worker>
        ) : (
          <div>No pdf selected</div>
        )}
      </div>
    </>
  );
};

export default PdfView;
