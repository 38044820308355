import meadowImage from "../../assets/images/compress/meadow-img-c.png";
import { Link } from 'react-router-dom';
import angleicon from "../../assets/images/angle-icon.png";
import React, { Suspense } from "react";
import ImageLoading from "../Common/skeleton/ImageLoading";

const LazyImage = React.lazy(() => import("../Common/Optemize/LazyImage")); 

const WhyInspireMeadows = () => {
  return (
    <>
     <section id="why-inspired-main">
        <div className="container">
          <div className="inspired-content">
            <h2>Why Inspired Meadows?</h2>
            <div className="meadow-img">
            <Suspense fallback={<ImageLoading/>}>
                <LazyImage src={meadowImage} alt="meadowImage image"/>
                </Suspense>
            {/* <img
                  alt={"overview image"}
                  src={meadowImage} 
                /> */}
              
            </div>
            <p className="p-spacing">
              At Inspired Meadows, we believe in providing a final resting place
              that embraces the beauty of nature and offers a meaningful and
              sustainable way to honor your loved ones. Our natural burials
              offer a range of unique benefits that go beyond traditional
              funeral practices. With our eco-friendly approach, we prioritize
              environmental stewardship and offer serene gardens of remembrance
              where families can find solace and peace. Our commitment to
              sustainability means that every aspect of our end-of-life planning
              process is designed to minimize our impact on the planet.
            </p>
            <p>
              By choosing a natural burial, you not only contribute to the
              preservation of our environment but also create a lasting legacy
              for generations to come. Our personalized farewell services allow
              you to reflect on the cherished memories of your loved ones and
              create a meaningful tribute that truly reflects their unique life
              and values.
            </p>
            <Link to="/learnmore">
              Learn More
              <img src={angleicon} alt="meadow" />
            </Link>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default WhyInspireMeadows;
