import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import MainView from './MainView';
import Calendly from './Calendly';
import SEO from '../SEO/SEO';


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const SchedulCall = (props) => {
	const [step, setStep] = useState(1);
  	const [selectedUserUrl, setSelectedUserUrl] = useState('');
  
	const handleNextStep = (url) => {
		setSelectedUserUrl(url);
		setStep(step + 1);
	};
	const handleBack = () => setStep(step - 1)
	let mainProps = { handleNextStep };
	let calenderProps = { selectedUserUrl, handleBack };
	return (
		<div className="schedulcall-page">
			 <SEO
				title="Schedule a call with Inspired Meadows"
				description="Schedule a call with Inspired Meadows for personalized support and guidance in end-of-life decisions. Our compassionate team is here to help you with care."
                keywords="Personalized farewell, Empathy and guidance in end-of-life decisions, Compassionate support for families"
				href="https://www.inspiredmeadows.co.uk/schedule-a-call"
             />
			 {/* <link rel="canonical" href="https://www.inspiredmeadows.co.uk/schedule-a-call"/> */}
			{step === 1 && <MainView {...mainProps} />}
      		{step === 2 && <Calendly {...calenderProps} />}
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulCall);