import React from "react";
import BrochurePdf from "../../assets/Brochure_compressed.pdf";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import downloadIcon from "../../assets/images/downloadsvg.svg";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import SEO from "../SEO/SEO";



const Brochure = () => {

    const onBrochureButtonClick = () => {
        const pdfUrl = BrochurePdf;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.setAttribute("download", "Brochure.pdf"); 
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  return (
    <>
      <div className="brochure_pdf-container">
      <SEO
        title="Inspired Meadows | Brochure"
      />
        {
          BrochurePdf && <div className="download_btn">
          <div className="download_btn"  onClick={onBrochureButtonClick}>
            <img src={downloadIcon} alt="Go Back" height={20} width={20} />
        </div>
          </div>
        }

        {BrochurePdf ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={BrochurePdf}
            />
          </Worker>
        ) : (
          <div>No pdf selected</div>
        )}
      </div>
    </>
  );
};

export default Brochure;
