import React, { useState } from 'react';
import commitimg from "../../assets/images/commit-img.png";
import AboutSecond from "../../assets/images/About_second.png";
import Aboutthird from "../../assets/images/About_third.png";
import AboutFourth from "../../assets/images/About_fourth.png";
import AboutFive from "../../assets/images/environmental.jpg";

const AccordionItem = ({
    title,
    content,
    activeItem,
    setActiveItem,
    index,
  }) => {
    const isActive = activeItem === index;
  
    const toggleActive = () => {
      setActiveItem(isActive ? null : index);
    };
  
    return (
      <div className="commit-tab" onClick={toggleActive}>
        <span className={`accordion-item ${isActive ? "active-tab" : ""}`}>
          {title}
        </span>
        <p>{content}</p>
      </div>
    );
  };

  
const CommitmentSection = () => {
  const initialActiveIndex = 0;

    const [activeItem, setActiveItem] = useState(initialActiveIndex);
    const accordionItems = [
        {
          title: "Green Burial Sites",
          img: commitimg,
          content:
            "Our burial grounds are meticulously designed to blend seamlessly with the surrounding natural environment. We prioritize the preservation of native flora and fauna, creating tranquil and biodiverse spaces that pay homage to the beauty of nature.",
        },
        {
          title: "Conservation Efforts",
          img: AboutSecond,
          content:
            "We actively engage in conservation efforts, protecting and restoring natural habitats within our burial sites. By promoting biodiversity and preserving sensitive ecosystems, we contribute to the overall health and sustainability of the surrounding areas.",
        },
        {
          title: "Carbon Neutrality",
          img: Aboutthird,
          content:
            "Inspired Meadows is dedicated to minimizing greenhouse gas emissions. We carefully manage our carbon footprint by implementing energy-efficient practices, utilising renewable energy sources, and offsetting our remaining emissions through verified carbon offset projects.",
        },
        {
          title: "Sustainable Materials",
          img: AboutFourth,
          content:
            "From the construction of our facilities to the products and materials we use, we prioritise sustainability. We source eco-friendly materials, employ energy-efficient technologies, and reduce waste through recycling and responsible resource management.",
        },
        {
          title: "Environmental Education",
          img: AboutFive,
          content:
            "We believe in raising awareness and educating the community about the importance of sustainable practices. Through various initiatives, we aim to inspire others to embrace environmentally conscious choices and become stewards of the natural world.",
        },
      ];
  return (
    <>
    <section id="Inspired-commitment">
        <div className="container">
          <div className="row">
            <div className="commit-header-col">
              <div className="commit-header">
                <h2>
                  Emphasising Inspired Meadows' commitment to sustainability and
                  environmental stewardship
                </h2>
                <p className="p-spacing">
                  At Inspired Meadows, we take great pride in our unwavering
                  commitment to sustainability and environmental stewardship. We
                  believe that preserving the beauty of our natural surroundings
                  is not only a responsibility but also a core value that
                  defines who we are as a company.
                </p>
                <p>
                  As a leader in the industry, we strive to minimize our
                  ecological footprint by implementing sustainable practices at
                  every stage of our operations. Here are some of the ways we
                  demonstrate our commitment to sustainability:
                </p>
              </div>
            </div>
            <div className="commit-details-col">
              <div className="commit-details">
                {accordionItems.map((item, index) => (
                  <AccordionItem
                    key={index}
                    title={item.title}
                    content={item.content}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    index={index}
                  />
                ))}
              </div>
            </div>
            <div className="commit-img-col">
              <div className="commit-img">
                {activeItem !== null && (
                  <img
                    src={accordionItems[activeItem].img}
                    alt={`commit image ${activeItem}`}
                  />
                )}
              </div>
            </div>
            <div className="commit-footer-col">
              <div className="commit-footer">
                <p>
                  By choosing Inspired Meadows, families can trust that their
                  loved ones' final resting place is in harmony with the
                  environment. Our commitment to sustainability not only honors
                  the memory of those we serve but also contributes to a
                  healthier and more sustainable future for generations to come.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default CommitmentSection;
