import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const AccordionItem = ({ number, title, content, videoUrl, isExpanded, toggleAccordion }) => {
 

  return (
    <>
      <li className={`faq-active ${isExpanded ? 'open-accordion' : ''}`}>
      <span onClick={() => toggleAccordion(number)}>{`${number + 1}. ${title}`}</span>
        <span className="plus-btn" onClick={() => toggleAccordion(number)}>
          <i
            className="fa fa-plus-circle"
            aria-hidden="true"
            style={{ display: isExpanded ? 'none' : 'inline-block' }}
            onClick={toggleAccordion}
          ></i>
          <i
            className="fa fa-minus-circle"
            aria-hidden="true"
            style={{ display: !isExpanded ? 'none' : 'inline-block' }}
            onClick={toggleAccordion}
          ></i>
        </span>
        {isExpanded && (
          <div className="faq-content">
            <p>{content}</p>
            <div className="faq-video">
              <ReactPlayer
                url={videoUrl} 
                width="640"
                height="360"
                controls 
                onError={(error) => console.error('Error playing video:', error)}
              />
             
            </div>
          </div>
        )}
      </li>
    </>
  );
};

export default AccordionItem;
