import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ImageLoading = () => {
  return (
    <>
    <Skeleton height={400} />
    </>
  )
}

export default ImageLoading