import { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SuccessRight from '../../assets/images/success-rigth.png';
import { setIsAddToCartSuccessModalOpen } from '../../reducers/slices/products';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  isModalOpen: state.products.isAddToCartSuccessModalOpen,
});
const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
  closeModal: () => dispatch(setIsAddToCartSuccessModalOpen({ isOpen: false })),
});

const SuccessModal = props => {
  const { closeModal, isModalOpen } = props;

  useEffect(() => {
    if (isModalOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';

    return () => (document.body.style.overflow = '');
  }, [isModalOpen]);

  return (
    <Fragment>
      <div
        onClick={closeModal}
        style={{ position: 'fixed' }}
        className={`overlay ${isModalOpen ? 'cart-show' : ''}`}
      />

      <div class={`cart-success ${isModalOpen ? 'cart-show' : ''}`}>
        <img src={SuccessRight} alt='success icon' />

        <span>Success!</span>
        <p>Your product has been added to your cart section successfully.</p>
        <Link onClick={closeModal} to='/cart' class='ok-btn'>
          Ok
        </Link>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);
