import { Fragment } from 'react';

const PlotItem = ({ item, removeCartProduct }) => {

  const plotNumbers = item?.plotData?.plots.map((plot) => plot?.plotNumber) || [];
  const sortedPlotNumbers = [...plotNumbers].sort((a, b) => {
    const numericPartA = parseInt(a.match(/\d+/)[0], 10); // Extract numeric part
    const numericPartB = parseInt(b.match(/\d+/)[0], 10);
    return numericPartA - numericPartB;
  });

 
  return (
    <Fragment>
      <div className='cart-item'>
        <div className='cart-img'>
          <img
            src={
              item?.plotData?.plots[0]?.images?.[0]
                ? item?.plotData?.plots[0]?.images?.[0]
                : 'https://placehold.co/200x200'
            }
            alt=''
          />
        </div>
        <div className='cart-details'>
          <h3>{item?.plotData?.siteName || '--'}</h3>
          <span>{sortedPlotNumbers.length > 1 ? item?.productType : item?.productType.slice(0 , -1)  } {`(${item?.plotData?.type})`}</span>
          {sortedPlotNumbers.length > 0 ? (
            sortedPlotNumbers.map((digit) => (
              <button key={digit}>Plot: #{digit || ' --'}</button>
            ))
          ) : (
            <button>
              Plot: #{item?.plotData?.plots?.[0]?.plotNumber || ' --'}
            </button>
          )}  
          <p>{item?.plotData?.plots?.[0]?.description || '--'}</p>
          <div className='cart-quantity'>
            <div className='quantity-btn'>
              <span>£{item?.plotData?.totalPrice?.toFixed(2)}</span>
            </div>

            <div className='cart-item-remove'>
              <button
                className='remove-item'
                onClick={() => removeCartProduct(item.id)}
              >
                <i className='fa fa-trash-o' aria-hidden='true'></i>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PlotItem;
