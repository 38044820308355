import React from 'react';
// Public Routes
import Home from '../Home';
import AboutUs from '../AboutUs';
import NaturalBurials from '../NaturalBurials';
import Location from '../Location';
import LocationDetail from '../Location/Detail';
import Blog from '../Blog';
import BlogDetail from '../Blog/Detail';
import ProfilePage from '../User/ProfilePage';
import SchedulCall from '../SchedulCall';
import FAQ from '../FAQ/index';
import ContactUs from '../ContactUs';
import Products from '../Products';
import Cart from '../Cart';
import TermsAndCondition from '../TermsAndCondition/TermsAndCondition';
import PrivacyPolicy from '../TermsAndCondition/PrivacyPolicy';
import LearnMore from '../Home/LearnMore';
import StoryBehind from '../Home/StoryBehind';
import PlanningAhead from '../Home/PlanningAhead';
import OverviewMeadows from '../Home/OverviewMeadows';
import PetLearnMore from '../Home/PetLearnMore';
import IntermentFormOne from '../Layouts/IntermentFormOne';
import CookiePolicy from "../TermsAndCondition/CookiePolicy"
import Brochure from '../Brochure/Brochure';
import PageNotFound from '../404NotFound/PageNotFound';


export const baseRoutes = [
	{
		name: 'Home',
		path: '/',
		component: <Home />
	},
	{
		name: 'LearnMore',
		path: '/learnmore',
		component: <LearnMore />
	},
	{
		name: 'Story behind',
		path: '/story-behind',
		component: <StoryBehind />
	},
	{
		name: 'Planning Ahead',
		path: '/planning-ahead',
		component: <PlanningAhead/>
	},
	{
		name: 'OverView',
		path: '/overview',
		component: <OverviewMeadows/>
	},
	{
		name: 'PetLearnMore',
		path: '/pet-learnmore',
		component: <PetLearnMore />
	},
	{
		name: 'About Us',
		path: '/about-us',
		component: <AboutUs />
	},
	{
		name: 'Products',
		path: '/products/:id',
		component: <Products />
	},
	{
		name: 'Products',
		path: '/products',
		component: <Products/>
	},
	{
		name: 'Location',
		path: '/locations',
		component: <Location />
	},
		
	{
		name: 'Location',
		path: '/location/:id/map',
		viewMode: 'map',
		component: <LocationDetail />
	},
	{
		name: 'Location Detail',
		path: '/location/:id',
		viewMode: 'details',
		component: <LocationDetail />
	},
	{
		name: 'Blog',
		path: '/blogs',
		component: <Blog />
	},
	{
		name: 'Blog Detail',
		path: '/blog/:id',
		component: <BlogDetail />
	},
	{
		name: 'User Profile',
		path: '/user/my-profile',
		component: <ProfilePage />
	},
	{
		name: 'schedule a call',
		path: '/schedule-a-call',
		component: <SchedulCall />
	},
	{
		name: 'faqs',
		path: '/faqs',
		component: <FAQ />
	},
	// {
	// 	name: 'Interment-form',
	// 	path: '/interment-form',
	// 	component: <IntermentForm />
	// },
	{
		name: 'Interment-form-one',
		path: '/interment-form',
		component: <IntermentFormOne />
	},
	{
		name: 'naturalburials',
		path: '/natural-burials',
		component: <NaturalBurials />
	},
	{
		name: 'contact us',
		path: '/contact-us',
		component: <ContactUs />
	},
	{
		name: 'Cart',
		path: '/cart',
		component: <Cart />
	},
	{
		name: 'terms & condition',
		path: '/terms&condition',
		component: <TermsAndCondition />
	},
	{
		name: 'Privacy Policy',
		path: '/privacy-policy',
		component: <PrivacyPolicy />
	},
	{
		name:'CookiePolicy',
		path:"/CookiePolicy",
		component: <CookiePolicy />
	},
	{
		name:'Brochure',
		path:"/brochure",
		component: <Brochure />
	},
	
];