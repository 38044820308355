import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // Import your root reducer
import { mainMiddleware, authMiddleware } from './middleware';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(mainMiddleware, authMiddleware),
  devTools: process.env.NODE_ENV !== 'production', // Enable DevTools in development
});

export default store;
