import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import MainView from "./MainView";
import SEO from "../SEO/SEO";
import useDocumentTitle from "../hooks/UseDocumentTitle";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const Home = (props) => {
  let mainProps = {};
  useDocumentTitle("Home | Natural Burials and Eco-Friendly Burials");

  return (
    <div className="home-page">
      <SEO
        title="Home | Natural Burials and Eco-Friendly Burials"
        description="Welcome to home page of Inspired Meadows, a serene haven for eco-friendly natural burials and compassionate pet burials, including pet graves."
        keywords="Pet burials, Pet grave, Sustainable end-of-life planning, Traditional cemeteries"
        href="https://www.inspiredmeadows.co.uk"
      />
     
      <MainView {...mainProps} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
