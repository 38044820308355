import React from "react";
import { Fragment } from "react";
import petImg from "../../assets/images/pet_img.png";
import petLives from "../../assets/images/pet_lives-img.png";
import Celevration from "../../assets/images/celevration_img.png";
import Nature_img from "../../assets/images/nature_img.png";
import forever_img from "../../assets/images/forever_img.png";
import goodbye_img from "../../assets/images/goodbye_img.png";
import sunrise_img from "../../assets/images/sunrise_bg_img1.png";
import petImgTwo from "../../assets/images/pet_img_two.png";
import phoneIcon from "../../assets/images/Phone-icon.png";
import email_icon from "../../assets/images/email_icon.png";
import Arrow_left from "../../assets/images/arrow_left.png";
import angle_right from "../../assets/images/angle-right-icon.png";
import { Link } from "react-router-dom";
import SEO from "../SEO/SEO";

const PetLearnMore = () => {
  return (
    <Fragment>
        <SEO
            title="Inspired Meadows | Pet Learn More"
            />
      <section id="pet-burials-main">
        <div class="container">
          <div class="row">
            <div class="back-btn-col">
              <div class="back-btn">
                <div className="back-link">
                  <img src={Arrow_left} alt="arrow left icon" />
                  Go back
                </div>
              </div>
            </div>
            <div class="pet-burial-col">
              <div class="pet-burials-find">
                <div class="pet-burial-left">
                  <span>Pet Burials with Inspired Meadows </span>
                  <h2>Where Love Finds Its Eternal Meadow</h2>
                  <p>
                    At Inspired Meadows, we get it – pets are family. They're
                    our loyal friends, our comforting companions, and they love
                    us unconditionally. That's why we've created a special place
                    for them right in the heart of the stunning Lake District.{" "}
                  </p>
                </div>
                <div class="pet-burial-right">
                  <img src={petImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pet-live-main">
        <div class="container">
          <div class="row">
            <div class="pet-burial-col">
              <div class="pet-burials-find">
                <div class="pet-burial-right">
                  <img src={petLives} alt="" />
                </div>
                <div class="pet-burial-left">
                  <h2>Where Love Lives On</h2>
                  <p>
                    Inspired Meadows isn't just a burial ground; it's a haven
                    where memories and the unbreakable bonds we share with our
                    pets are honoured. Here, surrounded by the natural beauty of
                    Kendal’s rolling hills and peaceful skies, the spirit of
                    your beloved pet finds a respectful and beautiful resting
                    place.{" "}
                  </p>
                  <p class="py">
                    We offer more than a final goodbye; we provide a way to keep
                    your pet close in a place that celebrates the love and joy
                    they brought into your life. In this tranquil setting, your
                    shared stories and memories take root, creating a legacy
                    that lasts forever in nature's embrace.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Honouring-main">
        <div class="container">
          <div class="row">
            <div class="Honouring-col">
              <div class="Honouring">
                <h2>Honouring Every Journey with Compassion</h2>
                <img src={petImgTwo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pet-burials-main" class="celebration-main">
        <div class="container">
          <div class="row">
            <div class="pet-burial-col">
              <div class="pet-burials-find">
                <div class="pet-burial-left">
                  <h2>A Celebration of Lifelong Bonds</h2>
                  <p>
                    In the journey of life, every chapter is precious —
                    especially the final ones. At Inspired Meadows, we
                    understand this profoundly. That's why we offer a choice
                    that speaks directly to the heart. Here, you can lay to rest
                    the cremated remains of your beloved pets alongside your own
                    future resting place, creating a shared sanctuary that's as
                    much about celebrating life as it is about honouring its
                    passing.{" "}
                  </p>
                </div>
                <div class="pet-burial-right">
                  <img src={Celevration} alt="" />
                </div>
              </div>
            </div>
            <div class="celebration-detail-col">
              <div class="celebration-detail-main">
                <div class="celebration-detail">
                  <p>
                    Choosing a plot at Inspired Meadows isn't just about finding
                    a burial space; it's about making a decision filled with
                    foresight, care, and deep love. It's acknowledging that the
                    joy, the companionship, and the unbreakable bond you shared
                    with your pet deserves to be remembered and celebrated.{" "}
                  </p>
                </div>
                <div class="celebration-detail">
                  <p>
                    Our commitment is to provide a space where memories are not
                    just preserved but cherished in a living landscape. Amidst
                    the natural beauty of the Lake District, every tree, stream,
                    and bird's song become part of the tribute to the
                    relationship you shared with your pet. It's a place where
                    laughter and paws once intertwined, and where those moments
                    find their everlasting peace.{" "}
                  </p>
                </div>
                <div class="celebration-detail">
                  <p>
                    At Inspired Meadows, we offer more than a resting place; we
                    provide a space for stories to live on — stories of wagging
                    tails, of purrs, of steadfast companionship through thick
                    and thin. In this serene haven, the spirit of your cherished
                    pet, and the special bond you shared, is immortalized,
                    letting the legacy of love you built together continue
                    forever.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pet-burials-main" class="celebration-main embrace-main">
        <div class="container">
          <div class="row">
            <div class="pet-burial-col">
              <div class="pet-burials-find">
                <div class="pet-burial-right">
                  <img src={Nature_img} alt="" />
                </div>
                <div class="pet-burial-left">
                  <h2>Nature's Embrace in Your Memorial Garden</h2>
                  <p>
                    Tucked away in the serene countryside of the Lake District,
                    Inspired Meadows is more than just a burial ground – it's a
                    natural haven where memories bloom as beautifully as the
                    flora around. Each personal memorial garden is a testament
                    to a life shared with a beloved pet, reflecting the pure
                    affection that defined your time together.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="celebration-detail-col">
              <div class="celebration-detail-main">
                <div class="celebration-detail">
                  <p>
                    Amidst the lush, whispering grasses and under skies that
                    stretch calmly above, Inspired Meadows offers a resting
                    place that's both dignified and deeply peaceful. It's a
                    space where the spirit of your pet is cradled by nature's
                    loving arms, surrounded by the gentle beauty of the
                    countryside.{" "}
                  </p>
                </div>
                <div class="celebration-detail">
                  <p>
                    Our gardens are carefully cultivated to not only provide
                    solace but also to mirror the joy and unconditional love
                    pets bring into our lives. In every blooming flower,
                    rustling leaf, and tranquil sunrise, there's a sense of
                    connection—a feeling that the bond you shared with your pet
                    continues to thrive in the nurturing embrace of nature.{" "}
                  </p>
                </div>
                <div class="celebration-detail">
                  <p>
                    Here, every moment of companionship is honoured in a setting
                    that's as extraordinary as the relationship you shared. In
                    the tranquillity of the Lake District, Inspired Meadows is a
                    place where memories linger softly, and love remains as
                    enduring as the timeless landscape around.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="pet-burials-main"
        class="celebration-main forever-heart-main"
      >
        <div class="container">
          <div class="row">
            <div class="pet-burial-col">
              <div class="pet-burials-find">
                <div class="pet-burial-left">
                  <h2>Forever Together in Nature's Heart</h2>
                  <p>
                    In the journey of life, every chapter is precious —
                    especially the final ones. At Inspired Meadows, we
                    understand this profoundly. That's why we offer a choice
                    that speaks directly to the heart. Here, you can lay to rest
                    the cremated remains of your beloved pets alongside your own
                    future resting place, creating a shared sanctuary that's as
                    much about celebrating life as it is about honouring its
                    passing.{" "}
                  </p>
                  <p class="py">
                    It's a promise of reunion, of being together again, wrapped
                    in the serene and tender embrace of nature. Imagine the
                    peace that comes with knowing that one day, you'll both rest
                    side by side, surrounded by the quiet majesty of rolling
                    hills, open skies, and the gentle whispers of the
                    countryside.
                  </p>
                </div>
                <div class="pet-burial-right">
                  <img src={forever_img} alt="" />
                </div>
              </div>
            </div>
            <div class="celebration-detail-col">
              <div class="celebration-detail-main">
                <div class="celebration-detail">
                  <p>
                    This concept isn't just about planning ahead; it's a deeply
                    touching gesture of love and loyalty. It symbolises a
                    connection so profound that it defies the limits of time —
                    ensuring that when your own journey comes to its natural
                    end, you will be reunited with your furry companion in a
                    place of tranquil beauty.{" "}
                  </p>
                </div>
                <div class="celebration-detail">
                  <p>
                    A plot at Inspired Meadows isn’t just a piece of land; it’s
                    a shared haven where memories are safeguarded, and the
                    legacy of your bond can flourish forever.{" "}
                  </p>
                </div>
                <div class="celebration-detail">
                  <p>
                    Here, in the heart of nature's splendour, rest assured that
                    the story of your life and the special role your pet played
                    in it will be forever interwoven into the landscape's
                    timeless tapestry.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pet-live-main" class="goodbye-main">
        <div class="container">
          <div class="row">
            <div class="pet-burial-col">
              <div class="pet-burials-find">
                <div class="pet-burial-right">
                  <img src={goodbye_img} alt="" />
                </div>
                <div class="pet-burial-left">
                  <h2>A Final Act of Love and Kindness</h2>
                  <p>
                    When you choose Inspired Meadows, you're not just making a
                    choice for the present; you're performing an act of profound
                    love and respect. It's a way to ensure that the happiness,
                    loyalty, and unwavering devotion your pet brought into every
                    corner of your life are cherished forever. This decision
                    goes beyond the practicalities of planning; it's about
                    creating a lasting tribute to a relationship that deeply
                    touched your heart.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pet-burials-main" class="sunrise-main">
        <div class="container">
          <div class="row">
            <div class="pet-burial-col">
              <div class="pet-burials-find">
                <div class="pet-burial-left">
                  <p>
                    Nestled in the tranquil beauty of Kendal's countryside,
                    Inspired Meadows offers a place where the special story of
                    your life with your pet resonates in every leaf, every bird
                    song, and every gentle breeze. Here, your shared memories
                    are not confined to the past; they continue to live on,
                    embraced by the natural splendour and peace of our carefully
                    tended grounds.
                  </p>
                  <p class="py">
                    In this quiet, beautiful space, each moment you spent
                    together finds a voice. The joyous runs, the quiet evenings,
                    the playful antics, and the comforting presence of your pet
                    are all part of the rich tapestry that makes up your
                    memorial garden. In the vast, open skies and the timeless
                    landscape of the Lake District, the essence of your bond
                    with your pet is forever woven into the earth's enduring
                    beauty, creating a legacy as timeless as the meadows
                    themselves.
                  </p>
                </div>
                <div class="pet-burial-right">
                  <img src={sunrise_img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="petburial-paragraph">
        <div class="container">
          <div class="row">
            <div class="paragraph-col">
              <div class="paragraph">
                <p>
                  Embrace Tomorrow with Love and Serenity – Reserve Your Shared
                  Space at Inspired Meadows Today.
                </p>
              </div>
            </div>
            <div class="get-info-col">
              <div class="get-info">
                <div class="get-head">
                  <div className="get-left">
                    <span>Get in touch</span>
                    <p>Please contact us for more information</p>
                  </div>
                  <Link to="/schedule-a-call">
                    Schedule a Call
                    <img src={angle_right} />
                  </Link>
                </div>
                <div class="get-contact">
                  <ul>
                    <li>
                      <div className="get-contact-link">
                        {" "}
                        <img src={email_icon} alt="Email icon" />
                        info@inspiredmeadows.co.uk
                      </div>
                    </li>
                    <li>
                      <div className="get-contact-link" >
                        {" "}
                        <img src={phoneIcon} alt="Phone image" /> 01392 576 302
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PetLearnMore;
