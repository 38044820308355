export const TOP_HEADER = [
	{
		id: "1",
      	name: "Home",
		link: "/",
		icon: "",
		icon_secondary: "",
		accessor: 'all',
		hideFrom: ''
	},
	{
		id: "2",
      	name: "About Us",
		link: "/about-us",
		icon: "",
		icon_secondary: "",
		accessor: 'all',
		hideFrom: ''
	},
	{
		id: "3",
      	name: "Natural Burials",
		link: "/natural-burials",
		icon: "",
		icon_secondary: "",
		accessor: 'all',
		hideFrom: ''
	},
	{
		id: "4",
      	name: "Products",
		link: "/products",
		icon: "",
		icon_secondary: "",
		accessor: 'all',
		hideFrom: ''
	},
	{
		id: "5",
      	name: "Locations",
		link: "/locations",
		icon: "",
		icon_secondary: "",
		accessor: 'all',
		hideFrom: ''
	},
	{
		id: "6",
      	name: "Latest News",
		link: "/blogs",
		icon: "",
		icon_secondary: "",
		accessor: 'all',
		hideFrom: ''
	},
	{
		id: "7",
      	name: "FAQs",
		link: "/faqs",
		icon: "",
		icon_secondary: "",
		accessor: 'all',
		hideFrom: ''
	},
	{
		id: "8",
      	name: "Contact Us",
		link: "/contact-us",
		icon: "",
		icon_secondary: "",
		accessor: 'all',
		hideFrom: ''
	}
];

export const RIGHT_HEADER = [
	{
		id: "1",
      	name: "Sign Up",
		link: "/login",
		icon: "",
		icon_secondary: "",
		accessor: 'all'
	}
];