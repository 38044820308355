import React from "react";
import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description, keywords , href }) => {
  // Create an array to hold all meta tags
  const metaTags = [
    { charSet: "utf-8" },
    { name: "description", content: description },
    { name: "keywords", content: keywords },
  ];

  // Filter out undefined meta tags
  const filteredMetaTags = metaTags.filter((meta) => meta.content);

  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      {filteredMetaTags.map((meta, index) => (
        <meta key={index} {...meta} />
      ))}
       <link rel="canonical" href={href}/>
    </Helmet>
  );
};

export default SEO;
