import React from 'react';
import loaderClasses from './Loader.module.css';


const Spinner = () => {
  return (
    <div class={loaderClasses.spinnerRolling}>
    <div class={loaderClasses.spinner}>
      <div></div>
    </div>
  </div>
  );
}

export default Spinner;
