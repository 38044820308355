import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import party_popper from "../../assets/images/party_popper.png";
import closeBtn from "../../assets/images/times_icon.png"
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const ResetComplete = (props) => {
    const { handleShowLogin, handleCloseResetPassComplete, showResetComplete } = props

    return (
        <Fragment>
            <div
                style={{ position: 'fixed' }}
                className={`overlay ${showResetComplete ? 'mail-active' : ''}`}
            />
            <div className={`login-main verify-mail-main ${showResetComplete ? 'mail-active' : ''}`}>
                <form>
                    <div className="logo">
                        <div className="logo-link" ><img src={logo} alt="Logo" /></div>
                        <span onClick={handleCloseResetPassComplete}><img src={closeBtn} alt="closeBtn" />
                        </span>
                    </div>
                    <div className="login-field">
                        <h2>Reset complete <img src={party_popper} alt="party popper image" /></h2>
                        <p>Your password has been reset. You can now use your new password to log in to your account</p>
                        <div className="login-btn">
                            <div className="return-link" onClick={handleShowLogin}>Return to login</div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(ResetComplete);