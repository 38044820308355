import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import MemorialList from "./MemorialList";

import agent from "../../agent";

import {
  saveMemorialFlora,
  setMemorialFloraError,
  setMemorialFloraIsLoading,
} from "../../reducers/slices/products";

const mapStateToProps = (state) => ({
  ...state.products.memorialFlora,
});
const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const MemorialFlora = (props) => {
  // const [selectedTypes, setSelectedTypes] = useState(["All"]);
  const { activeCategory, isLoading, error, data, isFetched, dispatch } = props;

  // const handleSelectTypes = async (event) => {
  //   const selectedType = event.target.value;
  //   setSelectedTypes(selectedType);
  //     if (event.target.checked) {
  //       setSelectedTypes((prevSelectedTypes) => [
  //         ...prevSelectedTypes,
  //         selectedType,
  //       ]);
  //     }
  // await fetchData(selectedType === "All" ? ["Flower", "Tree"] : selectedType);
  // };

  useEffect(() => {
    if (!isFetched) {
      fetchData(["Flower", "Tree"]); 
    }
  }, [dispatch, isFetched, activeCategory]);


  const fetchData = async (selectedType) => {
    try {
      dispatch(setMemorialFloraIsLoading({ isLoading: true }));
      const res = await agent.common.getMemorialFlora(selectedType);
      const { message, status } = res;
      if (!status) throw new Error(message);

      dispatch(saveMemorialFlora({ memorialFlora: res.data }));
    } catch (err) {
      if (err instanceof Error) {
        dispatch(setMemorialFloraError({ error: err.message }));
      }
    } finally {
      dispatch(setMemorialFloraIsLoading({ isLoading: false }));
    }
  };
  const flowerType = [{ type: "All", val: "All" }, { type: "Flower", val: "Flower" }];
  const treeType = [{ type: "All", val: "All" },  { type: "Tree", val: "Tree" }];

  const flowerData = data.filter((flower)=>flower.itemType === "Flower");
  const treeData = data.filter((flower)=>flower.itemType === "Tree");
  
  return (
    <Fragment>
      <div
        className={`burials-plots ${activeCategory === "memorialFlora" || "tree" ? "category-active" : ""
          }`}
      >
        <div className="product-head">
        <h2>{activeCategory === "memorialFlora"? "Flowers" : "Trees"}</h2>
          {/* <div className='product-type'>
          <span>Type:</span>
            {flowerType &&
              flowerType.map((type, idx) => (
                <div key={idx} className="product-field">
                    <input
                      id={type.type.toLowerCase().replaceAll(" ", "-")}
                      type="checkbox"
                      className="rounded-checkbox"
                      value={type?.val}
                      onChange={handleSelectTypes}
                      checked={ selectedTypes.includes(type?.type)}
                    />
                    <span class="checkmark"></span>
                  <label htmlFor={type.type.toLowerCase().replaceAll(" ", "-")}>
                    {type?.type}
                  </label>
                </div>
              ))}
          </div> */}
        </div>
        <div className="product-items">
          <div className="row">
            {activeCategory === "memorialFlora" ? 
              <MemorialList data={flowerData} error={error} isLoading={isLoading} />
            :
              <MemorialList data={treeData} error={error} isLoading={isLoading} />
            }
          
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemorialFlora);
