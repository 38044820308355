import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import AdviserBar from "./AdviserBar";
import CommitmentSection from "./CommitmentSection";
import CoreValues from "./CoreValues";
import OurMission from "./OurMission";
import AboutHeader from "./AboutHeader";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

const MainView = (props) => {
  return (
    <Fragment>
      <AboutHeader />
      <OurMission />
      <CoreValues />
      <CommitmentSection />
      <AdviserBar />
    </Fragment>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(MainView);
