import React, { Fragment, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { InlineWidget } from 'react-calendly';
import powered_logo from '../../assets/images/powered_logo.png';
import scheduleAgent from '../../assets/images/schedule-agent-img.png'
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});


const Calendly = (props) => {
    const { selectedUserUrl, handleBack } = props
    const selectedAgentData = useSelector((state) => state.header);
    const selectedAgentUrl = selectedAgentData?.selectedAgentData;
    const [agentVisibility, setAgentVisibility] = useState(false);

    const toggleBio = (event) => {
        event.stopPropagation();
        setAgentVisibility(!agentVisibility)

        console.log("click___")
        // setAgentVisibility((prevVisibility) => ({
        //     ...prevVisibility,
        //     [id]: !prevVisibility[id],
        //   }));
      };
      function textElips(text, words = 15) {
        if (!agentVisibility) {
          return text.length > words ? `${text.slice(0, words)}...` : text;
        } else {
          return text;
        }
      }
      console.log("agentVisibility" , selectedAgentUrl?.expendedBio?.length)
      console.log("agentVisibility" , selectedAgentUrl)

      const buttonClass = `toggle-button-detail${agentVisibility ? ' collapsed' : ''}`;

    return (
        <Fragment>
            <section id="Schedule-main">
                <div className="container">
                    <div className="schedule pb">
                        <div className="schedule-row">
                            <div className="Schedule-head-col">
                                <div className="Schedule-head">
                                    <h2>Schedule a call</h2>
                                    <div className="powered">
                                        <span>Powered by:</span>
                                        <img src={powered_logo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="schedule-row">
                            <div className="schedule-agent-col">
                                <div className="schedule-agent">
                                    <div className="schedule-agent-left">
                                        <div className="left-full">
                                            <div className="schedule-agent-profile">
                                                <img src={selectedAgentUrl.image} alt="" />
                                            </div>
                                            <div>
                                                <h3>{selectedAgentUrl?.firstName}</h3>
                                                <p className={`p ${agentVisibility ? ' collapsed' : ''}`}>
                                                     {/* {!agentVisibility
                                                        ? selectedAgentUrl?.expendedBio?.split(' ').slice(0, 103).join(' ')
                                                        : selectedAgentUrl?.expendedBio} */}
                                                        {textElips(selectedAgentUrl?.expendedBio , 203)}
                                                        {
                                                            selectedAgentUrl?.expendedBio?.length > 203 && (<> <button onClick={(e) => toggleBio(e)}className="toggle-button-detail">
                                                            {agentVisibility ? ' Read less' : 'Read more'}
                                                            </button></>) 
                                                        }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="schedule-agent-right">
                                        <h2>
                                            Select a Date & Time
                                        </h2>
                                        <div className={`calendly-details-main`} >
                                            <InlineWidget url={selectedUserUrl} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="back-schduleBtn-col">
                        <div className="back-schduleBtn">
                            <span  onClick={handleBack} >Back</span>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Calendly);