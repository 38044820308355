import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import MainView from './MainView';
import SEO from '../SEO/SEO';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const ContactUs = (props) => {
	let mainProps = {};
	return (
		<div className="natural-burials-page">
			<SEO
        title="Inspired Meadows | Contact Us"
        description="Contact Inspired Meadows for inquiries about funeral services, burial plots, and memorial arrangements."
        keywords="Inspired Meadows, contact us, funeral services, burial plots, memorial arrangements, contact information"
		href="https://www.inspiredmeadows.co.uk/contact-us"
      />
	  {/* <link rel="canonical" href="https://www.inspiredmeadows.co.uk/contact-us"/> */}
			<MainView {...mainProps} />
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);