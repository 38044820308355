import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import closeBtn from "../../assets/images/close_icon.png";
import Loader from "../components/Loader";
import agent from "../../agent";

const IntermentFormPay = ({setStatusApiResponse , intermentId ,  setIsModalOpen , isModalOpen, stripeKeys }) => {
  const [isOrderComplete, setIsOrderComplete] = useState(false);


  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [isPaymentElementLoading, setIsPaymentElementLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsPaymentElementLoading(false);
    }, 3000); // You can adjust the timeout as per your needs or remove it if not needed.
    return () => clearTimeout(timeout);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // if (!stripe || !elements) return;

    const { error: submitError } = await elements.submit();
    if (submitError) {
      toast.error(submitError.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (stripeKeys?.paymentIntent) {
      try {
        setLoading(true);
        const res = await stripe.confirmPayment({
          elements,
          clientSecret: stripeKeys?.paymentIntent,
          confirmParams: {
            return_url: "http://example.com",
          },
          redirect: "if_required",
        });
        if (res?.paymentIntent && res?.paymentIntent.status === "succeeded") {
          try{
            let statusResponse = await agent.common.updateStatus(intermentId , "paymentSuccess")
          
            if(statusResponse?.status == true){
              // window.open(statusResponse?.calendlyLink, '_blank')
              // window.location.href = statusResponse?.calendlyLink
              setStatusApiResponse(statusResponse)
              setIsModalOpen(false)
            }
           

          }catch(error){

           

          }


          setLoading(false);
          setIsOrderComplete(true);
        }else{
         
        }
      } catch (error) {
        setLoading(false);
       
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // CustomToast(error.message, "error");
      }
    }
  };

  const navigate = useNavigate();

  

  const closeModal = () => {
    setIsModalOpen(false)
  };

  return (
    <>
         <div
            // onClick={closeModal}
            style={{ position: "fixed" }}
            className={`overlay ${isModalOpen ? "cart-show" : ""}`}
          />
  
          <div
            className={`add-Card checkout-card ${
              isModalOpen ? "card-active" : ""
            }`}
          >
            <h2>Checkout</h2>
            <span>
              <img src={closeBtn} alt="" onClick={closeModal} />
            </span>
  
            <form onSubmit={handleSubmit}>
              <PaymentElement options={{ layout: 'tabs' }} />
              <div className="paymentButton">
                <button
                  type="submit"
                  className="pay-btn"
                  disabled={!stripe || !elements}
                >
                  {loading && <Loader />}  Pay
                </button>
              </div>
            </form>
          </div>
        
   
      <ToastContainer />
    </>
  );
  
};

export default IntermentFormPay;
