// headerSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";
const headerSlice = createSlice({
  name: "header",
  initialState: {
    data: [],
    email:"",
    password:"",
    statusCode:"",
    rememberMe:false,
    showLogin: false,
    showRegister: false,
    showForgot: false,
    showForgotEmail: false,
    showVerifyOtp: false,
    showResetPass: false,
    showResetComplete: false,
    showProfileDrop: false,
    showLogout: false,
    showPassowrd:false,
    showRegisterModal:false,
    showForgotPasswordModal:false,
    loginErr:null,
    forgotEmail:null,
    isLoggedIn: initialIsLoggedIn,
    selectedAgentData:null,
    isOtpVisible:false,
    registrationData: null,
    otpSuccessNew:false,
    selectedPdf:'',
  },
  reducers: {
    setCartData: (state, action) => {
      state.data = action.payload;
    },
    setStatusCode: (state, action) => {
      state.statusCode = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload;
    },
    setShowRegister: (state, action) => {
      state.showRegister = action.payload;
    },
    setResetPass: (state, action) => {
        state.showResetPass = action.payload;
    },
    setVerifyOtp: (state, action) => {
        state.showVerifyOtp = action.payload;
    },
    setShowForgot: (state, action) => {
        state.showForgot = action.payload;
    },
    setResetComplete: (state, action) => {
        state.showResetComplete = action.payload;
    },
    setShowProfileDrop: (state, action) => {
        state.showProfileDrop = action.payload;
    },
    setShowLogout: (state, action) => {
      state.showLogout = action.payload;
    },
      setShowPassword: (state, action) => {
        state.showPassowrd = action.payload;
    },
    setShowRegisterModal: (state, action) => {
      state.showRegisterModal = action.payload;
    },
    setShowForgotPasswordModal: (state, action) => {
      state.showForgotPasswordModal = action.payload;
    },
    setForgotEmail: (state, action) => {
      state.forgotEmail = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setLoginErr: (state, action) => {
      state.loginErr = action.payload;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },
    setSelectedAgentData: (state, action) => {
      state.selectedAgentData = action.payload;
    },
    setIsOtpVisible:(state,action)=>{
      state.isOtpVisible = action.payload;
    },
    setRegistrationData: (state, action) => {
      state.registrationData = action.payload;
    },
    setOtpSuccessNew: (state, action) => {
      state.otpSuccessNew = action.payload;
    },
    setSelectedPdf: (state, action) => {

      state.selectedPdf = action.payload;
    },
   
  },
});

export const {
        setCartData,
        setEmail,
        setPassword,
        setShowLogin,
        setShowRegister,
        setShowForgot,
        setVerifyOtp,
        setResetPass,
        setShowResetComplete,
        setShowProfileDrop,
        setShowLogout,
        setResetComplete,
        setShowPassword,
        setShowRegisterModal,
        setForgotEmail,
        setShowForgotPasswordModal,
        setIsLoggedIn,
        setLoginErr,
        setStatusCode,
        setRememberMe,
        setIsOtpVisible,
        setSelectedAgentData,
        setRegistrationData,
        setOtpSuccessNew,
        setSelectedPdf
        
  // Export other actions as needed
} = headerSlice.actions;

export default headerSlice.reducer;
