import React, { useState, useEffect, Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";

import {
  validate as FormValidate,
  ListErrorMessages,
} from "../../constants/Validations";
import { REGISTER, LOGIN_RESET } from "../../constants/actionTypes";
import closeBtn from "../../assets/images/times_icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setShowPassword,
  setIsLoggedIn,
  setIsOtpVisible,
  setRegistrationData,
  setShowRegister,
  setShowLogin,
  setResetPass,
  setShowForgot,
  setVerifyOtp,
  setResetComplete,
} from "../../reducers/slices/headerSlice";

import agent from "../../agent";
import Loader from "../components/Loader";
import { makeStyles } from "@material-ui/styles";

import TextField from "@mui/material/TextField";
import CustomInput from "../Common/CustomInput";

const useStyles = makeStyles({
  root: {
    // input label when focused
    "& label.Mui-focused": {
      color: "#219653",
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#219653",
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#219653",
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#219653",
      },
    },
  },
});

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  loginSuccess: state.common.loginSuccess,
  loginError: state.common.loginError,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: async (values) => {
    try {
      const response = await agent.Auth.signUpVerify(values);
      // toast.success("Email verified successfully.", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      return {
        statusCode: response.status,
      };
    } catch (error) {
      if (error.response && error.response.statusCode === 400) {
        const responseJson = JSON.parse(error.response.text);
        const errorMessage = responseJson.message;
        dispatch({
          type: REGISTER,
          payload: JSON.parse(error.response.text),
        });
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        // Handle other errors if needed
      }
    }
  },
});

const Register = (props) => {
  const {
    showRegister,
    handleShowLogin,
    handleCloseRegister,
    loginError,
    currentUser,
    onSubmit,
  } = props;
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dialCode, setDialCode] = useState("+44");
  const [inProgress, setInProgress] = useState(false);
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [click, setClick] = useState(false);
  const dispatch = useDispatch();
  const [passwordStrength, setPasswordStrength] = useState("Weak");
  const [checkPassword, setCheckPassword] = useState(true);

  const { showPassowrd } = useSelector((state) => state.header);
  const classes = useStyles();

  function crossHandler() {
    setName("");
    setEmail("");
    setPhone("");
    setPassword("");
    handleCloseRegister();
  }

  useEffect(() => {
    passwordValidate(password);
  }, [password]);

  const passwordValidate = (passwordValue) => {
   
    
    if (passwordValue.length < 8) {
      
      setPasswordStrength("Weak");
    } else if (!/[a-zA-Z]/.test(passwordValue) || !/\d/.test(passwordValue)) {
      setPasswordStrength("Must add alphabet");
    } else if (!/\d/.test(passwordValue)) {
      setPasswordStrength("Must add numbers");
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(passwordValue)) {
      setPasswordStrength("Medium");
    } else {
      setPasswordStrength("Strong");
      setCheckPassword(true);
    }
  };

  const submitBtn = async (e) => {
    e.preventDefault();
    if (isChecked) {
      passwordValidate(password);
      if (passwordStrength !== "Strong") {
        setCheckPassword(false);
        
      }
      let checkValidate = FormValidate({
        email: { value: email, email: true, required: true },
        phone: { value: phone, required: true },
        name: { value: name, required: true },
        lastName: { value: lastName, required: true },
        password: {
          value: password,
          required: true,
          hasSpecialCharacter: true,
          hasUpperCase: true,
          hasLowerCase: true,
          minlength: 8,
        },
      });
      if (!checkValidate.status) {
        try {
          
          if (passwordStrength !== "Strong") {
            setCheckPassword(false);
            return;
          }
          setCheckPassword(true);
          await submitHandle();
        } catch (error) {
          setInProgress(false);
          setClick(true);
        }
      } else {
        let values = ListErrorMessages(checkValidate.errors);
        setErrors(values);
        setInProgress(false);
        setClick(true);
        setIsOtpVisible(false);
        toast.error(values, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      setClick(true);
    }
  };

  const handleShowVerify = () => {
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(true));
    dispatch(setResetComplete(false));
  };

  useEffect(() => {
    if (currentUser) {
      handleCloseRegister();
      if (currentUser) {
        setName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setPassword("");
        setIsChecked(false);
      }
    }
  }, [currentUser]);

  const submitHandle = async () => {
    setInProgress(true);
    const [phoneno] = phone.split(" ");
    let verifyData = {
      dialCode: dialCode,
      email: email,
      phoneNumber: phoneno,
    };
    const sendData = {
      email: email,
      firstName: name,
      lastName: lastName,
      password: password,
      phoneNumber: phonNo,
    };
    dispatch(setRegistrationData(sendData));
    try {
      const submitResult = await onSubmit(verifyData);
      if (submitResult) {
        dispatch(setIsOtpVisible(true));
        handleShowVerify();
        setInProgress(false);
      }
    } catch (error) {
      setInProgress(false);
      setClick(true);
    }
  };

  const [phoneno] = phone.split(" ");
  const phonNo = {
    dialCode: dialCode,
    number: phoneno,
  };
  const [temporaryLoginErr, setTemporaryLoginErr] = useState(null);

  useEffect(() => {
    if (loginError) {
      if (typeof loginError === "string") {
        setTemporaryLoginErr(loginError);
        setInProgress(false);
        dispatch({
          type: LOGIN_RESET,
        });
        setTimeout(function () {
          setTemporaryLoginErr("");
        }, 4000);
      }
      // Reset form fields and errors if loginError is not a string (other error types)
      else {
        setName("");
        setEmail("");
        setLastName("");
        setPhone("");
        setPassword("");
        setErrors({});
        setInProgress(false);
        setClick(false);
      }
    }
  }, [loginError]);

  function onchangeHandler(e) {
    setName(e.target.value);
  }
  function lastNameHandler(e) {
    setLastName(e.target.value);
  }
  function onEmailChangeHandler(e) {
    setEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword.length < 8) {
      setPasswordStrength("Weak");
    } else if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
      setPasswordStrength("Must add alphabet");
    } else if (!/\d/.test(password)) {
      setPasswordStrength("Must add numbers");
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setPasswordStrength("Medium");
      
    } else {
      setCheckPassword(true);
      setPasswordStrength("Strong");
    }
  };

  return (
    <Fragment>
      <div
        style={{ position: "fixed" }}
        className={`overlay ${showRegister ? "register-active" : ""}`}
      />

      <div
        className={`login-main register-main ${
          showRegister ? "register-active" : ""
        }`}
      >
        <div className="logo">
          <div className="logo-link">
            <img src={logo} alt="Logo" />
          </div>
          <span>
            <img src={closeBtn} alt="Logo" onClick={crossHandler} />
          </span>
        </div>
        {inProgress && <Loader />}
        <form>
          <div className="login-field">
            <h2>Register</h2>
            <p>Enter your details to create account</p>
            {/* {temporaryLoginErr ? <span className="text-danger">{temporaryLoginErr}</span> : <Fragment />} */}
            <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
              <div className="input-field">
                <CustomInput
                  type={"text"}
                  value={name}
                  label={"First name"}
                  handleChange={onchangeHandler}
                />

                <span className="text-danger">
                  {errors && errors.name ? errors.name : ""}
                </span>
              </div>
              <div className="input-field">
                <CustomInput
                  type={"text"}
                  value={lastName}
                  label={"Last name"}
                  handleChange={lastNameHandler}
                />

                <span className="text-danger">
                  {errors && errors.lastName ? errors.lastName : ""}
                </span>
              </div>
            </div>

            <div className="input-field">
              <CustomInput
                type={"email"}
                value={email}
                label={"Your email address"}
                handleChange={onEmailChangeHandler}
              />

              <span className="text-danger">
                {errors && errors.email ? errors.email : ""}
              </span>
            </div>
            <div className="input-field">
              <div style={{ display: "flex", flex: 1 }}>
                <input
                  style={{ width: 70 }}
                  className="dial-code-input"
                  type="text"
                  value={dialCode}
                />
                <TextField
                  className={`${classes.root} phone-input`}
                  id="outlined-number"
                  label="Phone number"
                  type="number"
                  value={phone}
                  fullWidth
                  maxLength={10}
                  onChange={(e) => {
                    setPhone(e.target.value.replace("+44", ""));
                  }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </div>
              <span className="text-danger">
                {errors && errors.phone ? errors.phone : ""}
              </span>
            </div>
            <div className="input-field">
              <TextField
                className={classes.root}
                id="outlined-password-input"
                fullWidth
                label="Password"
                value={password}
                type={showPassowrd ? "text" : "password"}
                onChange={handlePasswordChange}
                autoComplete="current-password"
              />

              <i
                onClick={(e) => dispatch(setShowPassword(!showPassowrd))}
                className={`fa-eye-login fa-eye${showPassowrd ? "" : "-slash"}`}
                aria-hidden="true"
              ></i>
              <div style={{ marginTop: "5px" }}>
                <div style={{ color: ` ${checkPassword ? "green" : "red"}` }}>
                  {/* <span>Password is too short</span> */}
                  <span style={{ fontSize: 12 }}>
                    Must contain at least 8 characters of upper & lower case
                    letters, numbers, and symbols.
                  </span>
                </div>
                {/* <span style={{ color: '#ffd33d', }}>  {passwordStrength === 'Must add alphabet' && password.length > 0 && 'Password needs a number and lowercase letter'} </span> */}
                {/* <span style={{ color: 'gray',}}>  {passwordStrength === 'Must add numbers' && 'Must add numbers'} </span> */}
                {/* <span style={{ color: '#ffd33d', }}> {passwordStrength === 'Medium' && 'Medium! Password needs a special character'}  </span> */}
                {/* <span style={{ color: 'green', }}> {passwordStrength === 'Strong' && 'Password is strong'} </span> */}
              </div>
              <span className="text-danger">
                {/* {errors && errors.password ? errors.password : ""} */}
              </span>
            </div>
            <div className="input-field">
              <div className="input-check">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => {
                  setIsChecked(e.target.checked)
                  }}
                  required
                />
                <label className="register-term">
                  I agree to all{" "}
                  <Link to="/terms&condition">Terms & Conditions</Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </label>
                {click && !isChecked && (
                  <span className="text-danger">
                    This checkbox is required.
                  </span>
                )}
              </div>
            </div>
            <div className="login-btn">
              <a
                className="login-button btn btn-md btn-primary custom-button w-100 mb-3 orange-btn-border"
                type="button"
                disabled={!isChecked || inProgress}
                onClick={submitBtn}
              >
                Register
              </a>
            </div>
            <div className="log-footer">
              <p>
                Already a member yet?
                <Link
                  className="register-login"
                  to="#"
                  onClick={handleShowLogin}
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
