import { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import agent from '../../agent';

import {
  setIsAddToCartSuccessModalOpen,
  setIsMemorialDetailModalOpen,
  setMemorialDetail,
} from '../../reducers/slices/products';

import Loader from '../components/Loader';
import Login from '../Auth/Login';
import Register from '../Auth/Register';
import ProfileViewer from '../User/ProfileViewer';
import ForgotPassword from '../Auth/ForgotPassword';
import VerifyOtp from '../Auth/VerifyOtp';
import ResetComplete from '../Auth/ResetComplete';
import ResetPassword from '../Auth/ResetPassword';
import { useNavigate } from 'react-router-dom';
import {
  setEmail,
  setPassword,
  setShowLogin,
  setShowRegister,
  setShowForgot,
  setShowLogout,
  setShowPassword,
  setForgotEmail,
  setLoginErr,
  setVerifyOtp,
  setResetPass,
  setResetComplete,
  setShowProfileDrop
} from "../../reducers/slices/headerSlice";
import { LOGOUT } from "../../constants/actionTypes";
import 'react-toastify/dist/ReactToastify.css';
import placeholder from '../../assets/images/Default_Load.png'

const mapStateToProps = state => ({
  isModalOpen: state.products.isMemorialDetailModalOpen,
  userId: state.common?.currentUser?.id || '',
  id: state.products.memorialDetail.id,
  images: state.products.memorialDetail.images,
  title: state.products.memorialDetail.title,
  description: state.products.memorialDetail.description,
  price: state.products.memorialDetail.price,
  itemType: state.products.memorialDetail.itemType,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    dispatch(setIsMemorialDetailModalOpen({ isOpen: false }));
    dispatch(
      setMemorialDetail({
        id: '',
        itemType: '',
        title: '',
        description: '',
        images: [],
        price: '',
        createdAt: '',
        updatedAt: '',
      })
    );
  },
  onSignOut: () => {
    dispatch({ type: LOGOUT });
  },
  openAddToCartSuccessModal: () =>
    dispatch(setIsAddToCartSuccessModalOpen({ isOpen: true })),

});

const MemorialDetailModal = props => {
  const {
    isModalOpen,
    closeModal,
    openAddToCartSuccessModal,
    userId,
    id,
    images,
    title,
    description,
    price,
    itemType,
    currentUser,
  } = props;

  const [activeImg, setActiveImg] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    email,
    password,
    showLogin,
    showRegister,
    showForgot,
    showPassowrd,
    loginErr,
    showProfileDrop,
    showVerifyOtp,
    showResetPass,
    forgotEmail,
    showResetComplete,
    showLogout
  } = useSelector((state) => state.header);

  const handleCloseLogin = () => {
    dispatch(setShowLogin(false));
    dispatch(setEmail(''));
    dispatch(setPassword(''));
  }

  useEffect(() => {
    if (isModalOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [isModalOpen]);

  useEffect(() => {
    if (images && images.length > 0) setActiveImg(images[0]);
    else setActiveImg('');
  }, [images]);

  const incrementQuantity = () => {
    setQuantity(prevCount => prevCount + 1);
  };
  const decrementQuantity = () => {
    setQuantity(prevCount => {
      return prevCount > 1 ? prevCount - 1 : prevCount;
    });
  };

  const handleShowLogin = () => {
   
    dispatch(setShowLogin(true));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(false))
    dispatch(setResetComplete(false));
  }

  const handleCloseRegister = () => dispatch(setShowRegister(false));

  const handleCloseResetPass = () => dispatch(setResetPass(false));
  const handleShowResetPass = () => {
  
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(true));
    dispatch(setResetComplete(false));
  }
  const handleCloseResetPassComplete = () => dispatch(setResetComplete(false))
  const handleShowResetPassComplete = () => {
  
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(false));
  }
  const handleShowRegister = () => {
   
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(true));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(false));
    dispatch(setResetComplete(false));
  }

  const handleCloseForgot = () => dispatch(setShowForgot(false));
  const handleShowForgot = () => {
   
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(true));
    dispatch(setVerifyOtp(false));
    dispatch(setResetComplete(false));
  }
  const handleCloseVerify = () => dispatch(setVerifyOtp(false));
  const handleShowVerify = () => {
    
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(true));
    dispatch(setResetPass(false));
    dispatch(setResetComplete(false));
  }
  const handleShowMyProfile = () => {
    dispatch(setShowLogout(false));
    setShowProfileDrop(false);
   
    navigate('/user/my-profile');
  }
  const submitLogout = () => dispatch(setShowLogout(true));

  const addMemorialToCart = async () => {
    if (currentUser == null) {
      dispatch(setShowLogin(true));
      closeModal();
    } else {
      try {
        const data = {
          productId: id,
          productType: itemType,
          quantity: quantity,
          userId: userId,
        };

        setIsLoading(true);
        const res = await agent.common.addToCart(data);
        const { status, message } = res;
        if (!status) throw new Error(message);
        openAddToCartSuccessModal();
        closeModal();
      } catch (err) {
     
        if (err instanceof Error) {
          console.error(err.message);
        } else {
          console.error(err);
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  let loginProps = {
    showLogin, handleCloseLogin, handleShowLogin, handleShowRegister, handleShowForgot, email, setEmail, password, setPassword, showPassowrd, setShowPassword, loginErr, setLoginErr
  }

  let registerProps = {
    showRegister, handleCloseRegister, handleShowLogin
  }

  let forgotProps = {
    showForgot, handleCloseForgot, handleShowLogin, setForgotEmail, handleShowVerify
  }

  let verifyProps = {
    handleCloseVerify, handleShowLogin, forgotEmail, showVerifyOtp, handleShowResetPass
  }
  let resetPassProps = {
    showResetPass, handleShowLogin, forgotEmail, handleCloseResetPass, handleShowResetPassComplete
  }

  let resetCompleteProps = {
    handleShowLogin, handleCloseResetPassComplete, showResetComplete
  }
  let profileProps = {
    currentUser, submitLogout, showProfileDrop, handleShowMyProfile
  }
  return (
    <Fragment>
      <div
        onClick={closeModal}
        style={{ position: 'fixed' }}
        className={`overlay ${isModalOpen ? 'cart-show' : ''}`}
      />
      <div
        style={{ height: 'auto' }}
        className={`burials-add-to-cart ${isModalOpen ? 'cart-show' : ''}`}
      >
        {isLoading && <Loader />}
        <div className='burials-img'>
          <img src={activeImg || placeholder} alt='' />
          <ul
            style={{
              display: 'flex',
              gap: 9,
              marginTop: 10,
              flexWrap: 'wrap',
            }}
          >
            {images &&
              images.map((image, idx) => (
                <li
                  key={idx}
                  style={{
                    width: 50,
                    border: activeImg === image ? '2px solid green' : '',
                  }}
                  onClick={() => setActiveImg(image)}
                >
                  <img
                    src={image}
                    style={{
                      width: '100%',
                      aspectRatio: '1/1',
                      objectFit: 'cover',
                    }}
                    alt=''
                  />
                </li>
              ))}
          </ul>
        </div>
        <div className='burials-plot-detail'>
          <h2>{title}</h2>
          <p style={{ fontSize: 14, fontWeight: 500 }}>{itemType}</p>

          <span className='price'>£{Number(price).toFixed(2)} (Includes shipping & planting)</span>

          <p>{description}</p>

          <div style={{ marginTop: 20 }}>
            <span
              style={{
                color: '#333333',
                fontSize: 12,
                fontWeight: 500,
                lineHeight: '14.52px',
              }}
            >
              Quantity:
            </span>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <button
                onClick={decrementQuantity}
                style={{
                  width: 40,
                  height: 40,
                  border: '1px solid #E0E0E0',
                  borderRight: 0,
                  borderRadius: 0,
                  color: '#828282',
                  fontSize: 20,
                  margin: 0,
                  cursor: 'pointer',
                }}
              >
                -
              </button>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 40,
                  height: 40,
                  border: '1px solid #E0E0E0',
                  borderRadius: 0,
                  color: '#4F4F4F',
                }}
              >
                {quantity}
              </span>
              <button
                onClick={incrementQuantity}
                style={{
                  width: 40,
                  height: 40,
                  border: '1px solid #E0E0E0',
                  borderLeft: 0,
                  borderRadius: 0,
                  color: '#828282',
                  fontSize: 20,
                  margin: 0,
                  cursor: 'pointer',
                }}
              >
                +
              </button>
            </div>
          </div>

          <button
            style={{ marginTop: 20 }}
            className='cart-btn'
            onClick={addMemorialToCart}
          >
            Add to Cart
          </button>

          {isLoggedIn && (
            [loginProps, registerProps, forgotProps, profileProps].map((props, index) => (
              <Fragment key={index}>
                {props.showLogin && <Login {...props} />}
                {props.showRegister && <Register {...props} />}
                {props.showForgot && <ForgotPassword {...props} />}
                {props.showVerify && <VerifyOtp {...props} />}
                {props.showResetPass && <ResetPassword {...props} />}
                {props.showResetComplete && <ResetComplete {...props} />}
                {props.showProfile && <ProfileViewer {...props} />}
              </Fragment>
            ))
          )}


          <div className='burials-remove-btn' onClick={closeModal}>
            <span>
              <i className='fa fa-times-circle' aria-hidden='true'></i>
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemorialDetailModal);
