import React, { Fragment,  useState,  Suspense } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import bannerimg from "../../assets/images/Natural_bg_img.png";
import Natural_first from "../../assets/images/Natural_first.png";
import Natural_second from "../../assets/images/Natural_second.png";
import natural_three from "../../assets/images/natural_three.png";
import natural_four from "../../assets/images/natural_four.png";
import faqtwo from "../../assets/images/faq-two.png";
import faqthird from "../../assets/images/faq-third.png";
import keyfirst from "../../assets/images/key-first.png";
import keySecond from "../../assets/images/key-second.png";
import keyThird from "../../assets/images/key-three.png";
import keyFourth from "../../assets/images/key-four.png";
import keyFive from "../../assets/images/key-five.png";
import keySix from "../../assets/images/key-six.png";
import NaturalCustom from "../../assets/images/Natural_custom.png";
import NaturalCustom2 from "../../assets/images/natural burial.png";
import NaturalCustom3 from "../../assets/images/Natural_custom3.png";
import NaturalCustom4 from "../../assets/images/Natural_custom4.png";
import msgbox from "../../assets/images/msg-box.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import ReadMorePopup from "./ReadMorePopup";
import ImageLoading from "../Common/skeleton/ImageLoading";



const LazyImage = React.lazy(() => import("../Common/Optemize/LazyImage")); 

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

const MainView = (props) => {
  const initialActiveIndex = 0;
  const [activeItem, setActiveItem] = useState(initialActiveIndex);
  const [activeReadMore, setActiveReadMore] = useState(false);
  const [activeValue, setActiveValue] = useState("");

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 575,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingstwo = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
        },
      },

      {
        breakpoint: 575,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const settingsthree = {
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 575,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleClickReadMore = (value) => {
    setActiveReadMore(!activeReadMore);
    setActiveValue(value);
  };
  const onCloseReadMore = () => {
    setActiveReadMore(false);
  };

  return (
    <Fragment>
      <section id="banner-img-main">
        <div className="custom-banner-img">
        <Suspense fallback={<ImageLoading/>}>
                <LazyImage src={bannerimg} alt="overview image"/>
                </Suspense>
          {/* <img src={bannerimg} alt="banner-img" loading="lazy" /> */}
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>Natural Burials at Inspired Meadows</h1>
          </div>
        </div>
      </section>

      <section id="Burials-main">
        <div className="container">
          <div className="row">
            <div className="Burials-head-col">
              <div className="Burials-head">
                <h2> Natural Burials and their Benefits</h2>
                <p className="p-spacing">
                  Natural burials offer a unique and meaningful approach to
                  honouring the life of a loved one while embracing the beauty
                  and tranquility of nature. With a focus on sustainability and
                  environmental preservation, natural burials provide numerous
                  benefits for both individuals and the planet.
                </p>
                <p>
                  At Inspired Meadows, we believe in the power of natural
                  burials to create a lasting connection between life and the
                  environment. Here are some key points explaining natural
                  burials and their benefits:
                </p>
              </div>
            </div>
            <div className="burials-desk">
              <div className="burials-col">
                <div className="burials">
                  <img src={Natural_first} alt="" />
                  <span>Return to Nature</span>
                  <p>
                    Natural burials allow individuals to return to the earth in
                    a way that supports the natural cycle of life. By choosing a
                    natural burial, loved ones can find solace in knowing that
                    their final resting place will nourish and contribute to the
                    ecosystem.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={Natural_second} alt="" />
                  <span>Environmental Conservation</span>
                  <p>
                    Traditional burial practices often involve embalming fluids
                    and non-biodegradable materials that can harm the
                    environment. In contrast, natural burials prioritise
                    eco-friendly practices, using biodegradable materials and
                    promoting the preservation of natural habitats.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={natural_three} alt="" />
                  <span>Preservation of Open Spaces</span>
                  <p>
                    Natural burial grounds are carefully selected and designed
                    to protect and enhance open spaces, creating serene and
                    peaceful settings for reflection and remembrance. These
                    green spaces provide valuable habitat for wildlife and
                    contribute to the overall preservation of natural
                    landscapes.
                  </p>
                </div>
              </div>
              <div className="burials-half-col">
                <div className="burials-half">
                  <img src={natural_four} alt="" />
                  <span>Personalised  Memorials</span>
                  <p>
                    Natural burials offer a range of options for personalised 
                    memorials. Families can choose from various commemorative
                    elements, such as planting trees, dedicating benches, or
                    creating personalised  markers, allowing them to honor their
                    loved one in a way that resonates with their unique
                    personality and values.
                  </p>
                </div>
              </div>
              <div className="burials-half-col">
                <div className="burials-half">
                  <img src={natural_four} alt="" />
                  <span>Emotional Healing</span>
                  <p>
                    Being in nature has been shown to have a positive impact on
                    mental and emotional well-being. Natural burial grounds
                    provide a peaceful and comforting environment for families
                    to find solace, heal, and connect with their loved ones in a
                    serene natural setting.
                  </p>
                </div>
              </div>
            </div>

            <div className="burials-mobile">
              <div className="row">
                <div className="burials-slider">
                  <div className="slide">
                    <Slider {...settings}>
                      <div>
                        <div className="burials-col">
                          <div className="burials">
                            <img src={Natural_first} alt="" />
                            <span>Return to Nature</span>
                            <p>
                              Natural burials allow individuals to return to the
                              earth in a way that supports the natural cycle of
                              life. By choosing a natural burial, loved ones can
                              find solace in knowing that their final resting
                              place will nourish and contribute to the
                              ecosystem.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="burials-col">
                          <div className="burials">
                            <img src={Natural_second} alt="" />
                            <span>Environmental Conservation</span>
                            <p>
                              Traditional burial practices often involve
                              embalming fluids and non-biodegradable materials
                              that can harm the environment. In contrast,
                              natural burials prioritize eco-friendly practices,
                              using biodegradable materials and promoting the
                              preservation of natural habitats.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="burials-col">
                          <div className="burials">
                            <img src={natural_three} alt="" />
                            <span>Preservation of Open Spaces</span>
                            <p>
                              Natural burial grounds are carefully selected and
                              designed to protect and enhance open spaces,
                              creating serene and peaceful settings for
                              reflection and remembrance. These green spaces
                              provide valuable habitat for wildlife and
                              contribute to the overall preservation of natural
                              landscapes.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="burials-half-col">
                          <div className="burials-half">
                            <img src={natural_four} alt="" />
                            <span>Personalized Memorials</span>
                            <p>
                              Natural burials offer a range of options for
                              personalized memorials. Families can choose from
                              various commemorative elements, such as planting
                              trees, dedicating benches, or creating
                              personalized markers, allowing them to honor their
                              loved one in a way that resonates with their
                              unique personality and values.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="burials-half-col">
                          <div className="burials-half">
                            <img src={natural_four} alt="" />
                            <span>Personalized Memorials</span>
                            <p>
                              Natural burials offer a range of options for
                              personalized memorials. Families can choose from
                              various commemorative elements, such as planting
                              trees, dedicating benches, or creating
                              personalized markers, allowing them to honor their
                              loved one in a way that resonates with their
                              unique personality and values.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <div className="natural-footer-col">
              <div className="natural-footer">
                <p>
                  By choosing a natural burial, individuals not only contribute
                  to the preservation of the environment but also create a
                  meaningful and sustainable legacy for future generations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Burials-process-main">
        <div className="container">
          <div className="row">
            <div className="process-head-col">
              <div className="process-head">
                <h2>Natural Burials Process and Customisation Features</h2>
                <p>
                  At Inspired Meadows, we understand that every individual's
                  end-of-life journey is unique. That's why we offer a
                  comprehensive range of options and customisation features to
                  ensure a personalised and meaningful natural burial
                  experience. Here's a detailed overview of the process and the
                  various options available:
                </p>
              </div>
            </div>
            <div className="natural-burial-slide">
              <Slider {...settingsthree}>
                <div className="process-col">
                  <div className="process">
                    <img src={NaturalCustom3} alt="" />
                    <div className="process-content">
                      <span>Environmental Considerations</span>
                      <p>
                        Inspired Meadows is committed to sustainability and
                        environmental stewardship. We prioritise eco-friendly
                        practices by using biodegradable materials and
                        supporting the preservation of natural habitats within
                        our burial grounds. This allows individuals to leave a
                        positive impact on the environment even after they have
                        passed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-col">
                  <div className="process">
                    <img src={faqtwo} alt="" />
                    <div className="process-content">
                      <span>Burial Options</span>
                      <p>
                        We offer a range of burial options to suit individual
                        preferences and cultural traditions. Clients can choose
                        between the option of natural burial in a biodegradable
                        Urn, Casket, or Shroud.
                      </p>
                    </div>
                    <div
                      className="text-content"
                      onClick={() => handleClickReadMore("burial")}
                    >
                      <div className="btn">Read More</div>
                    </div>
                  </div>
                </div>
                <div className="process-col">
                  <div className="process">
                    <img src={faqthird} alt="" />
                    <div className="process-content">
                      <span>Customisation </span>
                      <p>
                        We believe in honouring the unique life and personality
                        of each individual. Clients have the opportunity to
                        customise their burial plot by incorporating
                        personalised elements such as commemorative plaques, or
                        the planting of trees or flowers in memory of their
                        loved one.
                      </p>
                    </div>
                    <div
                      className="text-content1"
                      onClick={() => handleClickReadMore("customization")}
                    >
                      <div className="btn">Read More</div>
                    </div>
                  </div>
                </div>
                <div className="process-col">
                  <div className="process">
                    <img src={NaturalCustom2} alt="" />
                    <div className="process-content">
                      <span>Ceremonies and Remembrance</span>
                      <p>
                        We understand the importance of remembrance and
                        ceremonies in the grieving process. Our team is
                        dedicated to working closely with families to
                        accommodate their cultural and spiritual practices,
                        ensuring a meaningful and respectful farewell for their
                        loved one.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-col">
                  <div className="process">
                    <img src={NaturalCustom4} alt="" />
                    <div className="process-content">
                      <span>Guidance and Support</span>
                      <p>
                        Our experienced team is here to guide and support you
                        throughout the entire process. We provide detailed
                        information, answer any questions you may have, and
                        ensure that every aspect of the natural burial
                        experience is tailored to your needs and wishes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-col-lst">
                  <div className="process-lst">
                    <img src={faqthird} alt="" />
                    <div className=".process-content">
                      <span>Customization</span>
                      <p>
                        We believe in honoring the unique life and personality
                        of each individual. Clients have the opportunity to
                        customize their burial plot by incorporating
                        personalized elements such as commemorative plaques, or
                        the planting of trees or flowers in memory of their
                        loved one.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="slide-mobile">
              <Slider {...settingstwo}>
                <div className="process-col">
                  <div className="process">
                    <img src={NaturalCustom3} alt="" />
                    <div className="process-content">
                      <span>Environmental Considerations</span>
                      <p>
                        Inspired Meadows is committed to sustainability and
                        environmental stewardship. We prioritize eco-friendly
                        practices by using biodegradable materials and
                        supporting the preservation of natural habitats within
                        our burial grounds. This allows individuals to leave a
                        positive impact on the environment even after they have
                        passed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-col">
                  <div className="process">
                    <img src={faqtwo} alt="" />
                    <div className="process-content">
                      <span>Burial Options</span>
                      <p>
                        We offer a range of burial options to suit individual
                        preferences and cultural traditions. Clients can choose
                        between the option of natural burial in a biodegradable
                        Urn, Casket, or Shroud.
                      </p>
                    </div>
                    <div
                      className="text-content"
                      onClick={() => handleClickReadMore("burial")}
                    >
                      <div className="btn">Read More</div>
                    </div>
                  </div>
                </div>
                <div className="process-col">
                  <div className="process">
                    <img src={faqthird} alt="" />
                    <div className="process-content">
                      <span>Customization</span>
                      <p>
                        We believe in honoring the unique life and personality
                        of each individual. Clients have the opportunity to
                        customize their burial plot by incorporating
                        personalized elements such as commemorative plaques, or
                        the planting of trees or flowers in memory of their
                        loved one.
                      </p>
                    </div>
                    <div
                      className="text-content1"
                      onClick={() => handleClickReadMore("customization")}
                    >
                      <div className="btn">Read More</div>
                    </div>
                  </div>
                </div>
                <div className="process-col">
                  <div className="process">
                    <img src={NaturalCustom2} alt="" />
                    <div className="process-content">
                      <span>Ceremonies and Remembrance</span>
                      <p>
                        We understand the importance of remembrance and
                        ceremonies in the grieving process. Our team is
                        dedicated to working closely with families to
                        accommodate their cultural and spiritual practices,
                        ensuring a meaningful and respectful farewell for their
                        loved one.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-col">
                  <div className="process">
                    <img src={NaturalCustom4} alt="" />
                    <div className="process-content">
                      <span>Guidance and Support</span>
                      <p>
                        Our experienced team is here to guide and support you
                        throughout the entire process. We provide detailed
                        information, answer any questions you may have, and
                        ensure that every aspect of the natural burial
                        experience is tailored to your needs and wishes.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="process-fotter-col">
            <div className="process-footer">
              <p>
                By offering a range of options and customisation features,
                Inspired Meadows strives to create a natural burial experience
                that is as unique as the individuals we serve, providing a
                meaningful and lasting tribute to their life and legacy.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="natural-key-main">
        <div className="container">
          <div className="row">
            <div className="key-head-col">
              <div className="key-head">
                <h2>Key Highlights of the Burial Sites</h2>
                <p>
                  Natural burials offer a unique and meaningful approach to
                  honouring the life of a loved one while embracing the beauty
                  and tranquility of nature. With a focus on sustainability and
                  environmental preservation, natural burials provide numerous
                  benefits for both individuals and the planet.
                </p>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyfirst} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Serene Landscaping</h3>
                  <p>
                    Our burial sites feature thoughtfully designed landscapes
                    that blend seamlessly with the surrounding natural beauty.
                    Lush greenery, meandering pathways, and gentle water
                    features create a sense of serenity and harmony, providing a
                    peaceful setting for reflection and remembrance.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keySecond} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Scenic Views</h3>
                  <p>
                    Inspired Meadows is committed to selecting locations that
                    offer breathtaking views of the surrounding natural
                    landscapes. Whether it's a panoramic vista of rolling hills,
                    a tranquil lake, or a picturesque woodland, our burial sites
                    provide a visually captivating backdrop that enhances the
                    overall experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyThird} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Thoughtful Design Elements</h3>
                  <p>
                    Every detail of our burial sites is carefully considered to
                    ensure a tranquil and aesthetically pleasing atmosphere.
                    From the placement of benches and seating areas for
                    contemplation to the integration of native plants and
                    flowers that attract wildlife, we create a space that
                    encourages a deep connection with nature.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyFourth} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Natural Elements</h3>
                  <p>
                    We embrace the inherent beauty of the natural world in our
                    burial sites. The presence of mature trees, vibrant flora,
                    and gentle wildlife fosters a sense of peace and
                    rejuvenation. Walking through our sites, visitors are
                    enveloped in a soothing ambiance that allows for a profound
                    and meaningful experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyFive} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Peaceful Reflection Areas</h3>
                  <p>
                    Inspired Meadows offers dedicated spaces for quiet
                    reflection and personal contemplation. These designated
                    areas provide a sense of privacy and tranquility, allowing
                    individuals to find solace and connect with their thoughts
                    and emotions in a serene setting.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain p-spacing">
                <div className="key-chain-img">
                  <span>
                    <img src={keySix} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Harmonious Integration</h3>
                  <p>
                    Our burial sites are designed to seamlessly integrate with
                    the surrounding environment, ensuring minimal disruption to
                    the natural landscape. We prioritise sustainability and
                    conservation, preserving the ecological balance and allowing
                    individuals to rest in harmony with nature.
                  </p>
                </div>
              </div>
            </div>
            <div className="chain-footer-col">
              <div className="chain-footer">
                <p>
                  At Inspired Meadows, we understand the importance of creating
                  a tranquil and aesthetically pleasing environment for the
                  final resting place. Our burial sites offer a serene sanctuary
                  where loved ones can find solace, seek comfort, and celebrate
                  the memories of those who have passed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Adviser-main">
        <div className="future-planing overview-future-planing">
          <div className="container">
            <div className="row">
              <div className="talk-adviser-col">
                <div className="talk-adviser">
                  <div className="msg-img">
                    <img src={msgbox} alt="message box" />
                  </div>
                  <div className="adviser-head">
                    <h3>Speak to an Advisor</h3>
                    <p>
                      For more information please contact us for a no obligation
                      call. Please click on SCHEDULE A CALL button
                    </p>
                  </div>
                  <div className="adviser-contact">
                    <Link to="/schedule-a-call">
                      Schedule a Call <img src={anglerighticon} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {activeReadMore && (
        <div>
          <ReadMorePopup
            activeReadMore={activeReadMore}
            activeValue={activeValue}
            onClose={onCloseReadMore}
          />
        </div>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
