import React from "react";
import { useNavigate , Link } from "react-router-dom";
import Arrow_left from "../../assets/images/arrow_left.png";
import msgbox from "../../assets/images/msg-box.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import { Fragment } from "react";
import SEO from "../SEO/SEO";



const StoryBehind = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    }
    return (
        <Fragment>
            <SEO
            title="Inspired Meadows | Story Behind"
            />
            <section id="learn-overview" class="learn-inspired">
                <div class="container">
                    <div class="row">
                        <div class="overview-col">
                            <div class="overview-content">
                                <div className="back-link" onClick={handleClick}><img src={Arrow_left} alt="" /> Go back</div><br />
                                <div class="terms-update term-inspired-info">
                                    <p>In 1976, when I was just eight years old, I experienced the loss of my mother to cancer.
                                        Back then, the approach to death and bereaved children was very different. It was
                                        considered usual practice at the time to advise that a funeral was no
                                        place for a child, even with the best intentions.  </p>
                                    <p>As the years passed, I longed to visit my mother's grave
                                        and pay my respects. However, I was told she had been cremated and her ashes scattered, with
                                        no physical marker or grave to visit. This realisation left a void in my heart, especially
                                        on occasions like Mother's Day when I yearned for a place to lay flowers and find solace.
                                        My experience with loss and the absence of a
                                        tangible memorial has shaped my perspective on death and the bereavement process. </p>
                                    <p>Around ten years ago, I learned about natural burial options
                                        through media and conversations. The idea of being laid to rest in a
                                        biodegradable urn coffin or shroud at a serene country location resonated
                                        deeply with me. It offered an alternative to the traditional church ceremony and
                                        crowded cemeteries, creating a more natural and meaningful final resting place. </p>
                                    <p>Today, as a member of the Inspired Meadows team, we have created something truly special.
                                        Through our journey of visiting burial grounds and cemeteries, we realised that woodland
                                        and natural burials might be outside their comfort zone for some individuals. Similarly,
                                        a religion-based church burial or ashes interment may not align with their beliefs or
                                        preferences. </p>
                                    <p>At Inspired Meadows, we believe in providing choices. We understand that each person deserves the
                                        opportunity to shape their final arrangements according to their wishes. While we cannot choose
                                        the moment of our passing, we can certainly choose what happens to us afterwards. By 'planning
                                        ahead' and exploring alternatives such as natural burials, we can alleviate some of the
                                        burdens on our loved ones during a time of great sorrow. </p>
                                    <p>Our mission is to create a sense of ease and peace for individuals and their families by offering a range of options catering
                                        to personal preferences and beliefs. Everyone deserves a dignified and meaningful farewell that brings solace
                                        and honours their unique journey. </p>
                                    <p>Inspired Meadows is here to guide and support you through this process, ensuring that your final
                                        arrangements reflect your wishes and provide comfort to loved ones. Together, we can create
                                        a lasting legacy that celebrates life and brings solace in the face of loss. </p>
                                    <b>Stephen Brown, Director of Inspired Meadows </b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Adviser-main">
                <div className="future-planing overview-future-planing">
                    <div className="container">
                        <div className="row">
                            <div className="talk-adviser-col">
                                <div className="talk-adviser">
                                    <div className="msg-img">
                                        <img src={msgbox} alt="message box" />
                                    </div>
                                    <div className="adviser-head">
                                        <h3>Speak to an Advisor</h3>
                                        <p>For more information please contact us for a no obligation call. Please click on SCHEDULE A CALL button</p>
                                    </div>
                                    <div className="adviser-contact">
                                        <Link to="/schedule-a-call">Schedule a Call <img src={anglerighticon} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default StoryBehind;