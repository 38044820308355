import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Arrow_left from "../../assets/images/arrow_left.png";
import msgbox from "../../assets/images/msg-box.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import { Fragment } from "react";
import SEO from "../SEO/SEO";


const PlanningAhead = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    }
    return (
        <Fragment>
            <SEO
            title="Inspired Meadows | Planning Ahead"
            />
            <section id="learn-overview" class="learn-inspired">
                <div class="container">
                    <div class="row">
                        <div class="overview-col">
                            <div class="overview-content">
                                <div className="back-link" onClick={handleClick}><img src={Arrow_left} alt="" /> Go back</div><br />
                                <div class="terms-update">
                                    <h3>The Importance of Planning Ahead </h3>
                                    <p>Navigating the uncertain landscapes of the future might seem overwhelming, but with Inspired Meadows,
                                        the path to peace is transparent, comforting, and economically prudent. We're not just about end-of-life
                                        planning; we're about celebrating life, memories, and the legacies we leave behind – all while ensuring
                                        your financial foresight. </p>
                                </div>
                                <div class="services-use services-inspired">
                                    <h3>Why is Planning Essential?</h3><br />
                                    <ul>
                                        <li>
                                            <p class="mb"><span class="terms-heading">Gift of Tranquillity: </span> 
                                            &nbsp;Picture this – a time of emotional turmoil, but your family is spared the added weight of decisions, financial strain, and the
                                                quest for the perfect resting space. Pre-planning is your gift of love, sparing them from these challenges. </p>
                                        </li>
                                        <li>
                                            <p class="mb"><span class="terms-heading">Today's Choices, Tomorrow's Savings:</span>
                                            &nbsp;With the world constantly changing and costs invariably rising, your choice to secure a serene space in Inspired Meadows today is
                                                economically wise. Lock in today's prices to hedge against future inflation, ensuring a hassle-free and economically sound
                                                experience for your loved ones. </p>
                                        </li>
                                        <li>
                                            <p class="mb"><span class="terms-heading">Family Unity: </span> Our bespoke
                                                burial space allows families to rest together, preserving the bond even
                                                after death. </p>
                                        </li>
                                        <li>
                                            <p class="mb"><span class="terms-heading">Your Story, Your Space:</span>
                                            &nbsp;At Inspired Meadows, we honour individuality. Whether it's a specific tree you find solace under or a spot that offers a perfect
                                                view of the sunset, your plot is an extension of your story, secured at today's rate.
                                            </p>
                                        </li>
                                        <li>
                                            <p><span class="terms-heading">Nature's Embrace:</span>
                                            &nbsp; Our meadows aren't just burial grounds. They're sanctuaries of nature, where the whispering winds and rustling leaves pay
                                                tribute to every soul. This is not an ending but a return to nature's warm embrace. </p>
                                        </li>
                                        <li>
                                            <p><span class="terms-heading">Customised Elegance with Value:</span>
                                            &nbsp; We believe every life is a unique tapestry of memories. Our team is here to help weave your legacy, ensuring your final
                                                resting place mirrors your life's journey, all within a budget-friendly framework. </p>
                                        </li>
                                        <li>
                                            <p><span class="terms-heading">Sustainable Choices for the future at today's prices:</span>
                                            &nbsp;We're ahead of the curve in a world seeking sustainability. With eco-friendly burial options, we ensure that
                                                resting in peace also means peace for our planet and your wallet.</p>
                                        </li>
                                        <li>
                                            <p><span class="terms-heading">Craft Your Legacy with Inspired Meadows:</span>
                                            &nbsp;  Life is an intricate dance between moments we cherish and moments we plan for. Inspired Meadows
                                                intertwines both, ensuring that when the sun sets on one journey, a beautiful legacy shines brightly for
                                                the world to see – and your foresight is rewarded in more ways than one. </p>
                                        </li>
                                        <li>
                                            <p><span class="terms-heading">Let's Address Your Thoughts:</span>
                                            &nbsp;  We understand you might have questions or even hesitations. Our dedicated team is here to answer, guide, and ensure you're
                                                comfortable every step of the way – especially when making economic sense of your decisions. </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Adviser-main">
                <div className="future-planing overview-future-planing">
                    <div className="container">
                        <div className="row">
                            <div className="talk-adviser-col">
                                <div className="talk-adviser">
                                    <div className="msg-img">
                                        <img src={msgbox} alt="message box" />
                                    </div>
                                    <div className="adviser-head">
                                        <h3>Speak to an Advisor</h3>
                                        <p>For more information please contact us for a no obligation call. Please click on SCHEDULE A CALL button</p>
                                    </div>
                                    <div className="adviser-contact">
                                        <Link to="/schedule-a-call">Schedule a Call <img src={anglerighticon} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default PlanningAhead;