import React, {useState, useEffect, Fragment} from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { validate as FormValidate, ListErrorMessages } from '../../../constants/Validations';
import { UPDATE_USER_PASS, UPDATE_USER_PASS_RESET } from "../../../constants/actionTypes";
import agent from "../../../agent";
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const mapStateToProps = (state) => ({
    currentUser: state.common.currentUser,
    updatePassSucc: state.common.updatePassSucc,
    updatePassError: state.common.updatePassError,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitForm: async (cid,values) => {
        try {
            const response = await agent.Customer.updatePassword(cid,values);
            dispatch({
                type: UPDATE_USER_PASS,
                payload: response,
            });
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (error) {
            const responseJson = JSON.parse(error.response.text);
            const errorMessage = responseJson.message;
            if (error.response && error.response.statusCode === 400) {
                dispatch({
                    type: UPDATE_USER_PASS,
                    payload: JSON.parse(error.response.text),
                });
                // toast.error(errorMessage, {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            }
        }
    },
    onEmpty: () => { dispatch({ type: UPDATE_USER_PASS_RESET }) }
});

const ChangePassword = (props) => {
    const { currentUser, onSubmitForm, onEmpty, updatePassSucc, updatePassError, showChangePass } =props
    const [password, setPassword] = useState("");
    const [currentpassword, setCurrentPassword] = useState("");
    const [showCurrentpassword, setShowCurrentPassword] = useState(false);
    const [showPassowrd, setShowPassword] = useState(false);
    const [showConfirmPassowrd, setShowConfirmPassword] = useState(false);
    const [confirmpassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const [inProgress, setInProgress] = useState(false);

    const submitBtn =  (e) => {
        e.preventDefault();
        let checkValidate = FormValidate({
            password: { value: password, required: true, hasSpecialCharacter:true, hasUpperCase: true, hasLowerCase:true, minlength: 8 },
            confirmPassword: { value: confirmpassword, required: true, hasSpecialCharacter:true, hasUpperCase: true, hasLowerCase:true, minlength: 8,equalPassword: password }
        });
        if(!checkValidate.status) {
            setErrors(null);
            submitHandle()
        } else {
            let values = ListErrorMessages(checkValidate.errors);
            setErrors(values);
        }
    } 

    const submitHandle =  () => {
        let sendData ={
           "currentPassword": currentpassword,
           "confirmPassword": confirmpassword,
           "newPassword": password
         }
        setInProgress(true)
        if (currentUser && currentUser.id) {
            onSubmitForm(currentUser.id,sendData);
        }
        
    };

    useEffect(() => {
        if(updatePassError) {
            if (typeof updatePassError === "string") {
                setMessage(updatePassError)
                setInProgress(false)
                if (updatePassSucc) {
                    setPassword('')
                    setCurrentPassword('')
                    setConfirmPassword('')
                }
                setTimeout(function() {
                    onEmpty()
                    setMessage(null)
                }, 5000);
            }
            
        }
        
    }, [updatePassError])

    return (
        <div className="profile-details-main" style={{display: `${showChangePass ? 'block': 'none'}`}}>
            <div className="profile-details profile-password">
            {/* {(message && updatePassSucc) ? <span className={(updatePassSucc) ? 'text-success' : 'text-danger' }>{message}</span> : <Fragment /> } */}
                <div className="profile-info">
                    <h3>Change Password</h3>
                    <div className="profile-input">
                        <label>Current Password</label>
                        <input
                            placeholder="************"
                            type={showCurrentpassword ? "text" : "password"}
                            value={currentpassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                        <i className={`fa fa-eye${showCurrentpassword?'':'-slash'}`} aria-hidden="true" onClick={(e) => setShowCurrentPassword(!showCurrentpassword)}></i>
                        <span className="text-danger">{errors && errors.password ? errors.password : ''}</span>
                        <span className="text-danger">{message && !updatePassSucc ? message : ''}</span>
                    </div>
                    <div className="profile-input">
                        <label>New Password</label>
                        <input
                            placeholder="************"
                            type={showPassowrd ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <i className={`fa fa-eye${showPassowrd?'':'-slash'}`} aria-hidden="true" onClick={(e) => setShowPassword(!showPassowrd)}></i>
                        <span className="text-danger">{errors && errors.password ? errors.password : ''}</span>
                    </div>
                    <div className="profile-input">
                        <label>Confirm Password</label>
                        <input
                            placeholder="************"
                            type={showConfirmPassowrd ? "text" : "password"}
                            value={confirmpassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <i className={`fa fa-eye${showConfirmPassowrd?'':'-slash'}`} aria-hidden="true" onClick={(e) => setShowConfirmPassword(!showConfirmPassowrd)}></i>
                        <span className="text-danger">{errors && errors.confirmPassword ? errors.confirmPassword : ''}</span>
                    </div>
                </div>
            </div>
            <div className="profile-btn">
                {/* <button className="cancel">Cancel</button> */}
                <button type="button" onClick={submitBtn} className="save">Save changes</button>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);