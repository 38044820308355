import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/images/image.png";
import agent from "../../agent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntermentFormPay from "./IntermentFormPay";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import TextField from "@mui/material/TextField";
import DatePicker from "../Common/DatePicker";
import { getFormattedDate } from "../../utils";
import moment from "moment/moment";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import SEO from "../SEO/SEO";


const IntermentFormOne = () => {
  const person = {
    personName: "",
    personAddress: "",
    personPostCode: "",
    personEmail: "",
    personContactNumber: "",
  };

  const funeralDirector = {
    companyName: "",
    contactName: "",
    contactEmail: "",
    contactTelephone: "",
  };

  const deceased = {
    deceasedName: "",
    deceaseAddress: "",
    deceasePostCode: "",
    deceaseBirth: "",
    deceaseDeath: "",
    deceasedRelation: "",
    deceasedRepresentative: "",
  };

  const initialState = {
    ...person,
    urnPlotNumber: "",
    intermentFee: "",
    ...deceased,
    ...funeralDirector,
    signature: "",
    date: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [petAshesIncluded, setPetAshesIncluded] = useState(false);
  const [price, setPrice] = useState();

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [stripeKeys, setStripeKeys] = useState(null);
  const [intermentId, setIntermentId] = useState("");
  const [contactTelephone, setContactTelephone] = useState("");

  const [formattedNumber, setFormattedNumber] = useState("");
  const [statusApiResponse, setStatusApiResponse] = useState(null);

  const [submitBtn, setSubmitBtn] = useState("Submit");
  const [fieldsToValidate, setFieldsToValidate] = useState([]);
  const [todayDate, setTodayDate] = useState(getFormattedDate(new Date()));

  const divref = useRef();

  const stripePromise = loadStripe(process.env.REACT_APP_MEADOWS_STRIPE_KEY);
  const stripeTestPromise = loadStripe(process.env.REACT_APP_MEADOWS_STRIPE_TEST_KEY);



  const options = {
    mode: "payment",
    amount: price * 100,
    currency: "gbp",
    payment_method_types: ['card'],
  };

  useCalendlyEventListener({
    
    onEventScheduled: (e) =>{
      const data = e.data?.payload;

      const payload = {
        intermentId:intermentId ,
        calendly:data?.event?.uri ,
        invitee: data?.invitee?.uri
      }

      const postLinks = async()=>{
        try {
          let statusResponse = await agent.common.postCalendlyLinks(payload)
        } catch (error) {
       
        }
      }

      postLinks()

    },
  });


  const handleFormChange = (event) => {
    const { name, value } = event.target;

    if (name === "urnPlotNumber") {
      setFormData({
        ...formData,
        [name]: value.toUpperCase(),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleFee = (event) => {
    const { value } = event.target;
    setPrice(value);

    if (value == 150) {
      setPetAshesIncluded(false);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-digits
    const formattedValue =
      value.length > 5 ? value.slice(0, 5) + " " + value.slice(5) : value;
    setFormattedNumber(formattedValue);
  };

  const handleContactTelephone = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-digits
    const formattedValue =
      value.length > 5 ? value.slice(0, 5) + " " + value.slice(5) : value;
    setContactTelephone(formattedValue);
  };

  const validateform = (formData) => {
    // Check if any required field is empty
    const postcodeRegex =
      /^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

   
    if (
      !formData.personName ||
      !formData.personAddress ||
      !formData.personPostCode ||
      !formData.personEmail ||
      (!formattedNumber && !formData.personContactNumber) ||
      !formData.deceasedName ||
      !formData.deceaseAddress ||
      !formData.deceasePostCode ||
      !formData.deceaseBirth ||
      !formData.deceaseDeath ||
      !formData.deceasedRelation ||
      !formData.deceasedRepresentative ||
      !formData.urnPlotNumber ||
      !price ||
      !formData.signature
    ) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    
      return false;
    }

    // if (
    //   !postcodeRegex.test(formData.personPostCode) ||
    //   !postcodeRegex.test(formData.deceasePostCode)
    // ) {
    //   toast.error("Please enter a valid Post Code", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return false;
    // }

    if (
      !emailRegex.test(formData.personEmail)
      // !emailRegex.test(formData.contactEmail)
    ) {
      toast.error("Please enter a valid email address", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    }

    const birthDate = new Date(formData.deceaseBirth);
    const deathDate = new Date(formData.deceaseDeath);
    const currentDate = new Date();

    // Check if birth date is after death date
    if (birthDate > deathDate) {
      toast.error("Please select a birth date before the death date", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (birthDate > currentDate || deathDate > currentDate) {
      toast.error("Please select a date in the past or today", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    return true;
  };

  const submitHanlde = async () => {
    const fields = [];
    // Add the names of the fields that need to be validated
    if (!formData.personName) fields.push("personName");
    if (!formData.personAddress) fields.push("personAddress");
    if (!formData.personPostCode) fields.push("personPostCode");
    if (!formData.personEmail) fields.push("personEmail");
    
    if (!formData.deceasedName) fields.push("deceasedName");
    if (!formData.personContactNumber && !formattedNumber) {
      fields.push("personContactNumber")
      // fields.push("telephoneNumber")
    }
    if (!formData.deceaseAddress) fields.push("deceaseAddress");
    if (!formData.deceasePostCode) fields.push("deceasePostCode");
    if (!formData.deceaseBirth) fields.push("deceaseBirth");
    if (!formData.deceaseDeath) fields.push("deceaseDeath");
    if (!formData.deceasedRelation) fields.push("deceasedRelation");
    if (!formData.deceasedRepresentative) fields.push("deceasedRepresentative");
    if (!formData.urnPlotNumber) fields.push("urnPlotNumber");
    if (!price) fields.push("price");
    if (!formData.signature) fields.push("signature");

    setFieldsToValidate(fields);

    if (validateform(formData)) {
      try {
        setSubmitBtn("Loading...");
        const payload = {
          applicant: {
            address: formData.personAddress,
            email: formData.personEmail,
            name: formData.personName,
            phoneNumber: {
              dialCode: "+44",
              number: formData.personContactNumber,
            },
            telePhoneNumber: formattedNumber,
            postalCode: formData.personPostCode,
          },
          deceased: {
            name:formData.deceasedName,
            address: formData.deceaseAddress,
            dateOfBirth: moment(formData.deceaseBirth).format("DD-MM-YYYY"),
            dateOfDeath: moment(formData.deceaseDeath).format("DD-MM-YYYY"),
            plot: {
              // category: ,
              plotNumber: formData.urnPlotNumber,
            },
            postalCode: formData.deceasePostCode,
            primaryRespresentative: formData.deceasedRelation,
            relationShip: formData.deceasedRelation,
          },
          funeralDirector: {
            companyName: formData.companyName,
            contactEmail: formData.contactEmail,
            contactName: formData.contactName,
            contactPhoneNumber: {
              dialCode: "+44",
              number: contactTelephone,
            },
          },
          intermentFees: {
            fees: Number(price),
            humanAshes: price == 200 ? "humanAshes" : "",
            petAshes: price == 150 ? "petAshes" : "",
            petAshesIncluded: petAshesIncluded ? "yes" : "",
          },
          signature: {
            signature: formData.signature,
            signatureDate: todayDate,
          },
        };

        let res = await agent.common.addIntermentForm(payload);

        if (res?.status) {
          setIsModalOpen(true);
          setStripeKeys(res?.data);
          setIntermentId(res?.intermentId);
          setSubmitBtn("Submit");
        }
      } catch (error) {
        setSubmitBtn("Submit");
        if (error?.response?.body?.message) {
          toast.error(error?.response?.body?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
       
      }
    }
  };

  return (
    <>
     <SEO
				title="Inspired Meadows | Interment Form"
				
       />
      <div className="logo-for-pdf">
        <span>
          <img src={Logo} alt="" />
        </span>
      </div>

      {statusApiResponse?.calendlyLink ? (
        <InlineWidget url={statusApiResponse?.calendlyLink} />
      ) : (
        <section id="location-form-main" className="new-interment-form-design">
          <div className="container">
            <div className="new-location-form">
              <h1>Interment Request Form</h1>
              <div className="form-main">
                <form>
                  <h3>
                    1. The Applicant (the person who will be responsible for
                    payment)
                  </h3>
                  <div className="input-field">
                    <label>Name:</label>

                    <input
                      type="text"
                      name="personName"
                      onChange={handleFormChange}
                      // placeholder={
                      //   fieldsToValidate.includes("personName") &&
                      //   !formData.personName
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      value={formData.personName}
                      className={
                        fieldsToValidate.includes("personName") &&
                        !formData.personName
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-field">
                    <label>Address:</label>
                    <input
                      type="text"
                      name="personAddress"
                      // placeholder={
                      //   fieldsToValidate.includes("personAddress") &&
                      //   !formData.personAddress
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      onChange={handleFormChange}
                      value={formData.personAddress}
                      className={
                        fieldsToValidate.includes("personAddress") &&
                        !formData.personAddress
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-field">
                    <label>Post Code:</label>
                    <input
                      type="text"
                      // placeholder={
                      //   fieldsToValidate.includes("personPostCode") &&
                      //   !formData.personPostCode
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      name="personPostCode"
                      onChange={handleFormChange}
                      value={formData.personPostCode}
                      className={
                        fieldsToValidate.includes("personPostCode") &&
                        !formData.personPostCode
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-field">
                    <label>Email Address:</label>
                    <input
                      type="email"
                      // placeholder={
                      //   fieldsToValidate.includes("personEmail") &&
                      //   !formData.personEmail
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      name="personEmail"
                      onChange={handleFormChange}
                      value={formData.personEmail}
                      className={
                        fieldsToValidate.includes("personEmail") &&
                        !formData.personEmail
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-date">
                    <div className="input-field birth">
                      <label>Telephone Number:</label>
                      <div className="input-date-picker">
                        <input
                          type="tel"
                          value={formattedNumber}
                          name="telephoneNumber"
                          onChange={handleChange}
                          // placeholder={
                          //   fieldsToValidate.includes("telephoneNumber") &&
                          //   !formattedNumber
                          //     ? `Can't be empty`
                          //     : ""
                          // }
                          className={
                            fieldsToValidate.includes("personContactNumber") &&
                            ( !formData.personContactNumber && !formattedNumber)
                              ? "invalid"
                              : ""
                          }
                        />
                      </div>
                    </div>
                   
                    <div className="input-field">
                      <label>Mobile Number:</label>
                      <div className="input-date-picker">
                        <input
                          type="number"
                          value={formData.personContactNumber}
                          name="personContactNumber"
                          onChange={handleFormChange}
                          // placeholder={
                          //   fieldsToValidate.includes("personContactNumber") &&
                          //   !formattedNumber
                          //     ? `Can't be empty`
                          //     : ""
                          // }
                          className={
                            fieldsToValidate.includes("personContactNumber") &&
                           ( !formData.personContactNumber && !formattedNumber)
                              ? "invalid"
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <form>
                  <h3>2. Details of Deceased</h3>
                  <div className="input-field">
                    <label>Name:</label>
                    <input
                      name="deceasedName"
                      // placeholder={
                      //   fieldsToValidate.includes("deceasedName") &&
                      //   !formData.deceasedName
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      onChange={handleFormChange}
                      value={formData.deceasedName}
                      type="text"
                      className={
                        fieldsToValidate.includes("deceasedName") &&
                        !formData.deceasedName
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-field">
                    <label>Address:</label>
                    <input
                      name="deceaseAddress"
                      // placeholder={
                      //   fieldsToValidate.includes("deceaseAddress") &&
                      //   !formData.deceaseAddress
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      onChange={handleFormChange}
                      value={formData.deceaseAddress}
                      type="text"
                      className={
                        fieldsToValidate.includes("deceaseAddress") &&
                        !formData.deceaseAddress
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-field">
                    <label>Post Code:</label>
                    <input
                      name="deceasePostCode"
                      onChange={handleFormChange}
                      // placeholder={
                      //   fieldsToValidate.includes("deceasePostCode") &&
                      //   !formData.deceasePostCode
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      value={formData.deceasePostCode}
                      type="text"
                      className={
                        fieldsToValidate.includes("deceasePostCode") &&
                        !formData.deceasePostCode
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-date">
                    <div className="input-field birth">
                      <label>Date of Birth:</label>
                      <div className="input-date-picker">
                        <input
                          type="date"
                          value={formData.deceaseBirth}
                          name="deceaseBirth"
                          onChange={handleFormChange}
                          fieldsToValidate={fieldsToValidate.includes(
                            "deceaseBirth"
                          )}
                          className={
                            fieldsToValidate.includes("deceaseBirth") &&
                            !formData.deceaseBirth
                              ? "invalid"
                              : ""
                          }
                          formData={formData}
                        />
                      </div>
                    </div>
                    <div className="input-field">
                      <label>Date of Death:</label>
                      <div className="input-date-picker">
                        <input
                         className={
                          fieldsToValidate.includes("deceaseDeath") &&
                          !formData.deceaseDeath ? "invalid" : ""  }
                          type="date"
                          value={formData.deceaseDeath}
                          name="deceaseDeath"
                          onChange={handleFormChange}
                          fieldsToValidate={fieldsToValidate.includes("deceaseDeath")}
                          formData={formData}
                        />
                      </div>

                      {/* <input
                        onChange={handleFormChange}
                        type="date"
                        name="deceaseDeath"
                        placeholder={
                          fieldsToValidate.includes("deceaseDeath") &&
                          !formData.deceaseDeath
                            ? `Can't be empty`
                            : ""
                        }
                        value={formData.deceaseDeath}
                        className={
                          fieldsToValidate.includes("deceaseDeath") &&
                          !formData.deceaseDeath
                            ? "invalid"
                            : ""
                        }
                      /> */}
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Your relationship to the deceased?</label>
                    <input
                      type="text"
                      name="deceasedRelation"
                      // placeholder={
                      //   fieldsToValidate.includes("deceasedRelation") &&
                      //   !formData.deceasedRelation
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      className={
                        fieldsToValidate.includes("deceasedRelation") &&
                        !formData.deceasedRelation
                          ? "invalid"
                          : ""
                      }
                      onChange={handleFormChange}
                      value={formData?.deceasedRelation}
                    />
                  </div>
                  <div className="input-field">
                    <label>Who is the deceased's primary representative?</label>
                    <input
                      type="text"
                      name="deceasedRepresentative"
                      // placeholder={
                      //   fieldsToValidate.includes("deceasedRepresentative") &&
                      //   !formData.deceasedRepresentative
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      onChange={handleFormChange}
                      value={formData?.deceasedRepresentative}
                      className={
                        fieldsToValidate.includes("deceasedRepresentative") &&
                        !formData.deceasedRepresentative
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-field">
                    <label>Urn Plot Number:</label>
                    <input
                      // style={{textTransform:"uppercase"}}
                      type="text"
                      name="urnPlotNumber"
                      // placeholder={
                      //   fieldsToValidate.includes("urnPlotNumber") &&
                      //   !formData.urnPlotNumber
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      onChange={handleFormChange}
                      value={formData?.urnPlotNumber}
                      className={
                        fieldsToValidate.includes("urnPlotNumber") &&
                        !formData.urnPlotNumber
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                </form>
                <div className="fee-main">
                  <div className="detail">
                    <div className="head first">
                      <h4>
                        {fieldsToValidate.includes("price") && !price ? (
                          <span className="invalidPrice">*</span>
                        ) : (
                          ""
                        )}
                        Interment Fee <span>£200.00</span>
                      </h4>
                      <p>(Select Yes if Pet Ashes to be included)</p>
                    </div>
                    <div className="checkbox">
                      <div className="check">
                        <label htmlFor="">Human Ashes</label>
                        <input
                          type="radio"
                          name="petAshes"
                          value={200}
                          checked={price == 200}
                          onChange={handleFee}
                        />
                      </div>
                      <div className="check">
                        <label htmlFor="">Yes</label>
                        <input
                          type="checkbox"
                          checked={petAshesIncluded}
                          name="petAshesIncluded"
                          onChange={(e) =>
                            setPetAshesIncluded(e.target.checked)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="head pl">
                      <h4>
                        Interment Fee <span>£150.00</span>
                      </h4>
                      <p>(If Pet Ashes buried separately)</p>
                    </div>
                    <div className="checkbox second">
                      <div className="check">
                        <label htmlFor="">Pet Ashes</label>
                        <input
                          type="radio"
                          checked={price == 150}
                          value={150}
                          name="petAshes"
                          onChange={handleFee}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <form>
                  <h3>
                    3. Funeral Director (if you are using one){" "}
                    <span className="directorOptional">(Optional)</span>
                  </h3>
                  <div className="input-field">
                    <label htmlFor="">Company Name:</label>
                    <input
                      type="text"
                      name="companyName"
                      onChange={handleFormChange}
                      value={formData?.companyName}
                      // placeholder={
                      //   fieldsToValidate.includes("companyName") &&
                      //   !formData.companyName
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      className={
                        fieldsToValidate.includes("companyName") &&
                        !formData.companyName
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-field">
                    <label htmlFor="">Contact Name:</label>
                    <input
                      type="text"
                      name="contactName"
                      onChange={handleFormChange}
                      // placeholder={
                      //   fieldsToValidate.includes("contactName") &&
                      //   !formData.contactName
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      value={formData?.contactName}
                      className={
                        fieldsToValidate.includes("contactName") &&
                        !formData.contactName
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-field">
                    <label htmlFor="">Contact Email Address:</label>
                    <input
                      type="text"
                      name="contactEmail"
                      onChange={handleFormChange}
                      // placeholder={
                      //   fieldsToValidate.includes("contactEmail") &&
                      //   !formData.contactEmail
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      value={formData?.contactEmail}
                      className={
                        fieldsToValidate.includes("contactEmail") &&
                        !formData.contactEmail
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                  <div className="input-field">
                    <label htmlFor="">Contact Telephone Number:</label>
                    <input
                      type="tel"
                      name="contactTelephone"
                      // placeholder={
                      //   fieldsToValidate.includes("contactTelephone") &&
                      //   !contactTelephone
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      onChange={handleContactTelephone}
                      value={contactTelephone}
                      className={
                        fieldsToValidate.includes("contactTelephone") &&
                        !contactTelephone
                          ? "invalid"
                          : ""
                      }
                    />
                  </div>
                </form>
                <form>
                  <h3>4. The Event</h3>
                  <div className="event_bullets">
                    <ul>
                      <li>
                        Interments can be arranged Monday – Friday during the
                        times stated below, subject to availability.
                      </li>
                      <li>
                        {" "}
                        Spring / Summer period: April – September between the
                        hours of 10.00hrs -17.00hrs (excluding Bank Holidays or
                        other Public Holidays).{" "}
                      </li>
                      <li>
                        Autumn / Winter period: October – March between 10.00hrs
                        – 14.00hrs (excluding Bank Holidays or other Public
                        Holidays).
                      </li>
                      <li>
                        You will be able to select your chosen date and time
                        from the calendar after the Interment Request form has
                        been submitted and payment has been processed.
                      </li>
                    </ul>
                  </div>
                </form>
                <div className="paragraph">
                  <p>
                    The information given on this form is accurate. I am the
                    person to whom all correspondence should be sent and am
                    responsible for paying the selected amount(s) shown above
                    and agree to be bound by Inspired Meadows Regulations as
                    found on the website:
                    <span >www.inspiredmeadows.co.uk</span>
                  </p>
                </div>
                <div className="application">
                  <div className="input-group first">
                    <label htmlFor="">Applicant Signature:</label>
                    <input
                      type="text"
                      onChange={handleFormChange}
                      // placeholder={
                      //   fieldsToValidate.includes("signature") &&
                      //   !formData.signature
                      //     ? `Can't be empty`
                      //     : ""
                      // }
                      name="signature"
                      value={formData?.signature}
                      className={`signatureStyle ${
                        fieldsToValidate.includes("signature") &&
                        !formData.signature
                          ? "invalid"
                          : ""
                      }`}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Date:</label>
                    <input type="text" value={todayDate} disabled />
                  </div>
                </div>
                <div className="submit-btn">
                  <p>Please select SUBMIT to proceed to payment.</p>
                  <button onClick={submitHanlde}>{submitBtn}</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <ToastContainer />

      {stripeKeys && (
        <>
          <Elements stripe={formData.personEmail === "kay@inspired.us" ? stripeTestPromise : stripePromise} options={options}>
            <IntermentFormPay
              setStatusApiResponse={setStatusApiResponse}
              intermentId={intermentId}
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              stripeKeys={stripeKeys}
            />
          </Elements>
        </>
      )}

      {/* {statusApiResponse?.calendlyLink && <InlineWidget url={statusApiResponse?.calendlyLink} /> } */}
    </>
  );
};

export default IntermentFormOne;
