import React  from 'react';
import { connect } from "react-redux";
import MainView from './MainView';
import SEO from '../SEO/SEO';
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const AboutUs = (props) => {
	let mainProps = {};

	return (
		<div className="aboutus-page">
			<SEO
				title="About Inspired Meadows commitment to environmental stewardship "
				description="Learn about Inspired Meadows' commitment to environmental stewardship and compassionate support."
				keywords="Environmental stewardship, Compassionate support for families, Preserving memories and legacies, Empathy and guidance in end-of-life decisions"
			   href="https://www.inspiredmeadows.co.uk/about-us"
            />

			<MainView {...mainProps} />
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);