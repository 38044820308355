import React, { Fragment } from 'react';
import Header from '../Includes/Header';
import Footer from '../Includes/Footer';

const FrontendLayout = ({ children }) => (
	<Fragment>
		<div className="header">
			<Header />
		</div>
		
    	{children}

		<div className="footer">
			<Footer />
		</div>
    	
    </Fragment>
);

export default FrontendLayout;