import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const NewMap = ({ plotData, openPlotDetail, urnPlotsArea, loading }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const plotLayerSourceLoaded = useRef(false);
  const hoverPolygonId = useRef(null);
  const allPlotsRef = useRef([]);

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoibWVhZG93czEiLCJhIjoiY2x0ZmQxMHY4MDdlbjJscXd3eHQ3OWloZCJ9.UMDSPN5Yyhnf7Ntei4jF-w";

    initializeMap(plotData);
    const plotPolygons = [];
    const allPlots = [...plotData];
    allPlots.forEach((area) => {
      const urnPlots = area.plots;
      urnPlots.forEach((plot) => {
        const { topLeft, topRight, bottomLeft, bottomRight } =
          plot?.gpsCoordinates;
        const polygonFeature = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [topLeft[0], topLeft[1]],
                [topRight[0], topRight[1]],
                [bottomRight[0], bottomRight[1]],
                [bottomLeft[0], bottomLeft[1]],
                [topLeft[0], topLeft[1]],
              ],
            ],
          },
          properties: {
            id: `${area.id}`,
            type: area.type,
            isAvailable: area.isAvailable,
            areaName: area.areaName,
          },
        };

        plotPolygons.push(polygonFeature);
      });
    });

    const featureCollection = {
      type: "FeatureCollection",
      features: plotPolygons,
    };

    if (
      featureCollection &&
      featureCollection.type === "FeatureCollection" &&
      plotLayerSourceLoaded.current
    ) {
      if (map.current && map.current.getSource("plotLayer")) {
        map.current.getSource("plotLayer").setData(featureCollection);
        allPlotsRef.current = allPlots;
      } else {
        console.error("Error: Map or source not initialized.");
      }
    } else {
      console.error("Error: Invalid feature collection or source not loaded.");
    }
    const urnAreaPolygons = [];
    urnPlotsArea?.forEach((areaPlot) => {
      const areaCoordinates = areaPlot?.areas?.flatMap((area) =>
        area?.ploygon.map((point) => [point.lng, point.lat])
      );

      if (areaCoordinates?.length > 0) {
        const areaPolygonFeature = {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: areaCoordinates,
          },
          properties: {
            id: `${areaPlot.id}`,
            name: areaPlot.name,
          },
        };
        urnAreaPolygons.push(areaPolygonFeature);
      }
    });

    const urnFeatureCollection = {
      type: "FeatureCollection",
      features: urnAreaPolygons,
    };

    if (
      urnFeatureCollection &&
      urnFeatureCollection.type === "FeatureCollection" &&
      plotLayerSourceLoaded.current
    ) {
      if (map.current && map.current.getSource("urnLayerSource")) {
        map.current.getSource("urnLayerSource").setData(urnFeatureCollection);
      } else {
        console.error("Error: Map or source not initialized for urn layer.");
      }
    } else {
      console.error(
        "Error: Invalid feature collection or source not loaded for urn layer."
      );
    }
  }, [plotData]);

  const initializeMap = () => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/satellite-v9",
        center: [-2.712991366, 54.27270724],
        zoom: 18.2,
        bearing: 77,
        dragRotate: false,
      });
      let marker = null;
      map.current.on("contextmenu", (e) => {
        e.preventDefault();
      });
      map.current.on("style.load", () => {
        map.current.addLayer({
          id: "plotLayer",
          type: "fill",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          },
          paint: {
            "fill-color": [
              "match",
              ["get", "type"],
              "Double Plot",
              "#f1ff05",
              "3 Family Plot",
              "#20519B",
              "4 Family Plot",
              "#f9a303",
              "5 Family Plot",
              "#B6424C",
              "#219653",
            ],
            "fill-opacity": 0.8,
            "fill-color": [
              "case",
              ["in", ["get", "areaName"], ["literal", ["Area D"]]],
              "#808080",
              ["boolean", ["get", "isAvailable"], true],
              [
                "match",
                ["get", "type"],
                "Double Plot",
                "#f1ff05",
                "3 Family Plot",
                "#20519B",
                "4 Family Plot",
                "orange",
                "5 Family Plot",
                "#B6424C",
                "#219653",
              ],
              "red",
            ],
          },
        });
        map.current.addSource("urnLayerSource", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        map.current.addLayer({
          id: "urnLayer",
          type: "line",
          source: "urnLayerSource",
          paint: {
            "line-color": "white",
            "line-width": 2,
          },
        });

        map.current.addSource("overlayLayerSource", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        map.current.addLayer({
          id: "overlayLayer",
          type: "fill",
          source: "overlayLayerSource",
          paint: {
            "fill-color": "rgba(0, 0, 0, 0.5)",
            "fill-opacity": 0,
          },
        });

        // map.current.on("click", function (e) {
        //   var coordinates = e.lngLat;

        //   // Remove the existing marker if it exists
        //   if (marker) {
        //     marker.remove();
        //   }

        //   // Create a new marker at the clicked location
        //   marker = new mapboxgl.Marker()
        //     .setLngLat(coordinates)
        //     .addTo(map.current);

        //   new mapboxgl.Popup()
        //     .setLngLat(coordinates)
        //     .setHTML('You clicked here: <br/>' + coordinates.lng + ', ' + coordinates.lat)
        //     .addTo(map.current)
        //     .on("close", () => {
        //       // Remove the marker when the popup is closed
        //       if (marker) {
        //         marker.remove();
        //         marker = null; // Reset the reference
        //       }
        //     });
        // });

        map.current.on("click", "plotLayer", (e) => {
          const clickedPlotId = e.features[0].properties.id;
          const area = allPlotsRef?.current?.find(
            (a) => a.id === clickedPlotId
          );
          if (area.areaName === "Area D") {
            new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(
                `<p style="color: red; font-weight: bold;">This area is unavailable</p>`
              )
              .addTo(map.current);
          } else if (area?.isAvailable === true) {
            if (area.type === "Single Plot") {
              let clickedPlotIds = JSON.parse(
                localStorage.getItem("plotsId") || "[]"
              );
              openPlotDetail(area);

              map.current.setPaintProperty("plotLayer", "fill-color", [
                "match",
                ["get", "id"],
                clickedPlotIds,
                "green",
                [
                  "match",
                  ["get", "type"],
                  "Double Plot",
                  "#f1ff05",
                  "3 Family Plot",
                  "#20519B",
                  "4 Family Plot",
                  "#f9a303",
                  "5 Family Plot",
                  "#B6424C",
                  "Single Plot",
                  "#219653",
                ],
              ]);
              localStorage.setItem("plotsId", JSON.stringify(clickedPlotIds));
            } else {
              openPlotDetail(area);
            }
          }
        });

        map.current.on("mousemove", "plotLayer", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: ["plotLayer"],
          });

          if (features.length > 0) {
            const hoveredPlotId = features[0].properties.id;

            const hoveredPlotData = allPlotsRef?.current?.find(
              (area) => area.id === hoveredPlotId
            );

            if (hoveredPlotData) {
              const overlayFeatures = {
                type: "FeatureCollection",
                features: hoveredPlotData.plots.map((plot) => ({
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        plot.gpsCoordinates.topLeft,
                        plot.gpsCoordinates.topRight,
                        plot.gpsCoordinates.bottomRight,
                        plot.gpsCoordinates.bottomLeft,
                        plot.gpsCoordinates.topLeft,
                      ],
                    ],
                  },
                  properties: {
                    id: hoveredPlotData.id,
                  },
                })),
              };

              map.current
                .getSource("overlayLayerSource")
                .setData(overlayFeatures);

              map.current.setPaintProperty("overlayLayer", "fill-opacity", 0.9);

              if (hoverPolygonId.current !== hoveredPlotData.id) {
                if (hoverPolygonId.current !== null) {
                  map.current.setFeatureState(
                    { source: "plotLayer", id: hoverPolygonId.current },
                    { hover: false }
                  );
                }

                hoverPolygonId.current = hoveredPlotData.id;
                map.current.setFeatureState(
                  { source: "plotLayer", id: hoverPolygonId.current },
                  { hover: true }
                );
              }
            }
          }
        });

        map.current.on("mouseleave", "plotLayer", () => {
          map.current.getCanvas().style.cursor = "";

          if (hoverPolygonId.current !== null) {
            map.current.setFeatureState(
              { source: "plotLayer", id: hoverPolygonId.current },
              { hover: false }
            );
            map.current.setPaintProperty("plotLayer", "fill-color", [
              "match",
              ["get", "id"],
              hoverPolygonId.current,
              "Double Plot",
              "#f1ff05",
              "3 Family Plot",
              "#20519B",
              "4 Family Plot",
              "#f9a303",
              "5 Family Plot",
              "#B6424C",
              "#219653",
            ]);
            hoverPolygonId.current = null;
          }

          map.current.setPaintProperty("plotLayer", "fill-opacity", 0.8);

          map.current.getSource("overlayLayerSource").setData({
            type: "FeatureCollection",
            features: [],
          });

          map.current.setPaintProperty("overlayLayer", "fill-opacity", 0);
        });

        map.current.getSource("plotLayer").on("data", () => {
          plotLayerSourceLoaded.current = true;
        });
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div
        style={{ width: "100%", height: "600px", backgroundColor: "#e6f5eb" }}
        ref={mapContainer}
      ></div>
    </>
  );
};

export default NewMap;
