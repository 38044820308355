import React from 'react';
import corebgimg from "../../assets/images/core-bg-img.png";
import CoreDetails from "./CoreDetails";
const CoreValues = () => {
  return (
    <>
    <section id="our-core-main">
        <div className="core-bg-img">
          <img src={corebgimg} alt="core bacjground image" />
        </div>
        <div className="core-content">
          <div className="container">
            <div className="row">
              <div className="core-head-col">
                <div className="core-head">
                  <h2>Our Core Values</h2>
                  <p>
                    As part of our mission, we have carefully crafted our core
                    values, which guide our actions and decisions every day:
                  </p>
                </div>
              </div>
             <CoreDetails/>
              <div className="core-footer-col">
                <div className="core-footer">
                  <p>
                    By choosing Inspired Meadows, families can be confident that
                    they are selecting a company that not only meets their needs
                    but also shares their values. Our history, mission, and
                    values are the pillars that drive us forward, shaping our
                    commitment to providing exceptional end-of-life planning
                    experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default CoreValues;
