import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import avtarPic from '../../assets/images/profile_img.png';
 
const ProfileViewer = (props) => {
    const { currentUser, submitLogout, showProfileDrop, handleShowMyProfile } = props;
    const [profile, setProfile] = useState('');
    const [userName, setUserName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState(false);

    useEffect(() => {
        if(currentUser && currentUser.email) {
            setUserName(currentUser.firstName);
            setLastName(currentUser.lastName);
            setEmail(currentUser.email)
            if(currentUser.image){
                setProfile(currentUser.image);
            }
        }

    }, [currentUser])


    const logout = ()=>{
        submitLogout()
    }

    const generateInitials = (name) => {
        const nameParts = name.split(" ");
        if (nameParts.length === 1) {
          return nameParts[0].charAt(0).toUpperCase();
        } else if (nameParts.length > 1) {
          return (
            nameParts[0].charAt(0).toUpperCase() + nameParts[nameParts.length - 1].charAt(0).toUpperCase()
          );
        }
        return "";
      };

      
    
    return (
        <Fragment>
            {(currentUser && currentUser.email) ? (
                <Fragment>
                    <div className={`profile-dropdown ${showProfileDrop ? 'profile-active' : ''}`}>
                        <div className="profile-info">
                            {currentUser?.image ? (
                                        <img src={profile} alt="profile image"/>
                                    ) : (
                                        <span className="profile-img-profile">{generateInitials(currentUser?.firstName)}</span>
                                    )}
                            <h3>{userName} {lastName}</h3>
                            <span>{email}</span>
                        </div>
                        <div className="log-out">
                            <ul>
                                <li onClick={handleShowMyProfile}><Link to="#"><i className="fa fa-user" aria-hidden="true"></i>
                                        My Profile</Link></li>
                                <li><Link className="active logout-btn" to="/" onClick={logout}><i className="fa fa-sign-out" aria-hidden="true"></i>
                                        Logout</Link></li>
                            </ul>
                        </div>
                    </div>
                </Fragment>
            ) : (<Fragment />)} 
        </Fragment>
    );
}

export default ProfileViewer;