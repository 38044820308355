import loaderClasses from './Loader.module.css';

const InlineLoader = () => {
  return (
    <div class={loaderClasses.loadingioSpinnerRolling}>
      <div class={loaderClasses.ldio}>
        <div></div>
      </div>
    </div>
  );
};

export default InlineLoader;
