import { Fragment, useEffect, useRef, useState } from 'react';
import {
  getSelectedTypeData,
  saveUrnPlots,
  selectFilteredUrnPlots,
  setIsPlotDetailModalOpen,
  setMultiplePlotDetail,
  setPlotDetail,
  setSelectedTypeData,
  setSelectedTypes,
} from '../../reducers/slices/products';
import { connect, useSelector } from 'react-redux';
import agent from '../../agent';
import NewMap from './NewMap';


const mapStateToProps = state => ({
  ...state.products.urnPlots,
});
const mapDispatchToProps = dispatch => ({
  dispatch,
  openPlotDetail: plotDetails => {
    if(plotDetails?.plots?.length > 0){
      dispatch(setMultiplePlotDetail(plotDetails));
    }else{
      dispatch(setPlotDetail(plotDetails));
    }
    dispatch(setIsPlotDetailModalOpen({ isOpen: true }));
  },
});

const UrnPlots = props => {
  const {
    activeCategory,
    dispatch,
    openPlotDetail,
  } = props;

  
  const [types, setTypes] = useState([]);
  const [urnPlotsData, setUrnPlotsData] = useState([]);
  const [selectedPlot,setSelectedPlot] = useState('');
  const [selectedTypes,setSelectedTypes] = useState(['Single Plot','Double Plot','3 Family Plot','4 Family Plot','5 Family Plot','Sold']);
  const [urnPlotsArea,setUrnPlotsArea] = useState();
  const [locations, setLocations] = useState([]);

  const [lat, setLat] = useState('');
  const [lang, setLang] = useState('');
  const [activeLocation, setActiveLocation] = useState(props?.id);
  const [loading, setLoading] = useState(true);

  const mapRef = useRef('');

  const allUrnPlots = useSelector((state)=>state?.products?.urnPlots?.data);

  useEffect(() => {
    (async () => {
      try {

        const res = await agent.common.getTypesAndLocations();
        const { status, message } = res;
        if (!status) throw new Error(message);

        setLocations(res?.data?.locations || []);
        setTypes(res?.data?.types || []);
        if (res?.data?.locations && res?.data?.locations.length)
          setActiveLocation(res?.data?.locations[0]?.locationId ?? '');
      }
      catch (err) {
        if (err instanceof Error) {
          console.error(err.message);
        } else {
          console.error(err);
        }
      }
    })();
  }, [activeCategory]);

  const handleSelectTypes = async (e) => {
    const val = e.target.value;
    const isSelected = selectedTypes?.includes(val);
    if (isSelected) {
      setSelectedTypes([...selectedTypes.filter((type) => type !== val)]); 
    }else{
     setSelectedTypes([...selectedTypes, val]);
    }
    e.target.checked = !isSelected;
  };

  useEffect(() => {
    const filterUrnPlots = () => {
      if (selectedTypes?.length === 6) {
        setUrnPlotsData(allUrnPlots);
      } else {
        const hasSoldType = selectedTypes?.includes("Sold");
        const filteredUrnPlots = allUrnPlots?.filter((plot) => {
          if (plot?.areaName === "Area C" || plot?.areaName === "Area D") {
            return true;
          }
          const isSelectedType = selectedTypes?.includes(plot?.type);
          if (!hasSoldType) {
            return isSelectedType && plot?.isAvailable === true;
          } else {
            return isSelectedType || (!isSelectedType && plot?.isAvailable === false);
          }
        });
        setUrnPlotsData(filteredUrnPlots || []);
      }
    };
  
    filterUrnPlots();
  }, [selectedTypes, allUrnPlots]);
  



  useEffect(() => {
      fetchData(selectedTypes, activeLocation);
  }, [activeLocation]);

  const fetchData = async (selectedTypes, activeLocation) => {
    try {
      setLoading(true); 
      if (selectedTypes.length > 0 && activeLocation !== "") {
        const res = await agent?.common?.getPlots('Urn Plots', selectedTypes, activeLocation);
        const { status, message } = res;
        if (!status) throw new Error(message);
        
        setUrnPlotsData(res?.data?.[0]?.plots);
        setUrnPlotsArea(res?.data?.[0]?.areas)
        dispatch(saveUrnPlots({ urnPlots: res.data?.[0]?.plots }));
      }
    } catch (err) {
      console.error(err);
    }finally {
      setLoading(false); 
    }
  };
  const getPlotColor = (plotType) => {
    switch (plotType) {
      case "Single Plot":
        return "#219653";
      case "Double Plot":
        return "#f1ff05;";
      case "3 Family Plot":
        return "#20519B";
      case "4 Family Plot":
        return "#f9a303";
      case "5 Family Plot":
        return "#B6424C";
      case "Unavailable":
        return "#808080";
      default:
        return "grey";
    }
  };
 
  return (
    <Fragment>
      <div
        className={`burials-plots ${activeCategory === 'urnPlots' ? 'category-active' : ''
          }`}
      >
        <div className='product-head'>
          {/* <h2>Urn Plots</h2> */}
          <div className='product-location'>
          {types && types.length > 0 && 
          <>
            <span>Location:</span>
            <div className='product-select'>
              <select
                value={activeLocation}
                onChange={e => {
                  setActiveLocation(e.target.value);
                }}
              >
                <option value='' disabled>
                  Select
                </option>
                {locations &&
                  locations.map(location => (
                    <option
                      key={location.locationId}
                      value={location.locationId}
                    >
                      {location.siteName}
                    </option>
                  ))}
              </select>
            </div>
          </>
          }
          </div>
          <div className='product-type'>
          {types && types.length > 0 &&<span>Type</span>}
            {types &&
              types.map((type, idx) => (
                <div key={idx} className='product-field'>
                    <input
                      id={type.toLowerCase().replaceAll(' ', '-')}
                      type='checkbox'
                      value={type}
                      onChange={handleSelectTypes}
                      checked={selectedTypes?.includes(type)}
                    />
                  <label>
                    {type}
                  </label>
                </div>
              ))}
             {types && types.length > 0 &&
              <div className='product-field'>
              <input
                    type='checkbox'
                    value="Sold"
                    className='input-box'
                    onChange={handleSelectTypes}
                    checked={selectedTypes?.includes("Sold")}
              />
               <label>
                  Sold                  
                </label>
              </div> 
             } 
          </div>
        </div>

        <div className='product-burials-map' style={{ aspectRatio: '16/9' }}>
          <NewMap
              plotData={urnPlotsData}
              getPlotColor={getPlotColor}
              selectedPlots={selectedPlot}
              activeLocation = {activeLocation}
              openPlotDetail={openPlotDetail}
              selectedTypes ={selectedTypes}
              selectedCategory={activeCategory}
              urnPlotsArea={urnPlotsArea}
              loading={loading}
              background="#e6f5eb"
            />
        </div>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UrnPlots);
