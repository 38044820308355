import React from 'react';
import missionimg from "../../assets/images/mission-img.png";

const OurMission = () => {
  return (
    <>
     <section id="our-mission">
        <div className="container">
          <div className="row">
            <div className="mission-head-col">
              <div className="mission-head">
                <p>
                  At Inspired Meadows, we have an unwavering mission, and strong
                  core values that form the foundation of who we are and what we
                  strive to achieve. With a deep commitment to providing
                  meaningful end-of-life planning solutions, we are a trusted
                  name in the industry.
                </p>
              </div>
            </div>
            <div className="mission-details-col">
              <div className="mission-details">
                <img src={missionimg} alt="mission image" />
              </div>
            </div>
            <div className="mission-details-col">
              <div className="mission-details">
                <h2>Our Mission</h2>
                <p className="p-spacing">
                  Our mission is to transform the traditional perception of
                  burials and create a seamless blend of natural beauty and
                  cherished remembrance.{" "}
                </p>
                <p>
                  Founded on the principles of compassion, sustainability, and
                  innovation, Inspired Meadows is dedicated to preserving the
                  environment while offering families a serene and sustainable
                  final resting place for their loved ones. Our team is
                  passionate about bridging the gap between traditional and
                  natural burials, ensuring that every aspect of our service
                  reflects the utmost respect and care for both the departed and
                  their families.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default OurMission;
