import React, {useState, useEffect, Fragment} from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { validate as FormValidate, ListErrorMessages } from '../../../constants/Validations';
import { UPDATE_USER_DATA, UPDATE_USER_DATA_RESET } from "../../../constants/actionTypes";
import agent from "../../../agent";
import Loader from "../../components/Loader";
import {ToastContainer, toast } from 'react-toastify';

const mapStateToProps = (state) => ({
    currentUser: state.common.currentUser,
    updateSuccess: state.common.updateSuccess,
    updateError: state.common.updateError,
});


const mapDispatchToProps = (dispatch) => ({
    onSubmitUpdate: async (cid,data) => {
        try {
            const response = await agent.Customer.updateProfile(cid,data);
            if(response?.status){
                toast.success(response?.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: UPDATE_USER_DATA,
                    payload: response,
                });
            }
           
        } catch (error) {
            if (error.response && error.response.statusCode === 400) {
                
                if(error?.response){
                    const message = error?.response?.body?.message;

                    toast.error(message, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                }                    
                // dispatch({
                //     type: UPDATE_USER_DATA,
                //     payload: JSON.parse(error.response.text),
                // });
            }
        }
    },
    onEmpty: () => { dispatch({ type: UPDATE_USER_DATA_RESET }) }
});

const ProfileDetail = (props) => {
    const { currentUser, showDetail, updateError, updateSuccess, onSubmitUpdate, onEmpty } = props
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [imageValidError, setImageValidError] = useState("");
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [show_image, setShowImage] = useState('');


    const handleChangeProfile = (event) => {
        var prflImage = event.target.files[0];
        setImageValidError('')
        if (!prflImage.name.match(/\.(jpg|jpeg|png)$/)) {
            setImageValidError("Only .png, .jpg and .jpeg format allowed!")
            return false;
        } else {
            setProfileImage(prflImage);
            setShowImage(URL.createObjectURL(prflImage));
        }
    }

    const submitBtn = async (e) => {
        e.preventDefault();
        const checkValidate = FormValidate({
            phone: { value: phone, required: true },
            name: { value: name, required: true },
            lastName: { value: lastName, required: true },
        });
        if(!checkValidate.status) {
            setErrors(null);
           
            if (imageValidError == '') {
                await submitUpdate()
            }
            
        } else {
            let values = ListErrorMessages(checkValidate.errors);
            setErrors(values);
            setInProgress(false);
        }
    } 

    const submitUpdate = async () => {
        setInProgress(true);
        const formData = new FormData();
        if(profileImage){
          formData.append("newProfileImage", profileImage);
        }else{
          formData.append("image",currentUser?.image)
        }
        const dialCode = phone.substring(0, 3);
        const phoneno = phone.substring(3);
       
            formData.append("phoneNumber", phoneno.trim());
      
        formData.append("firstName", name);
        formData.append("lastName", lastName);
        formData.append("dialCode", dialCode);
       
        if (currentUser && currentUser.id) {
            try {
                const response = await onSubmitUpdate(currentUser.id, formData);
                setInProgress(false);
                handleMessage(response);

                // window.location.reload();
              } catch (error) {
                
              }
        }
      };

    
    useEffect(() => {
        if (currentUser) {
            setName(currentUser.firstName);
            setLastName(currentUser.lastName);
            setEmail(currentUser.email);
            setShowImage(currentUser.image);
            setPhone(currentUser.phoneNumber.dialCode + " " + currentUser.phoneNumber.number);
        }
    }, [currentUser]);

    const handleMessage= () => {
        if(updateError) {
            if (typeof updateError === "string") {
                setMessage(updateError)
                onEmpty()
                setTimeout(function() {
                    setMessage(null)
                }, 2000);
            }
            
        }

    }

     const generateInitials = (name) => {
        const nameParts = name.split(" ");
        if (nameParts.length === 1) {
          return nameParts[0].charAt(0).toUpperCase();
        } else if (nameParts.length > 1) {
          return (
            nameParts[0].charAt(0).toUpperCase() + nameParts[nameParts.length - 1].charAt(0).toUpperCase()
          );
        }
        return "";
      };
    
    
    return (
        <div className={`profile-details-main ${showDetail ? 'profile-active' : ''}`} style={{display: `${showDetail ? 'block': 'none'}`}}>
            <div className="profile-details">
            {message ? <span className={(updateSuccess) ? 'text-success' : 'text-danger' }>{message}</span> : <Fragment /> }
                <div className="profile-upload">
                    <div className="profile-img">
                    {show_image ? (
                            <img src={show_image} alt=""  className="profile-initials"/>
                        ) : (
                            <div className="profile-initials">{generateInitials(name)}</div>
                        )}
                    </div>
                    <div className="profile-delete">
                        <div className="new-upload">
                            <input onChange={handleChangeProfile} type="file" value=""/>
                            <button className="upload-btn">Upload new picture</button>
                        </div>
                        {inProgress && <Loader/>}
                        {/* <button className="delete">Delete</button> */}
                    </div>
                    <span className="text-danger">{imageValidError}</span>
                </div>
                <div className="profile-info">
                    <h3>Personal information</h3>
                    <div className="profile-input">
                        <label>First name</label>
                        <input 
                            type="text" 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="First name"
                        />
                        <span className="text-danger">{errors && errors.name ? errors.name : ''}</span>
                    </div>
                    <div className="profile-input">
                        <label>Last name</label>
                        <input 
                            type="text" 
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last name"
                        />
                        <span className="text-danger">{errors && errors.lastName ? errors.lastName : ''}</span>
                    </div>
                    <div className="profile-input">
                        <label>Email</label>
                        <input 
                            type="email" 
                            value={email}
                            className="input-email"
                            placeholder="Email"
                            readOnly={true}
                        />
                    </div>
                    <div className="profile-input">
                        <label>Phone Number</label>
                        <input 
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value) }  
                            placeholder="Ex: +202 555 0111"
                        />
                        <span className="text-danger">{errors && errors.phone ? errors.phone : ''}</span>
                    </div>
                </div>
            </div>
            <div className="profile-btn">
                {/* <button className="cancel">Cancel</button> */}
                <button type="button" onClick={submitBtn} className="save">Save changes</button>
            </div>
            <ToastContainer/>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetail);