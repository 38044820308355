import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import MemorialList from './MemorialList';

import agent from '../../agent';

import {
  saveMemorialStones,
  setMemorialStonesError,
  setMemorialStonesIsLoading,
} from '../../reducers/slices/products';

const mapStateToProps = state => ({
  ...state.products.memorialStones,
});
const mapDispatchToProps = dispatch => {
  return { dispatch };
};

const MemorialStones = props => {
  const { activeCategory, isLoading, isFetched, data, error, dispatch } = props;

  useEffect(() => {
    (async () => {
      if (!isFetched) {
        try {
          dispatch(setMemorialStonesIsLoading({ isLoading: true }));

          const res = await agent.common.getMemorialStones();
          const { message, status } = res;
          if (!status) throw new Error(message);

          dispatch(saveMemorialStones({ memorialStones: res.data }));
        } catch (err) {
          if (err instanceof Error) {
            dispatch(setMemorialStonesError({ error: err.message }));
          }
        } finally {
          dispatch(setMemorialStonesIsLoading({ isLoading: false }));
        }
      }
    })();
  }, [dispatch, isFetched,activeCategory]);

  return (
    <Fragment>
      <div
        className={`burials-plots ${
          activeCategory === 'memorialStones' ? 'category-active' : ''
        }`}
      >
        <div className='product-head'>
          <h2>Memorial Plaques</h2>
        </div>
        <div className='product-items'>
          <div className='row'>
            <MemorialList data={data} error={error} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemorialStones);
