import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import MainView from "./MainView";
import SEO from "../SEO/SEO";
import useDocumentTitle from "../hooks/UseDocumentTitle";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const Blog = (props) => {
  let mainProps = {};
	useDocumentTitle("Blog | Insights on Natural and Eco-Friendly Burials")

  return (
    <div className="blog-page">
      <SEO
        title="Blog | Insights on Natural and Eco-Friendly Burials"
        description="Read insightful blogs from Inspired Meadows on sustainable end-of-life planning, legacy and memorialization."
        keywords=" Legacy and memorialization, Embracing nature in the burial process"
        href="https://www.inspiredmeadows.co.uk/blogs"
      />
     
      <MainView {...mainProps} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
