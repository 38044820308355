import { Fragment, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import agent from '../../agent';

import {
  saveBurialPlots,
  setIsPlotDetailModalOpen,
  setMultiplePlotDetail,
  setPlotDetail,
} from '../../reducers/slices/products';
import NewMap from './NewMap';

const mapStateToProps = state => ({
  ...state.products.burialPlots,
});
const mapDispatchToProps = dispatch => ({
  dispatch,
  openPlotDetail: plotDetails => {
    if (plotDetails?.plots?.length > 0) {
      dispatch(setMultiplePlotDetail(plotDetails));
    } else {
      dispatch(setPlotDetail(plotDetails));
    }
    dispatch(setIsPlotDetailModalOpen({ isOpen: true }));
  },
});

const BurialPlots = props => {
  const {
    activeCategory,
    dispatch,
    isFetched,
    isLoading,
    data,
    error,
    openPlotDetail,
  } = props;


  const [locations, setLocations] = useState([]);
  const [types, setTypes] = useState([]);

  const [activeLocation, setActiveLocation] = useState('');
  const [selectedPlot, setSelectedPlot] = useState('');
  const [selectedTypes, setSelectedTypes] = useState(['Single Plot', 'Double Plot', '3 Family Plot', '4 Family Plot', '5 Family Plot', 'Sold', 'Unavailable']);
  const [showOverlay, setShowOverlay] = useState(false);
  const [urnPlotsArea, setUrnPlotsArea] = useState();
  const [burialPlotsData, setBurialPlotsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(null);
  const mapRef = useRef('');

  const allBurialPlots = useSelector((state) => state?.products?.burialPlots.data);


  useEffect(() => {
    (async () => {
      try {
        const res = await agent.common.getTypesAndLocations();
        const { status, message } = res;
        if (!status) throw new Error(message);

        setLocations(res?.data?.locations || []);
        setTypes(res?.data?.types || []);
        if (props.id) {
          setActiveLocation(props.id);
        } else {
          if (res?.data?.locations && res?.data?.locations.length) {
            setActiveLocation(res?.data?.locations[0]?.locationId ?? '');
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.message);
        } else {
          console.error(err);
        }
      }
    })();
  }, [activeCategory]);

  const handleSelectBurialTypes = async (e) => {
    const val = e.target.value;
    const isSelected = selectedTypes?.includes(val);
    if (isSelected) {
      setSelectedTypes([...selectedTypes.filter((type) => type !== val)]);
    } else {
      setSelectedTypes([...selectedTypes, val]);
    }
    e.target.checked = !isSelected;
  };

  useEffect(() => {

    const selectFilteredBurialPlots = () => {
      if (selectedTypes?.length === 6) {
        setBurialPlotsData(allBurialPlots);
      } else {
        const hasSoldType = selectedTypes?.includes("Sold");
        if (!hasSoldType) {
          const filteredUrnPlots = allBurialPlots?.filter((plot) => plot?.isAvailable === true);
          setBurialPlotsData(filteredUrnPlots);
        } else {
         
          const filteredUrnPlots = allBurialPlots?.filter((plot) => {
            const isSelectedType = selectedTypes?.includes(plot?.type);
            return isSelectedType || (!isSelectedType && plot?.isAvailable === false);
          });
          setBurialPlotsData(filteredUrnPlots);
        }
      }
    };
    selectFilteredBurialPlots();
  }, [selectedTypes, allBurialPlots]);


  useEffect(() => {
    fetchData(selectedTypes, activeLocation);
  }, [selectedTypes, activeLocation]);

  const fetchData = async (selectedTypes, activeLocation) => {
    try {
      setLoading(true);
      if (selectedTypes.length > 0 && activeLocation !== '') {
        const res = await agent.common.getPlots('Burial Plots', selectedTypes, activeLocation);
        const { status, message } = res;
        if (!status) throw new Error(message);
        setBurialPlotsData(res?.data?.[0]?.plots);
        setUrnPlotsArea(res?.data?.[0]?.areas);
        dispatch(saveBurialPlots({ burialPlots: res.data?.[0]?.plots }));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getPlotColor = (plotType) => {
    switch (plotType) {
      case "Single Plot":
        return "#219653";
      case "Double Plot":
        return "yellow";
      case "3 Family Plot":
        return "blue";
      case "4 Family Plot":
        return "rgb(255, 186, 57)";
      case "5 Family Plot":
        return "rgb(255, 78, 107)";
      case "Unavailable":
        return "#808080";
      default:
        return "black";
    }
  };
  return (
    <Fragment>
      <div className={`burials-plots ${activeCategory === 'burialPlots' ? 'category-active' : ''}`}>
        <div className='product-head'>
          {/* <h2>Burial Plots</h2> */}

          <div className='product-location'>
            {types && types.length > 0 &&
              <>
                <span>Location:</span>
                <div className='product-select'>
                  <select
                    value={activeLocation}
                    onChange={(e) => {
                      setActiveLocation(e.target.value);
                    }}
                  >
                    <option value='' disabled>
                      Select
                    </option>
                    {locations.map((location) => (
                      <option
                        key={location.locationId}
                        value={location.locationId}
                      >
                        {location.siteName}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            }
          </div>

          <div className='product-type'>
            {types && types.length > 0 && <span>Type</span>}
            {types && types.map((type, idx) => (
              <div key={idx} className='product-field'>

                <input
                  id={type.toLowerCase().replaceAll(' ', '-')}
                  type='checkbox'
                  value={type}
                  onChange={handleSelectBurialTypes}
                  checked={selectedTypes.includes(type)}
                />
                <label htmlFor={type.toLowerCase().replaceAll(' ', '-')}>
                  {type}
                </label>
              </div>
            ))}
            {types && types.length > 0 &&
              <div className='product-field'>
                <input
                  type='checkbox'
                  value="Sold"
                  className='input-box'
                  onChange={handleSelectBurialTypes}
                  checked={selectedTypes?.includes("Sold")}
                />
                <label>
                  Sold
                </label>
              </div>
            }
            <div className='product-field'>
              <input
                type='checkbox'
                value="Unavailable"
                className='input-box-new'
                onChange={handleSelectBurialTypes}
                checked={selectedTypes.includes("Unavailable")}
              />
              <label>Unavailable</label>
            </div>
          </div>
        </div>

        <div className='product-burials-map' style={{ aspectRatio: '16/9' }}>
          <NewMap
            plotData={burialPlotsData}
            selectedTypes={selectedTypes}
            getPlotColor={getPlotColor}
            selectedPlots={selectedPlot}
            activeLocation={activeLocation}
            openPlotDetail={openPlotDetail}
            selectedCategory={activeCategory}
            urnPlotsArea={urnPlotsArea}
            loading={loading}
          />
        </div>
      </div>
    </Fragment>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(BurialPlots);
