
import purchaseImagefirst from "../../assets/images/purchase-img-first.png";
import purchaseImagesecond from "../../assets/images/purchase-img-second.png";
import purchaseImagethird from "../../assets/images/Flowers_icon.png";
import purchaseImagefour from "../../assets/images/Tree-image.jpg";
import purchaseImagefive from "../../assets/images/ARCA_Urns.jpg";
import purchaseImagesix from "../../assets/images/Coffin image.jpg";
import burialComminSoon from "../../assets/images/comingSoon.png";

export const purchaseData = [
    {
        id:0,
        image:burialComminSoon,
        val:'burialPlots',
        title:'Burial Plots'
    },
    {
        id:1,
        image:purchaseImagesecond,
        val:'urnPlots',
        title:'Urn Plots'
    },
    {
        id:2,
        image:purchaseImagethird,
        val:'memorialFlora',
        title:'Flowers'
    },
    {
        id:2,
        image:purchaseImagefour,
        val:'memorialFlora',
        title:'Trees'
    },
    // {
    //     id:3,
    //     image:purchaseImagefour,
    //     val:'memorialStones',
    //     title:'Memorial Plaques'
    // },
    // {
    //     id:4,
    //     image:purchaseImagefive,
    //     val:'urns',
    //     title:'Urns',
    // },
    // {
    //     id:5,
    //     image:purchaseImagesix,
    //     val:'Caskets',
    //     title:'Caskets'
    // }
]


