import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import agent from "../../../agent";
import { useNavigate } from 'react-router-dom';
import {
    FETCH_MY_ORDER,
} from "../../../constants/actionTypes";


const mapStateToProps = (state) => ({
    ...state,
    currentUser: state.common.currentUser,
    OrderData: state.common.OrderData,
    
});
const mapDispatchToProps = (dispatch) => ({
    onLoadOrder: async (userId) => {
      
        try {
          const response = await agent.Auth.getOrderDetail(userId);
          const data = response;
         
          dispatch({
            type: FETCH_MY_ORDER,
            payload: data,
          });
        } catch (error) {
          // Handle error here if needed
        }
      }
});

const MyOrders = (props) => {
    const navigate = useNavigate();
    const {currentUser, onLoadOrder, OrderData, showOrderPass} = props
    useEffect(()=>{
        if (currentUser && currentUser?.id) {
            onLoadOrder(currentUser?.id);
        }
        
    },[currentUser, onLoadOrder])

    useEffect(()=>{
        if (OrderData) {
        }
        
    },[])

    const sortPlots = (plotIds)=>{
        const sortedPlotNumbers = [...plotIds].sort((a, b) => {
            const numericPartA = parseInt(a.match(/\d+/)[0], 10); // Extract numeric part
            const numericPartB = parseInt(b.match(/\d+/)[0], 10);
            return numericPartA - numericPartB;
          });
          return sortedPlotNumbers
    }

    
    return (
        <div className="profile-details-main" style={{ display: `${showOrderPass ? 'block' : 'none'}` }}>
                    <div class="profile-details profile-password">
                        <div class="profile-info">
                            <h3>My Orders {OrderData?.length && (OrderData?.length)} </h3>
                            {OrderData?.length > 0 ? (
                                OrderData.map((data) => (
                            <div class="my-order">
                                <div class="order-details-main">
                                    <div class="order-img">
                                        <img src={data?.plot?.image|| data?.additionalItem?.image}  alt=""/>
                                    </div>
                                    <div class="order-details">
                                        {(data.productType == 'Burial Plots' || data.productType == 'Urn Plots') ? <span class="order-head">{data?.plot?.plotNumber?.length > 1 ? data?.productType.slice(0, -1) : data?.productType.slice(0, -1)}</span> : <span class="order-head"> {data?.productType}</span> }
                                        
                                        <div style={{display:"flex" , alignItems:"center" , gap:12}}>
                                        <span>{data?.plot?.itemType || data?.additionalItem?.itemType}</span>
                                        {
                                           (data?.productType === 'Burial Plots' || data?.productType === 'Urn Plots') &&  sortPlots(data?.plot?.plotNumber)?.map((plotId)=>{
                                                return (
                                                    <>
                                            <div className="myOrderPlotId">#{plotId}</div>
                                                    
                                                    </>
                                                )
                                            })
                                        }
                                          
                                        </div>
                                        <p>{data?.plot?.description || data?.additionalItem?.description}</p>
                                        <div class="order-price">
                                           <span class="price">
                                                {data?.plot ? null : <label>Quantity: {data?.additionalItem?.quantity}</label>}
                                                £{data?.totalPriceWithTax.toFixed(2)}
                                            </span>
                                            {data?.documentation?.status
                                             ?
                                             <span class="status">
                                                <span class="circle"></span>
                                                Completed
                                            </span>
                                             :
                                            <span class="status">
                                            <span class="circle1"></span>
                                            In-Progress
                                            </span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))
                            ) : (
                                <p>No orders till now.</p>
                            )}
                            <div class="add-visa">
                                <button onClick={() => navigate(`/products/flowers`)}><i class="fa fa-plus" aria-hidden="true"></i>
                                    Add Additional Items</button>
                            </div>
                        </div>
                    </div>
                    </div>
    )
}


export default connect(mapStateToProps, mapDispatchToProps) (MyOrders);

