import React, { useState, useEffect, Fragment,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, Link } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import backIcon from "../../assets/images/back_btn.png";
import { validate as FormValidate, ListErrorMessages } from '../../constants/Validations';
import { FORGOT_PASS, FORGOT_RESET } from "../../constants/actionTypes";
import closeBtn from "../../assets/images/times_icon.png"
import {
    setShowLogin,
    setShowRegister,
    setShowForgot,
    setVerifyOtp,
    setResetPass,
    setForgotEmail,
    setResetComplete
} from "../../reducers/slices/headerSlice";
import agent from "../../agent";
import Loader from '../components/Loader';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField';
import CustomInput from "../Common/CustomInput";


const mapStateToProps = (state) => ({
    forgotSuccess: state.common.forgotSuccess,
    forgotMessage: state.common.forgotMessage,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitForm: async (values , setEmail , setIsLoading) => {
        try {
            const response = await agent.Auth.forgotPass(values);
            dispatch({
                type: FORGOT_PASS,
                payload: response,
            });
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setEmail('')
            setIsLoading("Send OTP")
        } catch (error) {
            setIsLoading("Send OTP")
            if (error.response && error.response.statusCode === 400) {
                dispatch({
                    type: FORGOT_PASS,
                    payload: JSON.parse(error.response.texterror.response.text),
                });
                toast.error(error.response.text, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error("No User Found", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }
});

const ForgotPassword = (props) => {
    const { showForgot, handleCloseForgot, onSubmitForm, forgotMessage, forgotSuccess } = props
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState(null);
    const [emailValue, setEmailValue] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState("Send OTP");

    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    const submitBtn = (e) => {
        e.preventDefault();
        const checkValidate = FormValidate({
            email: { value: email, email: true, required: true }
        });
        if (!checkValidate.status) {
            setErrors(null);
            submitHandle()
            // setEmail('');
            setErrors('');
           
        } else {
            const values = ListErrorMessages(checkValidate.errors);
            
            toast.error("Please enter email", {
                position: toast.POSITION.TOP_RIGHT
            });
            setEmail('');
        }
    }

    const submitHandle = () => {
        setIsLoading("Loading...")
        var sendData = {
            "email": email,
        }
        onSubmitForm(sendData , setEmail , setIsLoading);
        setEmailValue(email);
        handleForgotMessage(email);
    };

    const handleShowVerify = () => {
        dispatch(setShowLogin(false));
        dispatch(setShowRegister(false));
        dispatch(setResetPass(false));
        dispatch(setShowForgot(false));
        dispatch(setVerifyOtp(true));
        dispatch(setResetPass(false));
        dispatch(setResetComplete(false));
    }
  

    const handleForgotMessage = (email) => {
        if (forgotMessage) {
          setMessage(forgotMessage);
          setInProgress(false); 
          if (typeof forgotMessage === "string") {
            dispatch({
              type: FORGOT_RESET,
            });
            if (forgotSuccess) {
              setTimeout(function () {
                setMessage(null);
                dispatch(setForgotEmail(emailValue));
                handleShowVerify();
              }, 2000);
            } else {
              setTimeout(function () {
                setMessage(null);
              }, 4000);
            }
          } 
        }
      };
      
      useEffect(() => {
        handleForgotMessage(email);
      }, [forgotMessage, forgotSuccess]);

    const handleShowLogin = () => {
        dispatch(setShowLogin(true))
        dispatch(setShowRegister(false));
        dispatch(setResetPass(false));
        dispatch(setShowForgot(false));
        dispatch(setVerifyOtp(false));
        dispatch(setResetPass(false))
        dispatch(setResetComplete(false));
    }
    function onEmailChangeHandler (e){
        setEmail(e.target.value)
      }
    return (
        <Fragment>
            <div
                style={{ position: 'fixed' }}
                className={`overlay ${showForgot ? 'register-active' : ''}`}
            />

            <div className={`login-main register-main ${showForgot ? 'register-active' : ''}`}>
                <form>
                    <div className="logo">
                        <div  className="logo-link"><img src={logo} alt="Logo" /></div>
                        <span onClick={handleCloseForgot} className="overlay_forgot">
                            <img src={closeBtn} alt="closeBtn" />
                        </span>
                    </div>
                    {inProgress && <Loader/>}
                    <div className="login-field">
                        <h2>Forgot password?</h2>
                        <p>No problem. Just enter your email address below -
                            we’ll send you a OTP to reset it.</p>
                        {/* {message ? <span className={(forgotSuccess) ? 'text-success' : 'text-danger'}>{message}</span> : <Fragment />} */}
                        <div className="input-field">
                        <CustomInput type={"email"} value={email} label={"Your email address"} handleChange={onEmailChangeHandler}/>
                       
                            {/* <span className="text-danger">{errors && errors.email ? errors.email : ''}</span> */}
                        </div>
                        <div className="login-btn">
                            <a
                                className="login-button btn btn-md btn-primary forgot-link w-100 mb-3 orange-btn-border"
                                type="button"
                                disabled={inProgress}
                                onClick={submitBtn}
                            >
                               {isLoading}
                            </a>
                        </div>
                        <div className="back-login forgot-back">
                            <div className="back-login" to="#" onClick={handleShowLogin}><img src={backIcon} alt="Back icon" />Back to login</div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);