import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import MemorialList from "./MemorialList";

import agent from "../../agent";

import {
  saveUrnsAndCaskets,
  setUrnsAndCasketsError,
  setUrnsAndCasketsIsLoading,
} from "../../reducers/slices/products";

const mapStateToProps = (state) => ({
  ...state.products.urnsAndCaskets,
});
const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const UrnsAndCaskets = (props) => {
  const [selectedTypes, setSelectedTypes] = useState(["Urn", "Casket"]);
  const { activeCategory, isLoading, isFetched, data, error, dispatch } = props;

  const handleSelectTypes = async (event) => {
    const selectedType = event.target.value;
    setSelectedTypes(selectedType);
    if (event.target.checked) {
      setSelectedTypes((prevSelectedTypes) => [
        ...prevSelectedTypes,
        selectedType,
      ]);
    }
    await fetchData(selectedType);
  };

  useEffect(() => {
    if (!isFetched) {
      fetchData(selectedTypes);
    }
  }, [dispatch, isFetched, activeCategory]);

  const fetchData = async (selectedType) => {
    if (selectedType === "All") {
      selectedType = ["Urn", "Casket"]
    }
    try {
      dispatch(setUrnsAndCasketsIsLoading({ isLoading: true }));
      const res = await agent.common.getUrnsAndCaskets(selectedType);
      const { message, status } = res;
      if (!status) throw new Error(message);
      dispatch(saveUrnsAndCaskets({ memorialStones: res.data }));
    } catch (err) {
      if (err instanceof Error) {
        dispatch(setUrnsAndCasketsError({ error: err.message }));
      }
    } finally {
      dispatch(setUrnsAndCasketsIsLoading({ isLoading: false }));
    }
  };

  const typeData = [
    { type: "All", val: "All" },
    { type: "Urn", val: "Urn" },
    { type: "Casket", val: "Casket" },
  ];

  return (
    <Fragment>
      <div
        className={`burials-plots ${activeCategory === "urnsAndCaskets" ? "category-active" : ""
          }`}
      >
        <div className="product-head">
          <h2>Urns and Caskets</h2>
          <div className='product-type'>
            <span>Type:</span>
            {typeData &&
              typeData.map((type, idx) => (
                <div key={idx} className="product-field">
                  <input
                    id={type.type.toLowerCase().replaceAll(" ", "-")}
                    type="checkbox"
                    className="rounded-checkbox"
                    value={type?.val}
                    onChange={handleSelectTypes}
                    checked={selectedTypes.includes(type?.type)}
                  />
                  {/* <span class="checkmark"></span> */}
                <label htmlFor={type.type.toLowerCase().replaceAll(" ", "-")}>
                  {type?.type}
                </label>

                </div>
              ))}
          </div>

        </div>
        <div className="product-items">
          <div className="row">
            <MemorialList data={data} isLoading={isLoading} error={error} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UrnsAndCaskets);
