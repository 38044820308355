import React from 'react';
import { Link } from "react-router-dom"
import msgbox from "../../assets/images/msg-box.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";

const AdviserBar = () => {
  return (
    <>
    <section id="Adviser-main">
        <div className="future-planing overview-future-planing">
          <div className="container">
            <div className="row">
              <div className="talk-adviser-col">
                <div className="talk-adviser">
                  <div className="msg-img">
                    <img src={msgbox} alt="message box" />
                  </div>
                  <div className="adviser-head">
                    <h3>Speak to an Advisor </h3>
                    <p>
                      For more information please contact us for a no obligation
                      call. Please click on SCHEDULE A CALL button
                    </p>
                  </div>
                  <div className="adviser-contact">
                    <Link to="/schedule-a-call">
                      Schedule a Call <img src={anglerighticon} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default AdviserBar;
