import React from 'react';
import core_first from "../../assets/images/core_first.png";
import core_second from "../../assets/images/core_second.png";
import core_three from "../../assets/images/core_three.png";
import core_four from "../../assets/images/core_four.png";
import core_five from "../../assets/images/core_five.png";

const coreData = [
  {
    className: "core-details-col",
    content: {
      className: "core-details",
      img: {
        src: core_first,
        alt: ""
      },
      span: "Sustainability",
      p: "We are committed to preserving and enhancing the natural environment, creating burial sites that harmoniously coexist with nature."
    }
  },
  {
    className: "core-details-col",
    content: {
      className: "core-details",
      img: {
        src: core_second,
        alt: ""
      },
      span: "Excellence",
      p: "We strive for excellence in all aspects of our service, from the quality of our burial sites to the support we offer our customers."
    }
  },
  {
    className: "core-details-col",
    content: {
      className: "core-details",
      img: {
        src: core_three,
        alt: ""
      },
      span: "Integrity",
      p: "We believe our passion for supporting the natural wildlife habitat whilst complimenting the beauty of the surrounding landscape ensues a serene and peaceful final resting place."
    }
  },
  {
    className: "core-achieve-col",
    content: {
      className: "core-achieve",
      img: {
        src: core_four,
        alt: ""
      },
      span: "Compassion",
      p: "We approach our work with empathy and understanding, supporting families during their time of loss and providing them with a sense of comfort and peace."
    }
  },
  {
    className: "core-achieve-col",
    content: {
      className: "core-achieve",
      img: {
        src: core_five,
        alt: ""
      },
      span: "Innovation",
      p: "We embrace innovation and continually explore new ways to improve the end-of-life planning process, ensuring that our services remain at the forefront of the industry."
    }
  }
];

const CoreDetails = () => {
  return (
    <div className="core-container">
      {coreData.map((item, index) => (
        <div key={index} className={item.className}>
          <div className={item.content.className}>
            <img src={item.content.img.src} alt={item.content.img.alt} />
            <span>{item.content.span}</span>
            <p>{item.content.p}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CoreDetails;
