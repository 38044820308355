import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import MainView from './MainView';
import SEO from '../SEO/SEO';
import useDocumentTitle from '../hooks/UseDocumentTitle';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const FAQ = (props) => {
	let mainProps = {  };
	useDocumentTitle("Burial FAQs - Natural and Eco-Friendly Funeral services")
	return (
		<div className="faq-page">
			<SEO
			title="Burial FAQs - Natural and Eco-Friendly Funeral services"
			description="Find answers to your questions about eco-friendly burials, natural burial sites, and pre-planning for end-of-life."
			keywords="Eco-friendly burials, Natural burial sites, Pre-planning for end-of-life"
			href="https://www.inspiredmeadows.co.uk/faqs"
      />
	  {/* <link rel="canonical" href="https://www.inspiredmeadows.co.uk/faqs"/> */}
			<MainView {...mainProps} />
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);