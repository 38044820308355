import React, { useState, useEffect, Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import backIcon from "../../assets/images/back_btn.png";
import { VERIFY_OTP, VERIFY_OTP_RESET, FORGOT_PASS, REGISTER, LOGIN } from "../../constants/actionTypes";
import OTPInput, { ResendOTP } from "otp-input-react";
import agent from "../../agent";
import closeBtn from "../../assets/images/times_icon.png"
import Loader from "../components/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setIsLoggedIn, setIsOtpVisible, setOtpSuccessNew } from "../../reducers/slices/headerSlice";

const mapStateToProps = (state) => ({
    otpSuccess: state.common.otpSuccess,
    otpMessage: state.common.otpMessage,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitForm: async (values) => {
        try {
            const response = await agent.Auth.verifyOtp(values);
            dispatch({
                type: VERIFY_OTP,
                payload: response,
            });
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (error) {
            if (error.response && error.response.statusCode === 400) {
                dispatch({
                    type: VERIFY_OTP,
                    payload: JSON.parse(error.response.text),
                });
                const errorResponse = JSON.parse(error.response.text);
                const errorMessage = errorResponse.message;
               
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                // Handle other errors if needed
            }
        }
    },
    onSubmit: async (values , setOTP) => {
        try {
            const response = await agent.Auth.signUp(values);
            localStorage.setItem("token", response?.token)
            dispatch({
                type: REGISTER,
                payload: response,
            });
            dispatch(setIsLoggedIn(true));
            dispatch(setOtpSuccessNew(true));
            localStorage.setItem("cartData", 0);
            toast.success("Registration successful!", {
                position: toast.POSITION.TOP_RIGHT
            });
            setOTP('')

            return {
                statusCode: response.status,
            };
        } catch (error) {
            if (error.response && error.response.statusCode === 400) {
                const responseJson = JSON.parse(error.response.text);
                const errorMessage = responseJson.message;
                dispatch({
                    type: REGISTER,
                    payload: JSON.parse(error.response.text),
                });
                dispatch(setOtpSuccessNew(false));
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {

                // Handle other errors if needed
            }
        }
    },

    onSubmitResend: async (values) => {
        try {
            const response = await agent.Auth.forgotPass(values);
            dispatch({
                type: FORGOT_PASS,
                payload: response,
            });
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (error) {
            if (error.response && error.response.statusCode === 400) {
                dispatch({
                    type: FORGOT_PASS,
                    payload: JSON.parse(error.response.text),
                });
                // toast.error(error.message, {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            } else {
                // Handle other errors if needed
            }
        }
    },
    onRegisterResend: async (values) => {
        try {
            const response = await agent.Auth.signUpVerify(values);
            const {status , message} = await response;
            if(status){
                toast.success(message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

        } catch (error) {
            if (error.response && error.response.statusCode === 400) {
                const responseJson = JSON.parse(error.response.text);
                const errorMessage = responseJson.message;
                dispatch({
                    type: REGISTER,
                    payload: JSON.parse(error.response.text),
                });
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                // Handle other errors if needed
            }
        }
    }
});



const VerifyOtp = (props) => {
    const { handleShowResetPass, handleCloseVerify, onSubmitForm, handleShowLogin, otpSuccess, otpMessage, onRegisterResend, forgotEmail, showVerifyOtp, onSubmitResend, onSubmit } = props
    const { isOtpVisible, registrationData, otpSuccessNew } = useSelector((state) => state.header);
    const [OTP, setOTP] = useState("");
    const [message, setMessage] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const dispatch = useDispatch();




    const showLogin = ()=>{
        setOTP("")
        handleShowLogin()
    }


    const closeOtpPopup = ()=>{
        setOTP("")
        handleCloseVerify()
    }
    

    const submitHandle = async () => {
        setInProgress(true);
        const sendData = {
            "email": forgotEmail,
            "otp": OTP
        }
        const data = { ...registrationData, otp: OTP }
        if (isOtpVisible) {
            try {
                await onSubmit(data , setOTP);
                setInProgress(false);
            } catch (error) {
                setInProgress(false);
            }
        } else {
            await onSubmitForm(sendData);
        }

    };

    const submitResendBtn = () => {
        let sendData = {
            "email": forgotEmail,
        }
        let verifyData = {
            dialCode: registrationData?.phoneNumber?.dialCode,
            email: registrationData?.email,
            phoneNumber: registrationData?.phoneNumber?.number,
        };
        if (isOtpVisible) {
            onRegisterResend(verifyData);
        } else {
            onSubmitResend(sendData);
        }
    };

    useEffect(() => {
        if (otpSuccessNew && isOtpVisible) {
            setInProgress(false);
            closeOtpPopup();
            dispatch(setIsOtpVisible(false));
            dispatch({
                type: VERIFY_OTP_RESET,
            });
        } else {
            setOTP('');
            setInProgress(false);
        }
    }, [otpSuccessNew]);

    useEffect(() => {
        if (otpMessage) {
            if (typeof otpMessage === "string") {
                setMessage(otpMessage)
                setInProgress(false);
                dispatch({
                    type: VERIFY_OTP_RESET,
                });
                if (otpSuccess) {
                    setOTP('');
                    setMessage(null)
                    setInProgress(false);
                    closeOtpPopup();
                    handleShowResetPass();
                } else {
                    setTimeout(function () {
                        setMessage(null);
                        setInProgress(false);
                    }, 2000);
                }
            }
        }
    }, [otpMessage, otpSuccess]);

    const renderTime = () => React.Fragment;

    return (
        <Fragment>
            <div
                style={{ position: 'fixed' }}
                className={`overlay ${showVerifyOtp ? 'mail-active' : ''}`}
            />

            <div className={`login-main verify-mail-main ${showVerifyOtp ? 'mail-active' : ''}`}>
                <form>
                    <div className="logo">
                        <div className="logo-link"><img src={logo} alt="Logo" /></div>
                        <span onClick={closeOtpPopup}>
                            <img src={closeBtn} alt="CloseBtn" />
                        </span>
                    </div>

                    {inProgress && <Loader />}
                    <div className="login-field">
                        <h2>Verify your email</h2>
                        <p>We sent a code to {isOtpVisible ? registrationData?.email : forgotEmail}</p>
                        {/* {message ? <span className={(otpSuccess) ? 'text-success' : 'text-danger'}>{message}</span> : <Fragment />} */}
                        <div className="input-field">
                            <div className="verify-otp custom-otp-input">
                                <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" />
                            </div>
                        </div>
                        <div className="login-btn">
                            <div
                                className="login-button btn btn-md btn-primary confirm-btn w-100 mb-3 orange-btn-border"
                                type="button"
                                disabled={inProgress}
                                onClick={submitHandle}
                            >
                                Confirm
                            </div>
                        </div>
                        <div className="log-footer for-verify">
                            <p>Not received your code?<ResendOTP maxTime={1} renderTime={renderTime} onResendClick={submitResendBtn} /></p>
                        </div>
                        <div className="back-login">
                            <div className="back-login-btn" onClick={showLogin}><img src={backIcon} alt="Back icon" />Back to login</div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);