import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const EmptyView = () => {
  return (
    <Fragment>
      <section id='cart-empty-main'>
        <div className='container'>
          <div className='cart-empty'>
            <h2>
              Your Cart is <span>Empty!</span>
            </h2>
            <p>Must add item on the cart before you proceed to check out.</p>
            <Link to='/products'>Purchase Now</Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default EmptyView;
