import React from "react";
import { useNavigate , Link } from "react-router-dom";
import Learn from "../../assets/images/learrn_img.png";
import Arrow_left from "../../assets/images/arrow_left.png";
import { Fragment } from "react";
import msgbox from "../../assets/images/msg-box.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import SEO from "../SEO/SEO";


const LearnMore = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    }
    return (
        <Fragment>
            <SEO
            title="Inspired Meadows | Learn More"
            />
            <section id="learn-overview" class="learn-inspired">
                <div class="container">
                    <div class="row">
                        <div class="overview-col">
                            <div class="overview-content">
                                <div className="back-link" onClick={handleClick}><img src={Arrow_left} alt="" /> Go back</div>
                                <h1>Why Inspired Meadows?</h1>
                                <div class="terms-update">
                                    <h3>The Importance of Planning Ahead </h3>
                                    <p>As populations grow and urban spaces expand, the traditional ways of laying our loved
                                        ones to rest are under strain. Recent headlines highlighting the scarcity of burial
                                        space and practices such as second-hand graves or interring multiple bodies in one
                                        plot have understandably raised concerns for many. </p>
                                </div>
                                <div class="services-use services-inspired">
                                    <h3>Benefits of Natural Burial with Inspired Meadows: </h3><br />
                                    <ul>
                                        <li>
                                            <p class="mb"><span class="terms-heading">Sustainability:</span> Natural burial
                                                spaces, like Inspired Meadows, focus on the environment. We ensure the
                                                grounds are maintained sustainably, promoting local wildlife and
                                                greenery—the deceased return to the earth naturally, reducing environmental
                                                impact. </p>
                                        </li>
                                        <li>
                                            <p class="mb"><span class="terms-heading">Dignity and Respect:</span>&nbsp;In a world
                                                where burial spaces are becoming scarce, owning a memorial garden ensures
                                                that your loved ones aren't disturbed or displaced in the future. </p>
                                        </li>
                                        <li>
                                            <p class="mb"><span class="terms-heading">Family Unity: </span> Our bespoke
                                                burial space allows families to rest together, preserving the bond even
                                                after death. </p>
                                        </li>
                                        <li>
                                            <p class="mb"><span class="terms-heading">Permanent Peace:</span>  Unlike some
                                                places, we promise it will remain yours forever once you choose your resting
                                                place with us. There's no risk of the space being reused for someone else.
                                            </p>
                                        </li>
                                        <li>
                                            <p><span class="terms-heading">Personalized Resting Place:</span>
                                            &nbsp;With Inspired Meadows, you can personalise how you and your loved ones are
                                                remembered. The memorial garden is an extension of your legacy. </p>
                                        </li>
                                        <li>
                                            <p><span class="terms-heading">Unrestricted Visits:</span>
                                            &nbsp;We believe in maintaining connections. Your family can always access the
                                                memorial garden, offering them a place to reminisce, mourn, and celebrate
                                                your life. </p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="terms-update term-inspired-info">
                                    <h3>A Solution for the Future: </h3>
                                    <p>Whilst it might be uncomfortable to ponder our mortality, planning for it ensures our
                                        wishes are honoured and our legacy preserved. Inspired Meadows offers more than just
                                        a burial space; it provides peace of mind. By confirming your right to burial with
                                        us, you are securing your part in a vision where nature and remembrance go hand in
                                        hand. Let's change the narrative around burial practices and move towards a more
                                        sustainable and respectful future together. Join us in making a difference for
                                        generations to come. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="Adviser-main">
                <div className="future-planing overview-future-planing">
                    <div className="container">
                        <div className="row">
                            <div className="talk-adviser-col">
                                <div className="talk-adviser">
                                    <div className="msg-img">
                                        <img src={msgbox} alt="message box" />
                                    </div>
                                    <div className="adviser-head">
                                        <h3>Speak to an Advisor</h3>
                                        <p>For more information please contact us for a no obligation call. Please click on SCHEDULE A CALL button</p>
                                    </div>
                                    <div className="adviser-contact">
                                        <Link to="/schedule-a-call">Schedule a Call <img src={anglerighticon} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default LearnMore;