import React, { Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import Learn from "../../assets/images/learrn_img.png";
import Arrow_left from "../../assets/images/arrow_left.png";
import msgbox from "../../assets/images/msg-box.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import SEO from "../SEO/SEO";


const OverviewMeadows = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    }
    return (
        <Fragment>
            <SEO
            title="Inspired Meadows | Overview"
            />
            <section id="learn-overview" class="learn-inspired">
                <div class="container">
                    <div class="row">
                        <div class="overview-col">
                            <div class="overview-content">
                                <div className="back-link" onClick={handleClick}><img src={Arrow_left} alt="" /> Go back</div>
                                <h1>Overview of Inspired Meadows</h1>
                                <img src={Learn} alt="" />
                                <div class="terms-update term-inspired-info">
                                    <p>Welcome to Inspired Meadows, a pioneering company that is redefining the concept of
                                        end-of-life planning. At Inspired Meadows, we believe that the final journey should be a
                                        beautiful and meaningful experience, not only for the departed but also for their loved
                                        ones. Our mission is to create serene and environmentally-conscious natural burial sites
                                        that offer solace, reflection, and a lasting legacy.</p>
                                    <p>With a deep understanding of the emotional and practical aspects surrounding end-of-life
                                        decisions, we have carefully crafted Inspired Meadows to be a trusted and compassionate
                                        resource. We offer a unique approach that bridges the gap between traditional and
                                        natural burials, providing a harmonious balance that resonates with individuals seeking
                                        a more sustainable and personalized farewell.</p>
                                    <p>Our commitment to eco-friendliness sets us apart. We design and maintain stunning gardens
                                        of remembrance that harmonize with nature, ensuring that each burial site becomes a
                                        serene sanctuary. By embracing environmentally-friendly practices such as tree planting
                                        and conservation efforts, we create a legacy that will positively impact future
                                        generations.</p>
                                    <p>At Inspired Meadows, we understand the importance of preserving memories and ensuring
                                        peace of mind for families. Our team of dedicated professionals is here to guide you
                                        through the entire process, offering support, empathy, and expertise. We are proud to be
                                        a trusted partner, assisting you in making informed decisions that honour the
                                        individuality of each life celebrated.</p>
                                    <p>As you navigate our website, you will discover a wealth of information, resources, and
                                        opportunities to explore our services. From detailed descriptions of our natural burial
                                        sites to the ability to schedule a personalized video call with our compassionate team,
                                        we strive to provide a seamless and comprehensive experience.</p>
                                    <p>Thank you for considering Inspired Meadows as your trusted partner in end-of-life
                                        planning. We invite you to embark on this journey with us, where compassion,
                                        sustainability, and reverence come together to create a lasting legacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Adviser-main">
                <div className="future-planing overview-future-planing">
                    <div className="container">
                        <div className="row">
                            <div className="talk-adviser-col">
                                <div className="talk-adviser">
                                    <div className="msg-img">
                                        <img src={msgbox} alt="message box" />
                                    </div>
                                    <div className="adviser-head">
                                        <h3>Speak to an Advisor</h3>
                                        <p>For more information please contact us for a no obligation call. Please click on SCHEDULE A CALL button</p>
                                    </div>
                                    <div className="adviser-contact">
                                        <Link to="/schedule-a-call">Schedule a Call <img src={anglerighticon} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default OverviewMeadows;