export const calculateTotalMaintenancePrice = (data , onePlotMaintenancePrice)=>{
    let price = 0
        for(let plot of data){
            if((plot?.productType === "Urn Plots") || (plot?.productType === "Burial Plots") ){
            price += plot?.plotData?.plots?.length * onePlotMaintenancePrice
    }
        }
    return price
}

export function getFormattedDate(date) {
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return `${day}-${month}-${year}`;
  }