import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import overview from "../../assets/images/compress/overview-img-c.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import ImageLoading from '../Common/skeleton/ImageLoading';

const LazyImage = React.lazy(() => import("../Common/Optemize/LazyImage")); 


const Overview = () => {
  return (
    <>
     <section id="overview-main">
        <div className="container">
          <div className="row">
            <div className="overview-img-col">
              <div className="overview-img">
                <h2>Overview of Inspired Meadows</h2>

                <Suspense fallback={<ImageLoading/>}>
                <LazyImage src={overview} alt="overview image"/>
                </Suspense>
               
              </div>
            </div>
            <div className="overview-text-col">
              <div className="overview-text">
                <p className="p-spacing">
                  Welcome to Inspired Meadows, a pioneering company that is
                  redefining the concept of end-of-life planning. At Inspired
                  Meadows, we believe that the final journey should be a
                  beautiful and meaningful experience, not only for the departed
                  but also for their loved ones. Our mission is to create serene
                  and environmentally-conscious   natural burial sites that offer
                  solace, reflection, and a lasting legacy.
                </p>
                <p>
                  With a deep understanding of the emotional and practical
                  aspects surrounding end-of-life decisions, we have carefully
                  crafted Inspired Meadows to be a trusted and compassionate
                  resource.{" "}
                </p>
              </div>
              <div className="overview-btn">
                <Link to="/overview">
                  Learn More
                  <img
                  alt={"overview image"}
                  src={anglerighticon} 
                />
                  
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default Overview;
