import React from 'react'
import times_icon from "../../assets/images/times_icon.png"
import logo_circle from "../../assets/images/circle_logo.png"
import CustomInput from './CustomInput'

const Visiter = ({crossHandle , showGiftPopup}) => {
  return (
    <div className='overlay-visited'>
    <div className="visited-main">
        <button className="close-btn" onClick={crossHandle}><img src={times_icon} alt="" /></button>
        <div className="logo">
            <img src={logo_circle} alt="" />
        </div>
        <h2>Thank you for visiting Inspired Meadows.</h2>
            <p>To receive your FREE GIFT <div className='click-link' onClick={showGiftPopup} style={{cursor:"pointer"}} >click here</div></p>
            {/* <div className="input-group">
                <div className="input-field">
                    <CustomInput label={"First Name"} name="firstName" type={"text"} /> */}
                    {/* <input type="text" placeholder="First Name" /> */}
                {/* </div>
                <div className="input-field">
                <CustomInput label={"Last Name"} name={"lastName"} type={"text"}/> */}
                    {/* <input type="text" placeholder="Last Name" /> */}
                {/* </div>
            </div>
            <div className="input-group">
                <div className="input-field email-field">
                <CustomInput label={"Email addres"} name={"email"} type="email"/> */}
                    {/* <input type="email" placeholder="Email address" /> */}
                {/* </div>
            </div> */}
            {/* <button className="submit-btn" onClick={crossHandle}>Submit</button> */}
    </div>
    
    </div>
  )
}

export default Visiter