import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import location_bg_img from "../../assets/images/Location_banner.png";
import Location_first from "../../assets/images/Location_first.png";
import angleicon from "../../assets/images/angle-icon.png";
import defaultImg from "../../assets/images/Default_Load.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import location_img from "../../assets/images/Location_details_img.png";

import agent from "../../agent";

import {
    FETCH_ALL_LOCATIONS,
} from "../../constants/actionTypes";
import InlineLoader from '../components/InlineLoader';
import Loader from '../components/Loader';

const mapStateToProps = (state) => ({
    ...state,
    allLocations: state.common.allLocations,
});

const mapDispatchToProps = (dispatch) => ({
    onLoadPage: async () => {
        try {
            const response = await agent.common.getAllLocations();
            const data = response; 
            dispatch({
                type: FETCH_ALL_LOCATIONS,
                payload: data,
            });
        } catch (error) {
            // Handle error here if needed
        }
    }
});

const MainView = (props) => {
    const { allLocations, onLoadPage } = props
    const [locations, setLocations] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const maxLength = 250;
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                await onLoadPage();
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (allLocations) {
            setLocations(allLocations)
        }
    }, [allLocations])



    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1
                }
            },

            {
                breakpoint: 575,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1
                }
            }
        ]
    };


    return (
        <Fragment>

            {isLoading ? (
                <div><Loader /></div>
            ) : (
                <>
                    <section id="banner-img-main">
                        <div className="custom-banner-img">
                            <img src={location_bg_img} alt="banner-img" />
                        </div>
                        <div className="custom-banner-text">
                            <div className="container">
                                <h1> Our Urn Plot Locations</h1>
                            </div>
                        </div>
                    </section>

                    <section id="location-main">
                        <>
                            <div className="container">
                                <div class="location-desk">
                                    <div className="row">
                                        <>
                                            {(locations && locations.length) ?
                                                locations.map((location, i) => (
                                                    <Fragment key={i}>
                                                        <div className="location-details-col">
                                                            <div className="location-details">
                                                                <img
                                                                    // src={(location.images && location.images.length && location.images[0] != "") ? location.images[0] : Location_first}
                                                                    src={location_img}
                                                                    alt={defaultImg}
                                                                    onError={(e) => {
                                                                        e.target.src = defaultImg;
                                                                    }} />
                                                                <div className="location-text">
                                                                    <span>{location.siteName}</span>
                                                                   
                                                                    <p>{(location.description && location.description.length > maxLength) ? location.description.substring(0, maxLength) : location.description}</p>
                                                                    <Link to={`/location/${location?.slugSiteName}`}>Read More <img src={angleicon} alt="angle icon" /></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ))
                                                : null
                                            }
                                        </>
                                    </div>
                                </div>
                                <div class="location-mobile">
                                    <div class="row">
                                        <div class="location-slider">
                                            <div className="slide">
                                                <Slider {...settings}>
                                                    {(locations && locations.length) ?
                                                        locations.map((location, i) => (
                                                            <Fragment key={i}>
                                                                <div>
                                                                    <div className="location-details-col">
                                                                        <div className="location-details">
                                                                            <img
                                                                                // src={(location.images && location.images.length && location.images[0] != "") ? location.images[0] : Location_first}
                                                                                src={location_img}
                                                                                alt={defaultImg}
                                                                                onError={(e) => {
                                                                                    e.target.src = defaultImg;
                                                                                }} />
                                                                            <div className="location-text">
                                                                                <span>{location.siteName}</span>
                                                                                <p>{(location.description && location.description.length > maxLength) ? location.description.substring(0, maxLength) : location.description}</p>
                                                                                <Link to={`/location/${location?.slugSiteName}`}>Read More <img src={angleicon} alt="angle icon" /></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        ))
                                                        : null
                                                    }
                                                </Slider>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </>
                    </section>
                </>
            )}
        </Fragment>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MainView);