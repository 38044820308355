import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import goBackImg from "../../assets/images/arrow_left.png";
import SEO from "../SEO/SEO";

const TermsAndCondition = (props) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous page.
  };
  return (
    <Fragment>
      <SEO
				title="Inspired Meadows | TermsAndCondition"
				
       />
      <section id="terms-main">
        <div class="container">
          <div class="row">
            <div class="terms-col">
              <div class="terms-details">
                <div class="back-btn" onClick={handleGoBack}>
                  <Link to="#">
                    <img src={goBackImg} alt="arrow left icon" />
                    Go back
                  </Link>
                </div>

                <div class="our-term">
                  <h1>Terms of Use </h1>

                  <p>
                    Please read these terms of use ("terms of use", "agreement")
                    carefully before using Inspired Meadows Ltd website
                    (“website”, "service") operated by Inspired Meadows Ltd
                    ("us", 'we", "our").
                  </p>
                </div>

                <div class="terms-update">
                  <h3>Conditions of use </h3>

                  <p>
                    By using this website, you certify that you have read and
                    reviewed this Agreement and that you agree to comply with
                    its terms. If you do not want to be bound by the terms of
                    this Agreement, you are advised to leave the website
                    accordingly. Inspired Meadows Ltd only grants use and access
                    of this website, its products, and its services to those who
                    have accepted its terms.{" "}
                  </p>

                  {/* <p class="mb">
                    Temporibus autem quibusdam et aut officiis debitis aut rerum
                    necessitatibus saepe eveniet ut et voluptates repudiandae
                    sint et molestiae non recusandae. Itaque earum rerum hic
                    tenetur a sapiente delectus, ut aut reiciendis voluptatibus
                    maiores alias consequatur aut perferendis doloribus
                    asperiores repellat.
                  </p> */}

                  {/* <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem.
                  </p> */}
                </div>
                <div class="terms-update">
                  <h3>Privacy policy</h3>

                  <p>
                    Before you continue using our website, we advise you to read
                    our privacy policy regarding our user data collection. It
                    will help you better understand our practices.{" "}
                  </p>
                </div>
                <div class="terms-update">
                  <h3>Age restriction </h3>

                  <p>
                    You must be at least 18 (eighteen) years of age before you
                    can use this website. By using this website, you warrant
                    that you are at least 18 years of age and you may legally
                    adhere to this Agreement. Inspired Meadows Ltd assumes no
                    responsibility for liabilities related to age
                    misrepresentation.{" "}
                  </p>
                </div>
                <div class="terms-update">
                  <h3>Intellectual property </h3>

                  <p>
                    You agree that all materials, products, and services
                    provided on this website are the property of Inspired
                    Meadows Ltd, its affiliates, directors, officers, employees,
                    agents, suppliers, or licensors including all copyrights,
                    trade secrets, trademarks, patents, and other intellectual
                    property. You also agree that you will not reproduce or
                    redistribute the Inspired Meadows Ltd’s intellectual
                    property in any way, including electronic, digital, or new
                    trademark registrations.{" "}
                  </p>
                  <p className="mb">
                    You grant Inspired Meadows Ltd a royalty-free and
                    non-exclusive license to display, use, copy, transmit, and
                    broadcast the content you upload and publish. For issues
                    regarding intellectual property claims, you should contact
                    the company in order to come to an agreement.
                  </p>
                </div>
                <div class="terms-update">
                  <h3>User accounts </h3>

                  <p>
                    As a user of this website, you may be asked to register with
                    us and provide private information. You are responsible for
                    ensuring the accuracy of this information, and you are
                    responsible for maintaining the safety and security of your
                    identifying information. You are also responsible for all
                    activities that occur under your account or password.{" "}
                  </p>
                  <p className="mb">
                    If you think there are any possible issues regarding the
                    security of your account on the website, inform us
                    immediately so we may address it accordingly.
                  </p>
                  <p>
                    We reserve all rights to terminate accounts, edit or remove
                    content and cancel orders in their sole discretion.
                  </p>
                </div>

                <div class="terms-update">
                  <h3>Applicable law </h3>

                  <p>
                    By visiting this website, you agree that the laws of England
                    and Wales, without regard to principles of conflict laws,
                    will govern these terms and conditions, or any dispute of
                    any sort that might come between Inspired Meadows Ltd and
                    you, or its business partners and associates.{" "}
                  </p>
                </div>
                <div class="terms-update">
                  <h3>Disputes </h3>

                  <p>
                    Any dispute related in any way to your visit to this website
                    or to products you purchase from us shall be arbitrated by
                    state or federal court of England and Wales and you consent
                    to exclusive jurisdiction and venue of such courts.{" "}
                  </p>
                </div>
                <div class="terms-update">
                  <h3>Indemnification </h3>

                  <p>
                    You agree to indemnify Inspired Meadows Ltd and its
                    affiliates and hold Inspired Meadows Ltd harmless against
                    legal claims and demands that may arise from your use or
                    misuse of our services. We reserve the right to select our
                    own legal counsel.{" "}
                  </p>
                </div>
                <div class="terms-update">
                  <h3>Limitation on liability </h3>

                  <p>
                    Inspired Meadows Ltd is not liable for any damages that may
                    occur to you as a result of your misuse of our website.{" "}
                  </p>
                  <p className="mb">
                    Inspired Meadows Ltd reserves the right to edit, modify, and
                    change this Agreement any time. We shall let our users know
                    of these changes through electronic mail. This Agreement is
                    an understanding between Inspired Meadows Ltd and the user,
                    and this supersedes and replaces all prior agreements
                    regarding the use of this website.
                  </p>
                </div>

                {/* <div class="instruction">
                  <h4>
                    PLEASE READ THESE TERMS CAREFULLY AND BE SURE YOU UNDERSTAND
                    IT FULLY, BECAUSE IT EXPLAINS AND CONTROLS YOUR LEGAL
                    RELATIONSHIP WITH US AND YOUR RIGHTS RELATED TO YOUR USE OF
                    THE SERVICE.
                  </h4>

                  <p>
                    If you have any questions, please don’t hesitate to contact
                    us using our Support Form
                  </p>
                </div>

                <div class="services-use">
                  <p class="service-first">
                    <span class="terms-heading">Use of Our Service:</span> At
                    vero eos et accusamus et iusto odio dignissimos ducimus qui
                    blanditiis praesentium voluptatum deleniti atque corrupti
                    quos dolores et quas molestias excepturi sint occaecati
                    cupiditate non provident, similique sunt in culpa qui
                    officia deserunt mollitia animi, id est laborum et dolorum
                    fuga. Et harum quidem rerum facilis est et expedita
                    distinctio. Nam libero tempore, cum soluta nobis est
                    eligendi optio cumque nihil impedit quo minus id quod maxime
                    placeat facere possimus, omnis voluptas assumenda est, omnis
                    dolor repellendus. Temporibus autem quibusdam et aut
                    officiis debitis aut rerum necessitatibus saepe eveniet ut
                    et voluptates repudiandae sint et molestiae non recusandae.
                  </p>

                  <ul>
                    <li>
                      <p class="mb">
                        <span class="terms-heading">Eligibility:</span>{" "}
                        Similique sunt in culpa qui officia deserunt mollitia
                        animi, id est laborum et dolorum fuga. Et harum quidem
                        rerum facilis est et expedita distinctio. Nam libero
                        tempore, cum soluta nobis est eligendi optio cumque
                        nihil impedit quo minus id quod maxime placeat facere
                        possimus, omnis voluptas assumenda est, omnis dolor
                        repellendus. Temporibus autem quibusdam et aut officiis
                        debitis aut rerum necessitatibus saepe eveniet ut et
                        voluptates repudiandae sint et molestiae non recusandae.
                      </p>
                    </li>

                    <li>
                      <p class="mb">
                        <span class="terms-heading">License Terms:</span>{" "}
                        Similique sunt in culpa qui officia deserunt mollitia
                        animi, id est laborum et dolorum fuga. Et harum quidem
                        rerum facilis est et expedita distinctio. Nam libero
                        tempore, cum soluta nobis est eligendi optio cumque
                        nihil impedit quo minus id quod maxime placeat facere
                        possimus, omnis voluptas assumenda est, omnis dolor
                        repellendus. Temporibus autem quibusdam et aut officiis
                        debitis aut rerum necessitatibus saepe eveniet ut et
                        voluptates repudiandae sint et molestiae non recusandae.
                      </p>
                    </li>

                    <li>
                      <p class="mb">
                        <span class="terms-heading">Our Services:</span>{" "}
                        Similique sunt in culpa qui officia deserunt mollitia
                        animi, id est laborum et dolorum fuga. Et harum quidem
                        rerum facilis est et expedita distinctio. Nam libero
                        tempore, cum soluta nobis est eligendi optio cumque
                        nihil impedit quo minus id quod maxime placeat facere
                        possimus, omnis voluptas assumenda est, omnis dolor
                        repellendus. Temporibus autem quibusdam et aut officiis
                        debitis aut rerum necessitatibus saepe eveniet ut et
                        voluptates repudiandae sint et molestiae non recusandae.
                      </p>
                    </li>

                    <li>
                      <p class="mb">
                        <span class="terms-heading">Your Account:</span>{" "}
                        Similique sunt in culpa qui officia deserunt mollitia
                        animi, id est laborum et dolorum fuga. Et harum quidem
                        rerum facilis est et expedita distinctio. Nam libero
                        tempore, cum soluta nobis est eligendi optio cumque
                        nihil impedit quo minus id quod maxime placeat facere
                        possimus, omnis voluptas assumenda est, omnis dolor
                        repellendus. Temporibus autem quibusdam et aut officiis
                        debitis aut rerum necessitatibus saepe eveniet ut et
                        voluptates repudiandae sint et molestiae non recusandae.
                      </p>
                    </li>

                    <li>
                      <p>
                        <span class="terms-heading">
                          Monitoring and Enforcement, Termination:
                        </span>
                        Similique sunt in culpa qui officia deserunt mollitia
                        animi, id est laborum et dolorum fuga. Et harum quidem
                        rerum facilis est et expedita distinctio. Nam libero
                        tempore, cum soluta nobis est eligendi optio cumque
                        nihil impedit quo minus id quod maxime placeat facere
                        possimus, omnis voluptas assumenda est, omnis dolor
                        repellendus. Temporibus autem quibusdam et aut officiis
                        debitis aut rerum necessitatibus saepe eveniet ut et
                        voluptates repudiandae sint et molestiae non recusandae.
                      </p>
                    </li>
                  </ul>
                </div>

                <div class="term-paragraph">
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga. Et harum quidem rerum facilis est et expedita
                    distinctio. Nam libero tempore, cum soluta nobis est
                    eligendi optio cumque nihil impedit quo minus id quod maxime
                    placeat facere possimus, omnis voluptas assumenda est, omnis
                    dolor repellendus. Temporibus autem quibusdam et aut
                    officiis debitis aut rerum necessitatibus saepe eveniet ut
                    et voluptates repudiandae sint et molestiae non recusandae.
                  </p>

                  <p class="mb">
                    On the other hand, we denounce with righteous indignation
                    and dislike men who are so beguiled and demoralized by the
                    charms of pleasure of the moment, so blinded by desire, that
                    they cannot foresee the pain and trouble that are bound to
                    ensue; and equal blame belongs to those who fail in their
                    duty through weakness of will, which is the same as saying
                    through shrinking from toil and pain. These cases are
                    perfectly simple and easy to distinguish. In a free hour,
                    when our power of choice is untrammelled and when nothing
                    prevents our being able to do what we like best, every
                    pleasure is to be welcomed and every pain avoided. But in
                    certain circumstances and owing to the claims of duty or the
                    obligations of business it will frequently occur that
                    pleasures have to be repudiated and annoyances accepted. The
                    wise man therefore always holds in these matters to this
                    principle of selection: he rejects pleasures to secure other
                    greater pleasures, or else he endures pains to avoid worse
                    pains.
                  </p>
                </div>

                <div class="term-contact">
                  <p>
                    <span class="terms-heading">Contact:</span> Please visit our
                    Help Center with any questions regarding the Services, or
                    submit a support ticket with any questions regarding these
                    Terms, Service, account, or billing matters. You may also
                    contact us by email at help@inspiredmeadows.com
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default TermsAndCondition;
