import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  burialPlots: {
    isLoading: false,
    error: '',
    data: [],
    isFetched: false,
  },
  urnPlots: {
    isLoading: false,
    error: '',
    data: [],
    isFetched: false,
  },
  memorialFlora: {
    isLoading: false,
    error: '',
    data: [],
    isFetched: false,
  },
  memorialStones: {
    isLoading: false,
    error: '',
    data: [],
    isFetched: false,
  },
  urnsAndCaskets: {
    isLoading: false,
    error: '',
    data: [],
    isFetched: false,
  },
  memorialDetail: {
    id: '',
    itemType: '',
    title: '',
    description: '',
    images: [],
    price: '',
    createdAt: '',
    updatedAt: '',
  },
  isMemorialDetailModalOpen: false,
  plotDetail: {
    id: '',
    locationId: '',
    siteName: '',
    phase: '',
    subPhase: '',
    plotNumber: '',
    category: '',
    type: '',
    description: '',
    gpsCoordinates: {
      topLeft: [],
      topRight: [],
      bottomLeft: [],
      bottomRight: [],
    },
    price: '',
    plotSize: '',
    images: [],
    isDeleted: false,
    createdAt: '',
    updatedAt: '',
  },
  isPlotDetailModalOpen: false,
  isAddToCartSuccessModalOpen: false,
  
};

const productsSlice = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    /* ----- BURIAL PLOTS ----- */
    setBurialPlotsIsLoading: (state, action) => {
      state.burialPlots.isLoading = action.payload.isLoading;
    },
    setBurialPlotsError: (state, action) => {
      state.burialPlots.error = action.payload.error;
      state.burialPlots.isFetched = true;
    },
    saveBurialPlots: (state, action) => {
      state.burialPlots.data = action.payload.burialPlots;
      state.burialPlots.isFetched = true;
    },
    /* ----- URN PLOTS ----- */
    setUrnPlotsIsLoading: (state, action) => {
      state.urnPlots.isLoading = action.payload.isLoading;
    },
    setUrnPlotsError: (state, action) => {
      state.urnPlots.error = action.payload.error;
      state.urnPlots.isFetched = true;
    },
    saveUrnPlots: (state, action) => {
      state.urnPlots.data = action.payload.urnPlots;
      state.urnPlots.isFetched = true;
    },
    /* ----- MEMORIAL FLORA ----- */
    setMemorialFloraIsLoading: (state, action) => {
      state.memorialFlora.isLoading = action.payload.isLoading;
    },
    setMemorialFloraError: (state, action) => {
      state.memorialFlora.error = action.payload.error;
      state.memorialFlora.isFetched = true;
    },
    saveMemorialFlora: (state, action) => {
      state.memorialFlora.data = action.payload.memorialFlora;
      state.memorialFlora.isFetched = true;
    },
    /* ----- MEMORIAL STONES ----- */
    setMemorialStonesIsLoading: (state, action) => {
      state.memorialStones.isLoading = action.payload.isLoading;
    },
    setMemorialStonesError: (state, action) => {
      state.memorialStones.error = action.payload.error;
      state.memorialStones.isFetched = true;
    },
    saveMemorialStones: (state, action) => {
      state.memorialStones.data = action.payload.memorialStones;
      state.memorialStones.isFetched = true;
    },
    /* ----- URNS AND CASKETS ----- */
    setUrnsAndCasketsIsLoading: (state, action) => {
      state.urnsAndCaskets.isLoading = action.payload.isLoading;
    },
    setUrnsAndCasketsError: (state, action) => {
      state.urnsAndCaskets.error = action.payload.error;
      state.urnsAndCaskets.isFetched = true;
    },
    saveUrnsAndCaskets: (state, action) => {
      state.urnsAndCaskets.data = action.payload.memorialStones;
      state.urnsAndCaskets.isFetched = true;
    },
    /* ----- MEMORIAL DETAILS ----- */
    setMemorialDetail: (state, action) => {
      const data = action.payload;
      state.memorialDetail.id = data.id;
      state.memorialDetail.itemType = data.itemType;
      state.memorialDetail.title = data.title;
      state.memorialDetail.description = data.description;
      state.memorialDetail.images = data.images;
      state.memorialDetail.price = data.price;
      state.memorialDetail.createdAt = data.createdAt;
      state.memorialDetail.updatedAt = data.updatedAt;
    },
    setIsMemorialDetailModalOpen: (state, action) => {
      state.isMemorialDetailModalOpen = action.payload.isOpen;
    },
    setPlotDetail: (state, action) => {
      const data = action.payload;
      const plots = data?.plots?.[0];
      state.plotDetail.id = data?.id;
      state.plotDetail.locationId = data?.locationId;
      state.plotDetail.siteName = data?.siteName;
      state.plotDetail.phase = plots?.phase;
      state.plotDetail.subPhase = plots?.subPhase;
      state.plotDetail.plotNumber = plots?.plotNumber;
      state.plotDetail.category = data?.category;
      state.plotDetail.type = data?.type;
      state.plotDetail.description = plots?.description;
      state.plotDetail.gpsCoordinates = plots?.gpsCoordinates;
      state.plotDetail.price = plots?.price;
      state.plotDetail.plotSize = plots?.plotSize;
      state.plotDetail.images = plots?.images;
      state.plotDetail.isDeleted = data?.isDeleted;
      state.plotDetail.createdAt = data?.createdAt;
      state.plotDetail.updatedAt = data?.updatedAt;
    },
    setMultiplePlotDetail : (state, action) =>{
      const data = action.payload;
      const plots = data?.plots?.[0];
      const plotNum = data?.plots?.map((data) => data?.plotNumber)
      state.plotDetail.id = data?.id;
      state.plotDetail.locationId = data?.locationId;
      state.plotDetail.siteName = data?.siteName;
      state.plotDetail.phase = plots?.phase;
      state.plotDetail.subPhase = plots?.subPhase;
      state.plotDetail.plotNumber = plotNum;
      state.plotDetail.category = data?.category;
      state.plotDetail.type = data?.type;
      state.plotDetail.description = plots?.description;
      state.plotDetail.gpsCoordinates = plots?.gpsCoordinates;
      state.plotDetail.price = data?.totalPrice;
      state.plotDetail.plotSize = data?.totalPlotSize;
      state.plotDetail.images = plots?.images;
      state.plotDetail.isDeleted = data?.isDeleted;
      state.plotDetail.createdAt = data?.createdAt;
      state.plotDetail.updatedAt = data?.updatedAt;
    },
    setIsPlotDetailModalOpen: (state, action) => {
      state.isPlotDetailModalOpen = action.payload.isOpen;
    },

    setIsAddToCartSuccessModalOpen: (state, action) => {
      state.isAddToCartSuccessModalOpen = action.payload.isOpen;
    },
  },
});

const { actions, reducer } = productsSlice;

export const {
  /* ----- BURIAL PLOTS ----- */
  setBurialPlotsError,
  setBurialPlotsIsLoading,
  saveBurialPlots,

  /* ----- URN PLOTS ----- */
  setUrnPlotsError,
  setUrnPlotsIsLoading,
  saveUrnPlots,
  setSelectedTypes,

  /* ----- MEMORIAL FLORA ----- */
  setMemorialFloraError,
  setMemorialFloraIsLoading,
  saveMemorialFlora,

  /* ----- MEMORIAL STONES ----- */
  setMemorialStonesError,
  setMemorialStonesIsLoading,
  saveMemorialStones,

  /* ----- URNS AND CASKETS ----- */
  setUrnsAndCasketsError,
  setUrnsAndCasketsIsLoading,
  saveUrnsAndCaskets,

  /* ----- MEMORIAL DETAIL ----- */
  setMemorialDetail,
  setIsMemorialDetailModalOpen,

  /* ----- PLOT DETAIL ----- */
  setPlotDetail,
  setMultiplePlotDetail,
  setIsPlotDetailModalOpen,

  setIsAddToCartSuccessModalOpen,
} = actions;


export default reducer;
