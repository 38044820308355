import React, { Suspense, useEffect, useRef, useState } from "react";
import bnrImage from "../../../assets/images/compress/bg-img-c.png";
import ImageLoading from "../skeleton/ImageLoading";


// Lazy load the components
const LazyImage = React.lazy(() => import("./LazyImage")); // Assuming you have a separate LazyImage component
const LazyVideo = React.lazy(() => import("./LazyVideo")); // Assuming you have a separate LazyVideo component

const BannerComponent = ({showImage , setIsPlaying ,  setShowImage , videoRef}) => {
  
  const handleVideoEnded = () => {
    setIsPlaying(false);
    setShowImage(true);
  };

  return (
    <Suspense fallback={<ImageLoading/>}>
      {showImage ? (
        <div className="bannerContainer">
        <LazyImage src={bnrImage} alt="Placeholder Image" />
        </div>
      ) : (
        <LazyVideo
          src="https://meadows-s3-bucket-v3.s3.eu-west-2.amazonaws.com/banners/inspired_meadows_final+(720p).mp4"
          ref={videoRef}
          onEnded={handleVideoEnded}
        />
      )}
    </Suspense>
  );
};

export default BannerComponent;
