import { Fragment, useEffect } from 'react';

import PartyPopper from '../../assets/images/party_popper.png';
import {useNavigate} from "react-router-dom";

const OrderCompleteModal = ({hasPlotInOrder ,  closeModal, isModalOpen }) => {


const navigate = useNavigate()
  const handleOK = ()=>{

    navigate("/")
    closeModal()

  }


  useEffect(() => {
    function handleModalClose(e) {
      if (e.key === 'Escape') handleOK();
    }
    document.body.addEventListener('keydown', handleModalClose);

    return () => document.body.removeEventListener('keydown', handleModalClose);
  }, []);

  // useEffect(() => {
  //   if (isModalOpen) document.body.style.overflow = 'hidden';
  //   else document.body.style.overflow = '';

  //   return () => (document.body.style.overflow = 'hidden');
  // }, [isModalOpen]);

  return (
    <Fragment>
      <div
        onClick={handleOK}
        style={{ position: 'fixed' }}
        className={`overlay ${isModalOpen ? 'cart-show' : ''}`}
      />

      <div
        style={{ height: 'auto' }}
        className={`order-complete ${isModalOpen ? 'alert-active' : ''}`}
      >
        <img src={PartyPopper} alt='party popper' />
        <span>Your order is complete!</span>
        {/* <p>You will be receiving a confirmation email with order details.</p> */}
        {
        hasPlotInOrder ? <p>Thank you for your purchase. Please check your email for the purchase documentation, remember to check your spam/junk folders also.</p> :<p>Additional items purchased successfully</p>
         }
        <button onClick={handleOK}>OK</button>
      </div>
    </Fragment>
  );
};

export default OrderCompleteModal;
