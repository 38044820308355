import React, { useEffect, useState, useRef } from "react";
import { Calendar, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { getFormattedDate } from "../../utils";

const DatePicker = ({
  className,
  name,
  value,
  fieldsToValidate,
  formData,
  onChangeHandle,
}) => {
  const [date, setDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleCalendar = () => {
    setIsOpen(!isOpen);
  };

  const handleDateChange = (selectedDate) => {
  const currentDate = new Date();

  if (selectedDate > currentDate) {
    return;
  }
    setDate(selectedDate);
    const data = {
      target: {
        value: getFormattedDate(selectedDate),
        name,
      },
    };
    onChangeHandle(data);
  };

  return (
    <>
      <div ref={datePickerRef}>
        <input
          onClick={toggleCalendar}
          type="text"
          value={value ? value : "DD-MM-YYYY"}
          className={fieldsToValidate && !value ? "invalid" : ""}
        />
        {isOpen && (
          <Calendar className={className} date={date} onChange={handleDateChange} />
        )}
      </div>
    </>
  );
};

export default DatePicker;
