import { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector  } from 'react-redux';

import MapIcon from '../../assets/images/Mapig_icon.png';
import LocationIcon from '../../assets/images/Location_icon.png';

import agent from '../../agent';

import {
  setIsAddToCartSuccessModalOpen,
  setIsPlotDetailModalOpen,
  setPlotDetail,
} from '../../reducers/slices/products';


import Loader from '../components/Loader';
import Login from '../Auth/Login';
import Register from '../Auth/Register';
import ProfileViewer from '../User/ProfileViewer';
import ForgotPassword from '../Auth/ForgotPassword';
import VerifyOtp from '../Auth/VerifyOtp';
import ResetComplete from '../Auth/ResetComplete';
import ResetPassword from '../Auth/ResetPassword';
import { useNavigate } from 'react-router-dom';
import {
  setEmail,
  setPassword,
  setShowLogin,
  setShowRegister,
  setShowForgot,
  setShowLogout,
  setShowPassword,
  setForgotEmail,
  setLoginErr,
  setVerifyOtp,
  setResetPass,
  setResetComplete,
  setShowProfileDrop
} from "../../reducers/slices/headerSlice";
import ProfileDetail from '../User/includes/ProfileDetail';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import placeholder from '../../assets/images/Default_Load.png'

const mapStateToProps = state => {
  const { isPlotDetailModalOpen, plotDetail } = state.products;
  return {
    isModalOpen: isPlotDetailModalOpen,
    userId: state.common?.currentUser?.id || '',
    plotDetail, 
    currentUser: state.common.currentUser
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
  closeModal: () => {
    dispatch(setIsPlotDetailModalOpen({ isOpen: false }));
    dispatch(
      setPlotDetail({
        id: '',
        locationId: '',
        siteName: '',
        phase: '',
        subPhase: '',
        plotNumber: '',
        category: '',
        type: '',
        description: '',
        gpsCoordinates: {
          topLeft: [],
          topRight: [],
          bottomLeft: [],
          bottomRight: [],
        },
        price: '',
        plotSize: '',
        images: [],
        isDeleted: false,
        createdAt: '',
        updatedAt: '',
      })
    );
  },
  openAddToCartSuccessModal: () =>
    dispatch(setIsAddToCartSuccessModalOpen({ isOpen: true })),
});

const PlotDetailModal = props => {
  const {
    userId,
    isModalOpen,
    closeModal,
    currentUser,
    plotDetail,
    openAddToCartSuccessModal,
  } = props;

  const [activeImg, setActiveImg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const {
    email,
    password,
    showLogin,
    showRegister,
    showForgot,
    showPassowrd,
    loginErr,
    showProfileDrop,
    showVerifyOtp,
    showResetPass,
    forgotEmail,
    showResetComplete,
    showLogout
  } = useSelector((state) => state.header);

  useEffect(() => {
    if (isModalOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [isModalOpen]);

  useEffect(() => {
    function handleModalClose(e) {
      if (e.key === 'Escape') {
        closeModal();
      }
    }
    document.body.addEventListener('keydown', handleModalClose);
    return () => document.body.removeEventListener('keydown', handleModalClose);
  }, []);

  useEffect(() => {
    if (plotDetail?.images && plotDetail?.images.length > 0) setActiveImg(plotDetail?.images[0]);
    else setActiveImg('');
  }, [plotDetail?.images]);

  
  const handleCloseLogin = () => {
    dispatch(setShowLogin(false));
    dispatch(setEmail(''));
    dispatch(setPassword(''));
  }

  const handleShowLogin = () => {
  
    dispatch(setShowLogin(true));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(false))
    dispatch(setResetComplete(false));
  }

  const handleCloseRegister = () => dispatch(setShowRegister(false));

  const handleCloseResetPass = () => dispatch(setResetPass(false));
  const handleShowResetPass = () => {
   
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(true));
    dispatch(setResetComplete(false));
  }
  const handleCloseResetPassComplete = () => dispatch(setResetComplete(false))
  const handleShowResetPassComplete = () => {
   
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(false));
  }
  const handleShowRegister = () => {
   
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(true));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(false));
    dispatch(setResetComplete(false));
  }

  const handleCloseForgot = () => dispatch(setShowForgot(false));
  const handleShowForgot = () => {
   
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(true));
    dispatch(setVerifyOtp(false));
    dispatch(setResetComplete(false));
  }
  const handleCloseVerify = () => dispatch(setVerifyOtp(false));
  const handleShowVerify = () => {
   
    dispatch(setShowLogin(false));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(true));
    dispatch(setResetPass(false));
    dispatch(setResetComplete(false));
  }
  const handleShowMyProfile = () => {
    dispatch(setShowLogout(false));
    setShowProfileDrop(false);
    navigate('/user/my-profile');
  }
  const submitLogout = () => dispatch(setShowLogout(true));


  const addPlotToCart = async () => {
    if (currentUser == null) {
      dispatch(setShowLogin(true));
      closeModal();
    } else {
      try {
        const data = {
          productId: plotDetail?.id,
          productType: plotDetail?.category,
          quantity: 1,
          userId: userId,
        };

        setIsLoading(true);
        const res = await agent.common.addToCart(data);
        const { status, message } = res;
        if(status === false){
          toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }else{
          openAddToCartSuccessModal();
        closeModal();
        }
        
      } catch (err) {
      
        if (err instanceof Error) {
          console.error(err.message);
        } else {
          console.error(err);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  let loginProps = {
    showLogin, handleCloseLogin, handleShowLogin, handleShowRegister, handleShowForgot, email, setEmail, password, setPassword, showPassowrd, setShowPassword, loginErr, setLoginErr
  }

  let registerProps = {
    showRegister, handleCloseRegister, handleShowLogin
  }

  let forgotProps = {
    showForgot, handleCloseForgot, handleShowLogin, setForgotEmail, handleShowVerify
  }

  let verifyProps = {
    handleCloseVerify, handleShowLogin, forgotEmail, showVerifyOtp, handleShowResetPass
  }
  let resetPassProps = {
    showResetPass, handleShowLogin, forgotEmail, handleCloseResetPass, handleShowResetPassComplete
  }

  let resetCompleteProps = {
    handleShowLogin, handleCloseResetPassComplete, showResetComplete
  }
  let profileProps = {
    currentUser, submitLogout, showProfileDrop, handleShowMyProfile
  }
  const plotNumbers = plotDetail?.plotNumber || [];
  const sortedPlotNumbers = [...plotNumbers].sort((a, b) => {
    const numericPartA = parseInt(a.match(/\d+/)[0], 10); // Extract numeric part
    const numericPartB = parseInt(b.match(/\d+/)[0], 10);
    return numericPartA - numericPartB;
  });

  return (
    <Fragment>
      <div
        onClick={closeModal}
        style={{ position: 'fixed' }}
        className={`overlay ${isModalOpen ? 'cart-show' : ''}`}
      />

      <div
        style={{ height: 'auto' }}
        class={`burials-add-to-cart ${isModalOpen ? 'cart-show' : ''}`}
      >
        {isLoading && <Loader />}
        <div class='burials-img'>
          <img
            style={{ width: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
            src={activeImg || placeholder}
            alt=''
          />
          {/* <ul
            style={{
              marginTop: 10,
              display: 'flex',
              gap: 9,
              flexWrap: 'wrap',
            }}
          >
            {plotDetail?.images &&
              plotDetail?.images.map((img, idx) => (
                <li
                  key={idx}
                  style={{
                    width: 50,
                    border: activeImg === img ? '2px solid green' : '',
                  }}
                  onClick={() => setActiveImg(img)}
                >
                  <img
                    src={img || 'https://placehold.co/50x50'}
                    alt=''
                    style={{
                      width: '100%',
                      aspectRatio: '1/1',
                      objectFit: 'cover',
                    }}
                  />
                </li>
              ))}
          </ul> */}
        </div>

        <div class='burials-plot-detail'>
          <h2>{plotDetail?.plotNumber?.length > 1 ? plotDetail?.category : plotDetail?.category.slice(0 , -1)}</h2>
          {sortedPlotNumbers.length > 1 ? 
            sortedPlotNumbers.map((digit) => (
              <button key={digit}>Plot: #{digit || ' --'}</button>
            )) :
            <button>Plot: #{sortedPlotNumbers[0] || ' --'}</button>
          } 
          <ul>
            <li>
              <img src={plotDetail?.LocationIcon} alt='' />
              <span>{plotDetail?.siteName || '--'}</span>
            </li>
            <li>
              <img src={MapIcon} alt='' />
              <span>{plotDetail?.plotSize || '--'}</span>
            </li>
          </ul>

          <span class='price'>£{plotDetail?.price || '-'}</span>

          <p>{plotDetail?.description || '--'}</p>

          <button class='cart-btn' onClick={addPlotToCart}>
            Add to Cart
          </button>
          {isLoggedIn && (
            [loginProps, registerProps, forgotProps, profileProps].map((props, index) => (
              <Fragment key={index}>
                {props.showLogin && <Login {...props} />}
                {props.showRegister && <Register {...props} />}
                {props.showForgot && <ForgotPassword {...props} />}
                {props.showVerify && <VerifyOtp {...props} />}
                {props.showResetPass && <ResetPassword {...props} />}
                {props.showResetComplete && <ResetComplete {...props} />}
                {props.showProfile && <ProfileViewer {...props} />}
              </Fragment>
            ))
          )}

          <div class='burials-remove-btn' onClick={closeModal}>
            <span>
              <i class='fa fa-times-circle' aria-hidden='true'></i>
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PlotDetailModal);
