import React, { Suspense } from 'react';
import anglerighticon from "../../assets/images/angle-right-icon.png";
import PetImage from "../../assets/images/pet_img.png";
import { Link } from 'react-router-dom';
import ImageLoading from '../Common/skeleton/ImageLoading';

const LazyImage = React.lazy(() => import("../Common/Optemize/LazyImage")); 


const Pets = () => {
  return (
    <>
    <section id="overview-main" class="pet-burials-main">
        <div class="container">
          <div class="row">
            <div class="pet-burials-img-col">
              <div class="overview-img pet-burials-img">
              <Suspense fallback={<ImageLoading/>}>
                <LazyImage src={PetImage} alt="PetImage image"/>
                </Suspense>
                {/* <img src={PetImage} alt="overview data" /> */}
              </div>
            </div>
            <div class="pet-burials-text-col">
              <div class="overview-text pet-text">
                <h2>Pet Burials with Inspired Meadows </h2>
                <p>
                  At Inspired Meadows, we get it – pets are family. They're our
                  loyal friends, our comforting companions, and they love us
                  unconditionally. That's why we've created a special place for
                  them right in the heart of the stunning Lake District.{" "}
                </p>
                <Link to="/pet-learnmore">
                  Learn More <img src={anglerighticon} alt="lear more data" loading='lazy' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default Pets;
